import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";
import AddContactStepper from "./AddContactStepper";
import { AddExistingContact } from "./AddExistingContact";
import { Close, ArrowBackIos } from "@material-ui/icons";
import "../Location/locationStyles.css";
import AddTalentContact from "./AddTalentContact";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: "auto",
    width: "30vW",
    overflow: "hidden",
  },
  paperTalent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 390,
    width: "30vW",
    overflow: "hidden",
  },
}));

function AddContactPopUp(props) {
  const classes = useStyles();
  const [existing, setExisting] = useState();
  const [addAgentPopUpOpen, setAddAgentPopUpOpen] = useState(false);

  const handleAddExisting = () => {
    setExisting(true);
  };

  const addContactAndCloseModal = (value) => {
    props.handleModal();
    props.selectNewPerson(value);
    setAddAgentPopUpOpen(false);
  };

  return (
    <div>
      <Modal
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
        style={
          addAgentPopUpOpen
            ? {
                width: "60%",
              }
            : {}
        }
      >
        <div>
          <AppBar position="static" style={{ background: "#000000" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              {existing ? (
                <IconButton color="inherit">
                  <ArrowBackIos onClick={() => setExisting(false)} />
                </IconButton>
              ) : (
                ""
              )}
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                {!existing
                  ? `Create New ${props.category}`
                  : `Add Existing ${props.category}`}
              </Typography>
              <IconButton onClick={props.handleModal} color="inherit">
                <Close
                  onClick={() => {
                    setExisting(false);
                    props.setGoAhead(false);
                    props.setNewContact([]);
                  }}
                />
              </IconButton>
            </Toolbar>
          </AppBar>
          {props.category === "Talent" ? (
            <div className={classes.paperTalent}>
              <AddTalentContact
                addAgentPopUpClosed={() => setAddAgentPopUpOpen(false)}
                addAgentPopUpOpened={() => setAddAgentPopUpOpen(true)}
                addContactAndCloseModal={addContactAndCloseModal}
                allPeople={props.people.lTalent}
                category={props.category}
                goAhead={props.goAhead}
                goAheadContact={props.goAheadContact}
                handleCloseModal={() => {
                  setAddAgentPopUpOpen(false);
                  props.handleModal();
                }}
                locationId={props.locationId}
                parent={props.parent}
                setAddExisting={handleAddExisting}
                setDuplicates={props.setDuplicates}
                setGoAhead={props.setGoAhead}
                setNewContact={props.setNewContact}
              />
            </div>
          ) : !existing ? (
            <div className={classes.paper}>
              <AddContactStepper
                addAgentPopUpClosed={() => setAddAgentPopUpOpen(false)}
                addAgentPopUpOpened={() => setAddAgentPopUpOpen(true)}
                addContactAndCloseModal={addContactAndCloseModal}
                allPeople={props.people["l" + props.category]}
                category={props.category}
                company={props.company}
                goAhead={props.goAhead}
                goAheadContact={props.goAheadContact}
                locationId={props.locationId}
                parent={props.parent}
                reloadContacts={
                  props.category === "Location Contact" &&
                  props.reloadLocationContacts
                }
                setAddExisting={handleAddExisting}
                setDuplicates={props.setDuplicates}
                setGoAhead={props.setGoAhead}
                setNewContact={props.setNewContact}
                reloadPersonList={props.reloadPersonList}
              />
            </div>
          ) : (
            <div className={classes.paper}>
              <AddExistingContact
                locationId={props.locationId}
                reloadContacts={
                  props.category === "Location Contact"
                    ? props.reloadLocationContacts
                    : ""
                }
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default AddContactPopUp;
