import React, { useState, useEffect } from "react";
import Axios from "axios";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Table,
  AppBar,
  Toolbar,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Button,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import DeliveredOutputPopup from "./PopUps/DeliveredOutputPopUp";
import { format } from "date-fns";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    // fontSize: 14,
    // maxWidth: "50px",
    // height: "50px",
    // overflow: "hidden"
    padding: "20px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100px",
  },
  root: {
    padding: "10px 15px 10px 15px",
    maxHeight: "50px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

//------------------------ Sorting ---------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Creative Name",
    align: "left",
    disablePadding: false,
    label: "Creative Name",
  },
  {
    id: "Duration",
    align: "left",
    disablePadding: false,
    label: "Ad Duration",
  },
  {
    id: "Creative Deadline",
    align: "left",
    disablePadding: false,
    label: "Deadline",
  },
  { id: "Key Number", align: "left", disablePadding: false, label: "Key #" },
  {
    id: "Platform",
    align: "left",
    disablePadding: false,
    label: "Platform",
  },
  {
    id: "Publisher",
    align: "left",
    disablePadding: false,
    label: "Publisher",
  },
  {
    id: "Target Audience",
    align: "left",
    disablePadding: false,
    label: "Target Audience",
  },
  { id: "notes", align: "left", disablePadding: false, label: "Notes" },
  { id: "status", align: "left", disablePadding: false, label: "Status" },
  { id: "Video", align: "left", disablePadding: false, label: "Video" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

//------------------------------End of Sorting--------------------------------------
//--------------------------- Delivered Outputs Table ------------------------------
export default function DeliveredOutputs(props) {
  const classes = useStyles();

  const [showDeliveredOutputPopUp, setShowDeliveredPopUp] = useState(false);
  const [currentOutputDetails, setCurrentOutputDetails] = useState({});

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("startDate");
  const [selected, setSelected] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const toggleShowDeliveredOutputPopUp = () => {
    if (showDeliveredOutputPopUp) {
      setShowDeliveredPopUp(false);
    } else {
      setShowDeliveredPopUp(true);
    }
  };

  const splitSemiColon = (items) => {
    var arr = items.split(";");
    const uniqueValues = [...new Set(arr)];
    var uniqueItems = uniqueValues.join(", ");
    return uniqueItems;
  };

  const splitStringArr = (items) => {
    if (items == null) {
      return "";
    } else {
      const uniqueValues = [...new Set(items)];
      var uniqueItems = uniqueValues.join(", ");

      return uniqueItems;
    }
  };

  const formatStringifiedStringArray = (stringifiedArray) => {
    var charArray = [...stringifiedArray];
    var finalString = "";
    charArray.forEach(c => {
      if (c !== "\"" && c !== "[" && c !== "]") {
        finalString = finalString + c;
      }
    });
    var arr = finalString.split(",");
    return [...new Set(arr)].join(", ");
  }

  return (
    <TableContainer
      component={Paper}
      style={{ marginTop: "15px", overflow: "hidden" }}
    >
      <AppBar
        position="static"
        className="output-header-container"
      >
        <Toolbar style={{ minHeight: "50px" }}>
          <Typography variant="h6" align="center" style={{ flexGrow: 1 }}>
            Delivered Outputs
          </Typography>
        </Toolbar>
      </AppBar>
      {showDeliveredOutputPopUp && (
        <DeliveredOutputPopup
          modalState={showDeliveredOutputPopUp}
          handleModal={toggleShowDeliveredOutputPopUp}
          outputDetails={currentOutputDetails}
          formatStringifiedStringArray={formatStringifiedStringArray}
        />
      )}
      {props.deliveredOutputsFetched && (
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
          style={{ tableLayout: "fixed" }}
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {props.deliveredOutputsFetched &&
              stableSort(props.outputs, getComparator(order, orderBy)).map(
                (row) => (
                  <StyledTableRow
                    key={row.id}
                    onClick={() => {
                      setCurrentOutputDetails(row);
                      toggleShowDeliveredOutputPopUp();
                    }}
                  >
                    <Tooltip title={row.creativeName ? row.creativeName : row["Creative Name"] }>
                      <StyledTableCell>
                          {row.creativeName
                              ? row.creativeName
                              : row["Creative Name"]}
                      </StyledTableCell>
                    </Tooltip>
                    <StyledTableCell>{row.duration}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.startDate != null &&
                        row.startDate != ""
                        ? format(new Date(row.startDate), "dd/MM/yyyy")
                        : row["Creative Deadline"] != null &&
                          row["Creative Deadline"] != ""
                          ? format(
                            new Date(row["Creative Deadline"]),
                            "dd/MM/yyyy"
                          )
                          : "Not Available"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.keyNumberFkNavigation !== null && row.keyNumberFkNavigation !== undefined
                        ? row.keyNumberFkNavigation.keynumber : ""}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatStringifiedStringArray(row.platform)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatStringifiedStringArray(row.publisher)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.targetAudience}
                    </StyledTableCell>
                    <StyledTableCell>{row.notes}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.status}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          window.open(row.creativeLink, "_blank");
                        }}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
//--------------------------- End of Delivered Outputs Table ------------------------------
