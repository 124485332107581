import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    CircularProgress,
    InputAdornment,
    Typography,
    Grid
} from '@material-ui/core';
import {
    Search as SearchIcon
} from "@material-ui/icons";
import axios from "axios";
import FilterButtonCustom from '../Shared/Buttons/FilterButonCustom';
import { Skeleton } from '@material-ui/lab';
import ContactTable from './ContactTable';
import "./ContactSearch.css";

const ContactSearchPage = ({

}) => {
    const bottomElementRef = useRef(null);
    const defaultSearchFilter = ["Client", "Contractor", "Location Contact", "Staff", "Talent"];

    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [keywordlookUp, setKeywordlookUp] = useState("");
    const [contactSearchFilter, setContactSearchFilter] = useState(defaultSearchFilter);
    const [applyFilterClicked, setApplyFilterClicked] = useState(false);
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        if (!isLoading && hasMoreData && !isLoadingMore) {
            const observer = new IntersectionObserver(
                async (entries) => {
                    if (entries[0].isIntersecting) {
                        setIsLoadingMore(true); // prevent making multiple calls
                        await searchContacts();
                        setIsLoadingMore(false);
                    }
                },
                { threshold: [1] }
            ); // By specifying [1] as the threshold value, you're instructing the Intersection Observer to trigger when the target element becomes fully visible.

            if (bottomElementRef.current) {
                console.log("Observing:", bottomElementRef.current);
                observer.observe(bottomElementRef.current);
            }

            return () => {
                if (bottomElementRef.current) {
                    observer.unobserve(bottomElementRef.current);
                }
            };
        }
    }, [isLoading, hasMoreData, isLoadingMore]);

    const searchContacts = (shouldBypass = false) => {
        if (!shouldBypass) {
            if (!hasMoreData || isLoading || isLoadingMore) {
                return; // No more data to fetch
            }
        }
        setIsLoading(true);
        axios.post("People/SearchContacts", contactSearchFilter, {
            params: {
                keyword: keywordlookUp,
                page: currentPage
            }
        }).then(({ data }) => {
            if (data.length === 0) {
                setHasMoreData(false);
                if (currentPage == 0) {
                    setContacts([]);
                }
            } else {
                if (currentPage === 0) {
                    setContacts(data);
                } else {
                    setContacts((prevContacts) => [...prevContacts, ...data]);
                }
                setCurrentPage(currentPage + 1);
            }
        }).finally(() => setIsLoading(false));
    }

    const handleApplyFilter = () => {
        setHasMoreData(true);
        setCurrentPage(0);
        setApplyFilterClicked(true);
    };

    const handleSearchContacts = () => {
        setHasMoreData(true);
        searchContacts(true);
    };

    const handleEditPersonInStore = (newContact) => {
        setContacts(contacts.map((c) => {
          return (
            c.id === newContact.id ? {
              ...c,
              ["firstName"]: newContact.firstName,
              ["lastName"]: newContact.lastName,
              ["name"]: `${newContact.firstName} ${newContact.lastName}`,
              ["emailAddress"]: newContact.emailAddress,
              ["phoneNumber"]: newContact.phoneNumber,
              ["bankAccount"]: newContact.bankAccount,
              ["enableSms"]: newContact.enableSms,
            } : c
          )
        }));
      };

    useEffect(() => {
        if (applyFilterClicked) {
            setApplyFilterClicked(false);
            searchContacts(true);
        }
    }, [applyFilterClicked])

    useEffect(() => {
        if (keywordlookUp) {
            setCurrentPage(0);
        }
    }, [keywordlookUp]);

    useEffect(() => {
        handleApplyFilter();
    }, [contactSearchFilter])

    return (
        <div className='contact-center-container'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h4" align='center'>Production Network</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <div className={"default-text-field-container"}>
                        <TextField
                            fullWidth
                            label="Search Contacts (Press Enter to Search)"
                            onChange={(e) => setKeywordlookUp(e.target.value)}
                            value={keywordlookUp}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    handleSearchContacts();
                                }
                            }}
                            variant="filled"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <FilterButtonCustom
                        title={"Category"}
                        options={defaultSearchFilter}
                        spanClass="influencer-filter-button-container"
                        selectedOptions={contactSearchFilter}
                        allSelectedByDefault={true}
                        setSelectedOptions={value => {
                            if(value.length == 0){
                                setContactSearchFilter(defaultSearchFilter)
                            } else {
                                setContactSearchFilter(value);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <ContactTable
                        contacts={contacts}
                        handleEditPersonInStore={handleEditPersonInStore}
                    />
                </Grid>
            </Grid>
            <div ref={bottomElementRef}></div>
            <div style={{ height: 10 }}></div>
            {isLoading &&
                <>
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                </>
            }

        </div>
    );
}

export default ContactSearchPage;
