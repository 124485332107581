import {
  Avatar,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import CheckIcon from "@material-ui/icons/Check";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  MailOutline as MailOutlineIcon,
  Visibility as VisibilityIcon,
  Receipt as ReceiptIcon,
} from "@material-ui/icons";
import { getDefaultAvatar } from "../../../../../../utils/InfluencerUtils";
import "./projectCampaignStepper.scss";
import { isOnboardingRequired } from "../../../../../../utils/PersonUtils";
import ConfirmInvoiceDialog from "./ConfirmInvoiceDialog";
import ContractorPopUp from "../../../../ProjectOutputs/PopUps/ContractorPopUp";
import ConfirmationDialog from "../../../../../Shared/ConfirmationDialog";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "green",
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "green",
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  partial: {
    "& $line": {
      backgroundColor: "yellow",
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "green",
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "green",
  },
  // completed: {
  //   backgroundImage:
  //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  // },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, partial } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
        [classes.partial]: partial,
      })}
    >
      {getSteps()[props.icon - 1]?.icon}
    </div>
  );
}

const influencerSteps = [
  { label: "Invoiced", field: null, icon: <CheckIcon /> },
];
const getSteps = () => {
  return [
    {
      label: "Client Approval Sent",
      field: "clientApprovalSentOn",
      icon: <MailOutlineIcon />,
    },
    { label: "Outreach Sent", field: "sentOn", icon: <MailOutlineIcon /> },
    { label: "Accepted", field: "approvedOn", icon: <CheckIcon /> },
    {
      label: "Content Uploaded",
      field: "contentUploadedOn",
      icon: <CheckIcon />,
    },
    {
      label: "Content Review",
      field: "contentReviewedOn",
      icon: <CheckIcon />,
    },
    {
      label: "Content Approved",
      field: "contentApprovedOn",
      icon: <CheckIcon />,
    },
    { label: "Reports Received", field: "reportsReceived", icon: <CheckIcon /> },
  ];
};

const ColorlibInvoiceStepIcon = (scheduleItem) => {
  const classes = useColorlibStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]:
          scheduleItem.contractStatus === "Invoiced" ||
          scheduleItem.contractStatus === "AwaitingPayment" ||
          scheduleItem.contractStatus === "Paid",
      })}
    >
      <CheckIcon />
    </div>
  );
};

const ProjectCampaignStepper = ({
  scheduleItem,
  handleClickClientApprovalStep,
  handleUpdateScheduleItemContractStatus,
  handleClickReviewedOnStep,
  handleClickContentApprovedOnStep,
}) => {
  const [activeStep, setActiveStep] = useState(-1);
  const [showInvoiceConfirmation, setShowInvoiceConfirmation] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);
  const [failedCreate, setFailedCreate] = useState(false);
  const [openUpdateInfluencerDialog, setOpenUpdateInfluencerDialog] =
    useState(false);
  const [openInvalidInvoiceDialog, setOpenInvalidInvoiceDialog] =
    useState(false);
  const [isMissingOnboarding, setIsMissingOnboarding] = useState(false);

  const steps = getSteps();

  useEffect(() => {
    checkScheduleItemStatus();
  }, [scheduleItem]);

  const checkIfHasAcceptedOutreach = () => {
    const status = scheduleItem?.scheduleItemStatusFkNavigation;
    if (status && !!status.approvedOn) {
      return true;
    }
    return false;
  };

  const checkScheduleItemStatus = () => {
    if (scheduleItem?.scheduleItemStatusFkNavigation) {
      let stepCount = -1;
      const status = scheduleItem?.scheduleItemStatusFkNavigation;
      const steps = getSteps();
      for (let index = 0; index < steps.length; index++) {
        const step = steps[index];
        if (
          step?.field &&
          status[step?.field] &&
          step?.field !== "approvedOn"
        ) {
          if (
            step?.field === "clientApprovalSentOn" &&
            status["clientApprovalStatus"] !== "approved"
          ) {
            break;
          }
          stepCount++;
        } else {
          if (step?.field === "approvedOn" && status["approvedOn"]) {
            if (!isOnboardingRequired(scheduleItem?.personFkNavigation)) {
              // TODO: make circle yellow
              stepCount++;
              continue;
            } else {
              setIsMissingOnboarding(true);
            }
          } else if (
            step?.field === "clientApprovalSentOn" &&
            status["clientApprovalDate"]
          ) {
            stepCount++;
            continue;
          }
          break;
        }
      }
      setActiveStep(stepCount);
    }
  };

  const handleStepClick = (e, step) => {
    if (step?.field === "clientApprovalSentOn") {
      handleClickClientApprovalStep(scheduleItem);
    } else if (step?.field === "contentReviewedOn") {
      handleClickReviewedOnStep(scheduleItem);
    } 
    // else if (step?.field === "contentApprovedOn") {
    //   handleClickContentApprovedOnStep(scheduleItem);
    // }
  };

  const handleCloseUpdateInfluencerDialog = () => {
    setOpenUpdateInfluencerDialog(false);
  };

  const snackbarSuccess = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={successCreate}
      autoHideDuration={2000}
      onClose={() => setSuccessCreate(false)}
    >
      <MuiAlert severity="success">Your request has been processed.</MuiAlert>
    </Snackbar>
  );

  const snackbarFailed = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={failedCreate}
      autoHideDuration={2000}
      onClose={() => setFailedCreate(false)}
    >
      <MuiAlert severity="error">Failed to send invoice</MuiAlert>
    </Snackbar>
  );

  return (
    <div className="project-campaign-stepper-root">
      {openUpdateInfluencerDialog && (
        <ContractorPopUp
          handleModal={handleCloseUpdateInfluencerDialog}
          modalState={openUpdateInfluencerDialog}
          personId={scheduleItem?.personFk}
          scheduleItemId={null}
          reloadPersonList={() => console.log("No reload")}
          reloadScheduleItemsList={() => {}}
          type="Influencer"
          providerType={"Influencer"}
          handleEditPerson={() => {}}
          selectedContact={null}
          rowUpdated={() => {}}
        />
      )}
      {showInvoiceConfirmation && (
        <ConfirmInvoiceDialog
          open={showInvoiceConfirmation}
          handleClose={() => setShowInvoiceConfirmation(false)}
          scheduleItem={scheduleItem}
          handleUpdateScheduleItemContractStatus={
            handleUpdateScheduleItemContractStatus
          }
          setSuccessCreate={setSuccessCreate}
          setFailedCreate={setFailedCreate}
        />
      )}
      {openInvalidInvoiceDialog && (
        <ConfirmationDialog
          show={openInvalidInvoiceDialog}
          titleText="Invoice Cannot Be Sent"
          contentText="The invoice can only be sent after the influencer accepts the contract."
          handleClose={() => setOpenInvalidInvoiceDialog(false)}
          buttonText="Ok, got it!"
        />
      )}
      {snackbarSuccess}
      {snackbarFailed}
      <div className="project-campaign-stepper-avatar-container">
        <Avatar
          alt="avatar"
          src={getDefaultAvatar(scheduleItem?.personFkNavigation)}
        />
        <Typography>
          <div
            onClick={() => {
              setOpenUpdateInfluencerDialog(true);
            }}
            className="link"
          >
            {scheduleItem?.personFkNavigation?.firstName +
              " " +
              scheduleItem?.personFkNavigation?.lastName}
          </div>
        </Typography>
      </div>
      <div style={{ display: "flex" }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel
                className={`${
                  isMissingOnboarding && step?.field === "approvedOn"
                    ? "project-campaign-stepper-warning"
                    : ""
                }`}
                onClick={(e) => handleStepClick(e, step, scheduleItem)}
                StepIconComponent={ColorlibStepIcon}
              >
                {isMissingOnboarding && step?.field === "approvedOn"
                  ? "Onboarding Required"
                  : step?.field === "clientApprovalSentOn" &&
                    scheduleItem?.scheduleItemStatusFkNavigation[
                      "clientApprovalStatus"
                    ] === "approved"
                  ? "Approved By Client"
                  : step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Stepper
          alternativeLabel
          connector={<ColorlibConnector />}
          className="project-campaign-stepper-root-cursor"
        >
          <Step key={"Invoiced"}>
            <StepLabel
              onClick={(e) => {
                if (
                  scheduleItem.contractStatus === "Invoiced" ||
                  scheduleItem.contractStatus === "AwaitingPayment" ||
                  scheduleItem.contractStatus === "Paid"
                ) {
                  return;
                } else if (!checkIfHasAcceptedOutreach()) {
                  setOpenInvalidInvoiceDialog(true);
                } else {
                  setShowInvoiceConfirmation(true);
                }
              }}
              StepIconComponent={() => ColorlibInvoiceStepIcon(scheduleItem)}
            >
              Invoiced
            </StepLabel>
          </Step>
        </Stepper>
      </div>
    </div>
  );
};

export default ProjectCampaignStepper;
