import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect, useLayoutEffect } from "react";
import ReconcileMediaUndersAndOversTableRow from "./ReconcileMediaUndersAndOversTableRow";
import "./reconcileMediaCard.scss";

const ReconcileMediaUndersAndOversTable = ({
  reconLines,
  handleUpdateSelectedReconLines,
  handleRemoveSelectedReconLine,
  payable,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    };

    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 50);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const TableHeadersBigScreen = () => {
    return (
      <TableHead>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Client</Typography>
        </TableCell>
        <TableCell
          className="reconcile-table-head-cell-root"
          style={{ width: 180 }}
        >
          <Typography variant="caption">Job Code</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Expected Supplier Bill</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Received Supplier Bill</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">
            Running Total of Unders/Overs
          </Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Unmatched Remaining</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Use</Typography>
        </TableCell>
      </TableHead>
    )
  }

  return (
    <Table className={width <= 1440 ? "" : "reconcile-centre-table-root"}>
      {width <= 1440 ? "" : TableHeadersBigScreen()}
      <TableBody>
        {reconLines.map((reconLine, index) => (
          <ReconcileMediaUndersAndOversTableRow
            key={index}
            reconLine={reconLine}
            handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
            handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
            payable={payable}
            width={width}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ReconcileMediaUndersAndOversTable;
