import React, {useEffect, useState} from "react";
import "./influencerOutreachDetails.scss";
import InfluencerBasicDetails from "../../InfluencersNetwork/InfluencerBasicDetails";
import {
    Avatar,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import {currencyFormat, isNumeric} from "../../../utils/GeneralUtils";
import {format} from "date-fns";

import {DeleteItemDialog} from "../../Project/Schedule/PrePost/Dialogs";
import InfluencerDeliverables from "./InfluencerDeliverables";
import {Autocomplete, Skeleton} from "@material-ui/lab";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import axios from "axios";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import moment from "moment";
import {getMonthsArray} from "../../../utils/utils";
import InfluencerDeliverableAutoChaseBtn from "./InfluencerAutoChaseBtn";
import InfluencerAutoChaseBtn from "./InfluencerAutoChaseBtn";
import {useUserContext} from "../../../context/user/UserContext";
import {useAuth} from "../../../context/auth/AuthContext";
import MultiCurrencyInputField from "../../CurrencyExchange/MultiCurrencyInputField";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import GeneralDocumentViewer from "../../Shared/Dialog/GeneralDocumentViewer";
import PullFromBudgetAutocomplete from "../../Shared/BudgetItem/PullFromBudgetAutocomplete";
import OutreachForm from "./OutreachForm/OutreachForm";

const InfluencerOutreachDetails = ({
                                       influencer,
                                       calculateRemainigInfluencerSpend,
                                       handleSaveScheduleItem,
                                       setSnackBarProps,
                                       deleteScheduleItem,
                                       budgetItems,
                                       project,
                                       handleRefreshScheduleItemList,
                                   }) => {
    const {user} = useAuth();
    const [avatar, setAvatar] = useState(null);
    const [localScheduleItem, setLocalScheduleItem] = useState(influencer);
    const [hasChanges, setHasChanges] = useState(false);
    const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
    const [showInvoicesModal, setShowInvoicesModal] = useState(false);
    const [isLoadingAvatar, setIsLoadingAvatar] = useState(true);

    const [openUpdateInfluencerDialog, setOpenUpdateInfluencerDialog] =
        useState(false);

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState(null);
    const [deliverables, setDeliverables] = useState([]);
    const [isLoadingDeliverables, setIsLoadingDeliverables] = useState([]);
    const [invoice, setInvoice] = useState(null);
    const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
    const [invoiceUrl, setInvoiceUrl] = useState("");
    const [showDocumentViewer, setShowDocumentViewer] = useState(false);
    useEffect(() => {
        var clonedItem = {...influencer};
        if (clonedItem.description === null) {
            clonedItem.description = influencer.personFkNavigation.bio;
        }
        updateLocalRecordState(clonedItem);
        resetFields();
        getInvoice();
    }, [influencer]);

    useEffect(() => {
        setIsLoadingAvatar(true);
        if (influencer?.personFkNavigation?.socialMediaProfile?.length > 0) {
            var av = influencer?.personFkNavigation?.socialMediaProfile[0].avatar;
            if (influencer?.personFkNavigation?.primarySocial == "INSTAGRAM") {
                var foundProfile =
                    influencer?.personFkNavigation?.socialMediaProfile.find(
                        (s) => s.application === "INSTAGRAM"
                    );
                if (foundProfile != null) {
                    av = foundProfile.avatar;
                }
            } else if (influencer?.personFkNavigation?.primarySocial == "TIKTOK") {
                var foundProfile =
                    influencer?.personFkNavigation?.socialMediaProfile.find(
                        (s) => s.application === "TIKTOK"
                    );
                if (foundProfile != null) {
                    av = foundProfile.avatar;
                }
            }

            setAvatar(av);
        } else {
            setAvatar("https://source.boringavatars.com/beam");
        }
        setTimeout(() => {
            setIsLoadingAvatar(false);
        }, 2000);
    }, [influencer?.id]);

    const resetFields = () => {
        setHasChanges(false);
        setInvoice(null);
        setInvoiceUrl("");
        setShowDocumentViewer(false);
    };

    const getInvoice = () => {
        if (
            influencer?.contractStatus === "AwaitingPayment" ||
            influencer?.contractStatus === "Paid"
        ) {
            setIsLoadingInvoice(true);
            axios
                .get(`invoice/getTalentInvoiceByScheduleItemId/${influencer.id}`)
                .then(({data}) => {
                    setInvoice(data);
                })
                .catch((err) => console.log(err))
                .finally(() => setIsLoadingInvoice(false));
        }
    };

    const handleDeleteItemDialogClose = () => {
        setOpenDeleteItemDialog(false);
    };

    const checkIfHasRemainingBudget = () => {
        const selectedBudget = budgetItems.find(
            (bi) => bi.budgetId === localScheduleItem.budgetId
        );

        if (!selectedBudget) {
            // Budget category not found, handle the error or return early if needed.
            setSnackBarProps({
                open: true,
                severity: "warning",
                text: "No budget selected.",
            });
            return false;
        }

        const remainingBudget =
            localScheduleItem.budgetId === influencer.budgetId
                ? selectedBudget.remaining + (influencer?.allocatedRatesInNzd || 0)
                : selectedBudget.remaining;
        const newAllocatedRates = Number(
            localScheduleItem.allocatedRatesInNzd || 0
        );
        if (remainingBudget - newAllocatedRates < 0) {
            // Not enough budget remaining, handle the error or return early if needed.
            updateLocalRecordState({
                ...localScheduleItem,
                category: influencer?.category || null,
                budgetId: influencer?.budgetId || null,
            });
            setOpenAlertDialog(true);
            setAlertDialogMessage(
                "The selected budget doesn't cover these costs. Reduce the amount or select another budget."
            );

            return false;
        }

        return true; // Return true if budget check is successful.
    };

    const isValidCampaign = () => {
        const deliverableWithBoost = deliverables?.find((d) => d.boostBudget > 0);
        if (
            !!!deliverableWithBoost &&
            localScheduleItem?.type === "paid" &&
            deliverables?.length > 0 &&
            influencer.currency === "NZD"

        ) {
            setOpenAlertDialog(true);
            setAlertDialogMessage(
                "Unable to save! This is a promoted campaign with no Boost Budget for any deliverable. Add some boost budget to proceed."
            );
            return false;
        }

        return true;
    };

    const isValidForm = () => {
        return checkIfHasRemainingBudget() && isValidCampaign();
    };
    const handeSaveChanges = async () => {
        if (isValidForm()) {
            if (localScheduleItem?.shouldSendProduct === 0) {
                localScheduleItem.productProvided = null;
            }
            await handleSaveScheduleItem(localScheduleItem);
            setHasChanges(false);
        }
        // const previousScheduleItemFee = Number(influencer?.allocatedRates || 0);
        // const remainingBudget = await calculateRemainigInfluencerSpend();
        // const remainingBudgetWithoutPreviousFee =
        //   (await calculateRemainigInfluencerSpend()) + previousScheduleItemFee;

        // if (localScheduleItem.allocatedRates <= remainingBudgetWithoutPreviousFee) {

        // } else {
        //   alert(
        //     "You don't have enough budget. Remaining budget: $" + remainingBudget
        //   );
        // }
    };

    const handleOnChange = (field, value) => {
        if (field === "startTime") {
            updateLocalRecordState({
                ...localScheduleItem,
                [field]: format(value, "yyyy-MM-dd HH:mm:ss"),
            });
        } else if (field === "budget") {
            updateLocalRecordState({
                ...localScheduleItem,
                category: value?.projectCategory || null,
                budgetId: value?.budgetId || null,
            });
        } else {
            updateLocalRecordState({...localScheduleItem, [field]: value});
        }

        setHasChanges(true);
    };

    const handlePreviewInvoice = () => {
        if (invoice && invoice.xeroInvoiceId) {
            setInvoiceUrl(`invoice/GetXeroInvoiceAsPdf/${invoice.xeroInvoiceId}/2`);
            setShowDocumentViewer(true);
        }
    };

    const handlePreviewOutreachPage = () => {
        if (
            project?.id &&
            project.token &&
            localScheduleItem?.id &&
            localScheduleItem?.personFk &&
            localScheduleItem.scheduleFk
        ) {
            window.open(
                `https://services.thompsonspencer.com/project-outreach/${project.id}/${localScheduleItem.scheduleFk}/${localScheduleItem?.id}/${localScheduleItem.personFk}/${project.token}`,
                "_blank"
            );
        }
    };

    const handlePreviewContentCentrePage = () => {
        if (localScheduleItem?.personFkNavigation) {
            window.open(
                `https://services.thompsonspencer.com/content-centre/${localScheduleItem.personFk}/${localScheduleItem.personFkNavigation?.token}`,
                "_blank"
            );
        }
    };

    const isReadOnly = () => {
        return (
            localScheduleItem?.contractStatus === "Signed" ||
            localScheduleItem?.contractStatus === "Invoiced" ||
            localScheduleItem?.contractStatus === "InvoicePending" ||
            localScheduleItem?.contractStatus === "AwaitingPayment" ||
            localScheduleItem?.contractStatus === "Paid"
        );
    };

    const updateLocalRecordState = (_localScheduleItem) => {
        setLocalScheduleItem(_localScheduleItem);
    }

    const handleUpdatePersonDetails = (influencer) => {
        if (influencer) {
            const _scheduleItemClone = {
                ...localScheduleItem,
                personFkNavigation: influencer,
            };
            updateLocalRecordState(_scheduleItemClone);
            handleRefreshScheduleItemList(_scheduleItemClone);
            setSnackBarProps({
                open: true,
                severity: "success",
                text: "Your changes have been saved!",
            });
        } else {
            setSnackBarProps({
                open: true,
                severity: "warning",
                text: "Hm, it looks like something went wrong.",
            });
        }
    };

    const handleUpdateAutoChase = (value) => {
        axios
            .post(
                `/scheduleitems/UpdateAutoChaseForScheduleItem/${localScheduleItem.id}/${value}`,
                null,
                {
                    params: {
                        user: user?.email,
                    },
                }
            )
            .then(({data}) => {
                const _scheduleItemClone = {
                    ...localScheduleItem,
                    autoChase: value ? 1 : 0,
                };
                updateLocalRecordState(_scheduleItemClone);
                handleRefreshScheduleItemList(_scheduleItemClone);

                setSnackBarProps({
                    open: true,
                    severity: "success",
                    text: value
                        ? `Reminders are now ON! Let's get this contract signed.`
                        : `Alright! ${
                            localScheduleItem?.personFkNavigation?.firstName ||
                            "The Influencer"
                        } will no longer receive contract reminders.`,
                });
            })
            .catch((err) => {
                setSnackBarProps({
                    open: true,
                    severity: "warning",
                    text: "Ops, something went wrong",
                });
            });
    };

    const handleChangeAllocatedRate = (
        currency,
        allocatedRates,
        allocatedRatesInNzd
    ) => {
        updateLocalRecordState({
            ...localScheduleItem,
            allocatedRates: allocatedRates || null,
            allocatedRatesInNzd: allocatedRatesInNzd || null,
        });
        setHasChanges(true);
    };

    return (
        <>
            {showDocumentViewer && invoiceUrl && (
                <GeneralDocumentViewer
                    modalState={showDocumentViewer}
                    handleModal={() => {
                        setInvoiceUrl("");
                        setShowDocumentViewer(false);
                    }}
                    headerColor="#000"
                    docType={"PDF"}
                    urlFile={invoiceUrl}
                />
            )}

            {openAlertDialog && alertDialogMessage && (
                <ConfirmationDialog
                    show={openAlertDialog}
                    titleText="Heads Up"
                    contentText={alertDialogMessage}
                    handleClose={() => {
                        setOpenAlertDialog(false);
                        setAlertDialogMessage(null);
                    }}
                    buttonText="Ok, got it!"
                />
            )}
            {openUpdateInfluencerDialog && localScheduleItem.personFkNavigation && (
                <ContractorPopUp
                    handleModal={(e) => setOpenUpdateInfluencerDialog(false)}
                    modalState={openUpdateInfluencerDialog}
                    personId={localScheduleItem.personFkNavigation?.id}
                    scheduleItemId={null}
                    reloadPersonList={() => console.log("No reload")}
                    reloadScheduleItemsList={() => {
                    }}
                    type="Influencer"
                    providerType={localScheduleItem.personFkNavigation.personCategory}
                    handleEditPerson={handleUpdatePersonDetails}
                    selectedContact={localScheduleItem.personFkNavigation}
                    rowUpdated={() => {
                    }}
                />
            )}
            {openDeleteItemDialog && (
                <DeleteItemDialog
                    handleDeleteItemDialogClose={handleDeleteItemDialogClose}
                    setShowInvoicesModal={setShowInvoicesModal}
                    deleteScheduleItem={deleteScheduleItem}
                    scheduleItem={localScheduleItem}
                    setSnackBarProps={setSnackBarProps}
                    openDeleteItemDialog={openDeleteItemDialog}
                />
            )}
            <div className="influencer-outreach-details-root">
                <div className="influencer-outreach-details-header">
                    <div className="influencer-outreach-details-name-container">
                        {isLoadingAvatar ? (
                            <Skeleton variant="circle" width={40} height={40}/>
                        ) : (
                            <Avatar
                                className="influencer-outreach-details-avatar-image"
                                alt="avatar"
                                src={avatar}
                            />
                        )}
                        <div className="influencer-outreach-details-name">
                            <Typography
                                variant="h6"
                                className="influencer-outreach-details-name-link"
                                onClick={(e) => setOpenUpdateInfluencerDialog(true)}
                            >
                                {localScheduleItem.personFkNavigation?.firstName +
                                    " " +
                                    localScheduleItem.personFkNavigation?.lastName}
                            </Typography>
                            <Typography
                                className="influencer-outreach-details-container-link"
                                variant="caption"
                                onClick={handlePreviewOutreachPage}
                            >
                                {localScheduleItem.scheduleItemType?.toLowerCase()} contract:{" "}
                                {localScheduleItem?.contractStatus}
                            </Typography>
                            <Typography
                                className="influencer-outreach-details-container-link"
                                variant="caption"
                                onClick={handlePreviewContentCentrePage}
                            >
                                content centre
                            </Typography>
                            {localScheduleItem?.statementOfWork && (
                                <Typography
                                    className="influencer-outreach-details-container-link"
                                    variant="caption"
                                    onClick={() =>
                                        window.open(
                                            `https://drive.google.com/file/d/${localScheduleItem?.statementOfWork}/view`
                                        )
                                    }
                                >
                                    statement of work
                                </Typography>
                            )}
                            {localScheduleItem?.purchaseOrder?.length > 0 && (
                                <Typography
                                    className="influencer-outreach-details-container-link"
                                    variant="caption"
                                    onClick={() =>
                                        window.open(
                                            `/purchaseOrder/GetPurchaseOrderPdfByNumber/${localScheduleItem.purchaseOrder[0]?.purchaseOrderNumber}`, "_blank"
                                        )
                                    }
                                >
                                    {`purchase order - sent on ${moment(localScheduleItem.purchaseOrder[0]?.date)?.format("DD MMM YYYY")}`}
                                </Typography>
                            )}
                            {isLoadingInvoice && <Skeleton/>}
                            {!isLoadingInvoice && invoice?.xeroInvoiceId && (
                                <div>
                                    <Typography
                                        className="influencer-outreach-details-container-link"
                                        variant="caption"
                                        onClick={handlePreviewInvoice}
                                    >
                                        {`invoice ${
                                            invoice.paidOn
                                                ? "paid on: " +
                                                moment(invoice.paidOn).format("DD/MM/YYYY")
                                                : invoice.dueDate
                                                    ? "due on: " +
                                                    moment(invoice.dueDate).format("DD/MM/YYYY")
                                                    : ""
                                        }`}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="influencer-outreach-details-autochase-container">
                        {(localScheduleItem?.contractStatus === "Send" ||
                            localScheduleItem?.contractStatus === "Pending") && (
                            <InfluencerAutoChaseBtn
                                isChecked={!!localScheduleItem?.autoChase}
                                handleChange={handleUpdateAutoChase}
                                title="Contract Chase"
                            />
                        )}
                    </div>
                </div>
                <div className="influencer-outreach-details-divider"></div>
                <OutreachForm
                    record={localScheduleItem}
                    handleOnChange={handleOnChange}
                    isReadOnly={isReadOnly}
                    handleChangeAllocatedRate={handleChangeAllocatedRate}
                    budgetItems={budgetItems}
                    handleSaveScheduleItem={handleSaveScheduleItem}
                    updateLocalRecordState={updateLocalRecordState}
                    hasChanges={hasChanges}
                    handeSaveChanges={handeSaveChanges}
                    setOpenDeleteItemDialog={setOpenDeleteItemDialog}
                    setShowInvoicesModal={setShowInvoicesModal}
                />
                <div className="influencer-outreach-details-footer">
                    <InfluencerDeliverables
                        influencer={localScheduleItem}
                        isReadOnly={isReadOnly}
                        deliverables={deliverables}
                        setDeliverables={setDeliverables}
                        isLoadingDeliverables={isLoadingDeliverables}
                        setIsLoadingDeliverables={setIsLoadingDeliverables}
                    />
                </div>
            </div>
        </>
    );
};

export default InfluencerOutreachDetails;
