import React, { useEffect, useState } from "react";
import "./addNewScheduleItemForm.scss";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import RoleAutocomplete from "../../../Shared/Inputs/RoleAutocomplete";
import PersonAutocomplete from "../../../Shared/Inputs/PersonAutocomplete";
import MultiCurrencyInputField from "../../../CurrencyExchange/MultiCurrencyInputField";
import { isEmpty, isNumeric } from "../../../../utils/GeneralUtils";
import DateFnsUtils from "@date-io/date-fns";
import AccessTime from "@material-ui/icons/AccessTime";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format } from "date-fns";
import { connect } from "react-redux";
import axios from "axios";

const AddNewScheduleItemForm = ({
  scheduleItemCategories = ["Talent", "Contractor"],
  budgetItems,
  scheduleItem,
  setScheduleItem,
  handleSubmitForm,
  isSavingScheduleItem,
  showTimePicker = false,
  addUnpaidOption = false,
  companyId = null,
  tasks,
  selectedCompanyIdForInternal,
  setSelectedCompanyIdForInternal,
  isInternal,
  clientList
}) => {
  const [renderPersonTextbox, setRenderPersonTextbox] = useState(true);

  useEffect(() => {
    if (!renderPersonTextbox) {
      setRenderPersonTextbox(true);
    }
  }, [renderPersonTextbox])

  useEffect(() => {
    setRenderPersonTextbox(false)
  }, [clientList])

  useEffect(() => {
    setScheduleItem(
      scheduleItem || {
        startTime: new Date(),
      }
    );
  }, [scheduleItem]);

  const handleOnChange = (field, value) => {
    if (field === "budget") {
      setScheduleItem({
        ...scheduleItem,
        category: value?.category || null,
        budgetId: value?.budgetId || null,
        allocatedRates:
          value?.category === "Unpaid" ? 0 : scheduleItem.allocatedRates || 0,
        allocatedRatesInNzd:
          value?.category === "Unpaid"
            ? 0
            : scheduleItem.allocatedRatesInNzd || 0,
      });
    } else if (field === "role") {
      setScheduleItem({
        ...scheduleItem,
        roleFk: value?.id || null,
        roleFkNavigation: value || null,
      });
    } else if (field === "person") {
      setScheduleItem({
        ...scheduleItem,
        personFk: value?.id || null,
        personFkNavigation: value || null,
      });
    } else {
      setScheduleItem({
        ...scheduleItem,
        [field]: value,
      });
    }
  };
  const handleChangeAllocatedRate = (
    currency,
    allocatedRates,
    allocatedRatesInNzd
  ) => {
    setScheduleItem({
      ...scheduleItem,
      allocatedRates: allocatedRates || null,
      allocatedRatesInNzd: allocatedRatesInNzd || null,
    });
  };
  const handeSaveChanges = () => {
    handleSubmitForm();
  };

  const shouldDisableFee = () => {
    if (
      isEmpty(scheduleItem?.personFkNavigation) ||
      scheduleItem.category === "Unpaid"
    )
      return true;
    return false;
  };
  return (
    <div className="add-new-schedule-item-form-root">
      <div className="add-new-schedule-item-form-flex-container">
        <Autocomplete
          className="add-new-schedule-item-form-field"
          options={scheduleItemCategories}
          getOptionLabel={(option) => option}
          onChange={(e, newValue) => {
            handleOnChange("scheduleItemType", newValue);
          }}
          value={scheduleItem.scheduleItemType || null}
          disableClearable
          selectOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type"
              placeholder={scheduleItemCategories?.join("/")}
              size="small"
              fullWidth={true}
              variant="outlined"
            />
          )}
        />
        {scheduleItem?.scheduleItemType !== "Client" &&
          scheduleItem?.scheduleItemType !== "Staff" ? (
          <Autocomplete
            className="add-new-schedule-item-form-field"
            options={
              addUnpaidOption
                ? [{ category: "Unpaid" }].concat(
                  budgetItems?.filter((b) => b.totalAmount <= b.quoted)
                )
                : budgetItems?.filter((b) => b.totalAmount <= b.quoted)
            }
            getOptionLabel={(option) => option?.category}
            onChange={(e, newValue) => {
              handleOnChange("budget", newValue || null);
            }}
            value={
              scheduleItem.category?.includes("Unpaid")
                ? { category: "Unpaid" }
                : budgetItems?.find(
                  (bi) => bi.budgetId === scheduleItem?.budgetId
                ) || null
            }
            selectOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pull from Budget"
                size="small"
                fullWidth={true}
                variant="outlined"
              />
            )}
          />
        ) : scheduleItem?.scheduleItemType === "Staff" ? (
          <Autocomplete
            className="add-new-schedule-item-form-field"
            options={tasks || []}
            getOptionLabel={(option) => option?.category}
            onChange={(e, newValue) => {
              handleOnChange("budget", newValue || null);
            }}
            value={tasks.find(t => t.category === scheduleItem.category) || null}
            selectOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tasks"
                size="small"
                fullWidth={true}
                variant="outlined"
              />
            )}
          />
        ) : (
          ""
        )}
        {scheduleItem?.scheduleItemType !== "Client" && (
          <div className="add-new-schedule-item-form-field">
            <RoleAutocomplete
              role={scheduleItem.roleFkNavigation}
              category={scheduleItem.scheduleItemType}
              handleSelectRole={(newRole) => handleOnChange("role", newRole)}
            />
          </div>
        )}
        {scheduleItem?.scheduleItemType === "Client" && isInternal && (
          <div className="add-new-schedule-item-form-field">
            <Autocomplete
              disableClearable
              options={clientList}
              getOptionLabel={(option) => option?.name}
              onChange={(e, newValue) => {
                setSelectedCompanyIdForInternal(newValue.id);
              }}
              value={clientList.find(c => c.id === selectedCompanyIdForInternal) || null}
              selectOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                />
              )}
            />
          </div>
        )}
        <div className="add-new-schedule-item-form-field">
          {((renderPersonTextbox && scheduleItem?.scheduleItemType === "Client") || scheduleItem?.scheduleItemType !== "Client") && (
            <PersonAutocomplete
              person={scheduleItem.personFkNavigation}
              categories={
                scheduleItem.scheduleItemType
                  ? [scheduleItem.scheduleItemType]
                  : []
              }
              handleSelectPerson={(newPerson) =>
                handleOnChange("person", newPerson)
              }
              companyId={isInternal ? selectedCompanyIdForInternal : companyId}
            />
          )}
        </div>
        {scheduleItem?.scheduleItemType !== "Client" &&
          scheduleItem?.scheduleItemType !== "Staff" && (
            <div className="add-new-schedule-item-form-field">
              {scheduleItem?.personFkNavigation?.currency === "NZD" ||
                scheduleItem?.category === "Unpaid" ? (
                <TextField
                  className="add-new-schedule-item-form-field"
                  label="Amount"
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={scheduleItem?.allocatedRates}
                  onChange={(e) =>
                    isNumeric(e.target.value) || e.target.value === ""
                      ? handleChangeAllocatedRate(
                        "",
                        e.target.value,
                        e.target.value
                      )
                      : {}
                  }
                  disabled={shouldDisableFee()}
                />
              ) : (
                <MultiCurrencyInputField
                  totalAmount={scheduleItem?.allocatedRates || 0}
                  nzdTotalAmount={scheduleItem?.allocatedRatesInNzd || 0}
                  size="small"
                  currency={scheduleItem?.personFkNavigation?.currency}
                  handleChange={handleChangeAllocatedRate}
                  isReadOnly={shouldDisableFee()}
                  lockDropdown={true}
                />
              )}
            </div>
          )}

        {showTimePicker ? (
          <div
            className="add-new-schedule-item-form-field"
            style={{ display: "flex", gap: 5 }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                label="Start Time *"
                size="small"
                inputVariant={"outlined"}
                value={scheduleItem.startTime}
                onChange={(date, value) => {
                  if (date instanceof Date && !isNaN(date)) {
                    handleOnChange(
                      "startTime",
                      format(date, "yyyy-MM-dd HH:mm:ss")
                    );
                  }
                }}
                keyboardIcon={<AccessTime />}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                label="End Time *"
                size="small"
                inputVariant={"outlined"}
                value={scheduleItem.endTime}
                onChange={(date, value) => {
                  if (date instanceof Date && !isNaN(date)) {
                    handleOnChange(
                      "endTime",
                      format(date, "yyyy-MM-dd HH:mm:ss")
                    );
                  }
                }}
                keyboardIcon={<AccessTime />}
              />
            </MuiPickersUtilsProvider>
          </div>
        ) : (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="add-new-schedule-item-form-field"
              format="dd/MM/yyyy"
              label={"Due Date"}
              inputVariant={"outlined"}
              size="small"
              value={scheduleItem.startTime}
              onChange={(date, value) => {
                if (date instanceof Date && !isNaN(date)) {
                  handleOnChange(
                    "startTime",
                    format(date, "yyyy-MM-dd HH:mm:ss")
                  );
                }
              }}
              KeyboardButtonProps={{
                style: {
                  ...{
                    padding: "0px",
                    margin: "0px",
                  },
                },
              }}
            />
          </MuiPickersUtilsProvider>
        )}
      </div>
      <div className="add-new-schedule-item-form-action-container">
        {isSavingScheduleItem ? (
          <CircularProgress size={20} />
        ) : (
          <Button
            size="small"
            variant="contained"
            className={`default-button`}
            onClick={handeSaveChanges}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  tasks: state.budget.tasks
});
export default connect(mapStateToProps, {})(AddNewScheduleItemForm);
