import { InputAdornment, TextField } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import MediaSpendBudgetAllocationInput from "./MediaSpendBudgetAllocationInput";
import MediaSpendFormDialog from "./MediaSpendFormDialog";
import axios from "axios";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";

const MediaSpendBudgetAllocation = ({
  mediaCampaign,
  mediaSpendList,
  budgetItems,
  setSnackBarProps,
  handleUpdateMediaSpend,
  fetchAllBudget,
  datesToDisplay,
  isClientView,
  commissionRate
}) => {
  const [selectedMediaSpend, setSelectedMediaSpend] = useState(null);
  const [openMediaSpendDialog, setOpenMediaSpendDialog] = useState(false);
  const [openSelectCampaignBudgetDialog, setOpenSelectCampaignBudgetDialog] = useState(false);
  const [isSavingMediaSpend, setIsSavingMediaSpend] = useState(false);  

  const handleUpdateAllocation = (date, mediaSpend) => {
    if(mediaCampaign.budgetId){
      if (mediaSpend) {
        setSelectedMediaSpend(mediaSpend);
      } else {
        setSelectedMediaSpend({
          billingDate: date.endOf('month').format("YYYY-MM-DD"),
          mediaCampaignFk: mediaCampaign.id,
          budgetId: mediaCampaign.budgetId,
          code: mediaCampaign.budget?.jobId ? `${mediaCampaign.budget?.jobId}_${date.endOf('month').format("MMMYY")}_${mediaCampaign.name}` : null
        });
      }
      setOpenMediaSpendDialog(true);
    }else{
      setOpenSelectCampaignBudgetDialog(true)
    }
    
  };

  const handleCloseFormDialog = () => {
    setSelectedMediaSpend(null);
    setOpenMediaSpendDialog(false);
  };

  const handleSaveMediaSpend = () => {
    setIsSavingMediaSpend(true);
    axios
      .post(`/mediacampaign/UpdateMediaSpend`, selectedMediaSpend)
      .then(async ({ data }) => {
        await fetchAllBudget()
        handleUpdateMediaSpend(mediaCampaign?.id, data)
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Great! Your changes have been saved.",
        });
        handleCloseFormDialog();
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to save budget allocation",
        });
      })
      .finally(() => setIsSavingMediaSpend(false));
  };

  return (
    <div className="media-spend-allocation-container">
      {openSelectCampaignBudgetDialog && (
        <ConfirmationDialog
          show={openSelectCampaignBudgetDialog}
          titleText="Please select a budget to pull from..."
          contentText="Sorry, but you haven't selected a budget. You can edit the campaign and select a budget. =)"
          handleClose={() => setOpenSelectCampaignBudgetDialog(false)}
          buttonText="Ok, got it!"
        />
      )}
      {openMediaSpendDialog && selectedMediaSpend && (
        <MediaSpendFormDialog
          open={openMediaSpendDialog}
          handleClose={handleCloseFormDialog}
          setSelectedMediaSpend={setSelectedMediaSpend}
          selectedMediaSpend={selectedMediaSpend}
          budgetItems={budgetItems}
          isSavingMediaSpend={isSavingMediaSpend}
          handleSubmit={handleSaveMediaSpend}
          isClientView={isClientView}
          commissionRate={commissionRate}
        />
      )}
      {datesToDisplay.map((date, index) => (
        <div className="budget-allocation-container" key={index}>
          <MediaSpendBudgetAllocationInput
            mediaSpend={mediaSpendList?.find(
              (ms) =>
                moment(ms.billingDate)?.format("MMM YY") ===
                date.format("MMM YY")
            )}
            date={date}
            handleUpdateAllocation={handleUpdateAllocation}
            isClientView={isClientView}
            currency={mediaCampaign.budget?.currency || "NZD"}
          />
        </div>
      ))}
    </div>
  );
};

export default MediaSpendBudgetAllocation;
