import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { setSnackBarProps } from "../../../actions/snackbar";
import {
  getSchedulesForTable,
  archiveSchedule,
} from "../../../actions/schedules";

import Snacky from "../../Shared/Snacky";
import { format } from "date-fns";
import { withStyles, styled } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import Loader from "../../Loader";
import CustomTableHead from "../../Shared/CustomTableHead";


const tableHeader = [
  {
    field: "scheduleName",
    name: "Name",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "scheduleAddresses",
    name: "Address",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "scheduleDate",
    name: "date",
    fieldType: "date",
    alignment: "left",
  },
  {
    field: "startTime",
    name: "Start Time",
    fieldType: "date",
    alignment: "left",
  },
  {
    field: "finishTime",
    name: "End Time",
    fieldType: "date",
    alignment: "left",
  },
  {
    field: "name",
    name: "",
    fieldType: "string",
    alignment: "left",
  },
];

const StyledPaper = styled(Paper)({
  width: "100%",
});

//------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#9E9E9E",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  body: {
    fontSize: "1em",
  },
}))(TableCell);


const StyledButton = styled(Button)({
  color: "#000000",
  borderRadius: "20%",
});

const ScheduleTable = ({
  getSchedulesForTable,
  archiveSchedule,
  schedulesForTable,
  setSnackBarProps,
  loading,
  snackbar,
  project,
}) => {
  const initialDialogProps = {
    titleText: "",
    contentText: "",
    buttonOneText: "",
    buttonTwoText: "",
    show: false,
    scheduleId: null,
  };
  const [dialogProps, setDialogProps] = useState(initialDialogProps);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "scheduleName",
    name: "Name",
    fieldType: "string",
  });

  const history = useHistory();
  const location = useLocation();

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleDeleteSchedule = (id, hasInvoices, index) => {
    if (hasInvoices) {
      setDialogProps({
        titleText: "This schedule cannot be archived",
        contentText: "It contains invoiced items",
        buttonOneText: "",
        buttonTwoText: "OK",
        show: true,
        scheduleId: null,
      });
    } else {
      setDialogProps({
        titleText: "Do you want to archive this schedule?",
        contentText: "",
        buttonOneText: "Archive",
        buttonTwoText: "Cancel",
        show: true,
        scheduleId: id,
        index: index,
      });
    }
  };

  const dialogButtonOne = () => {
    return (
      <Button
        onClick={() => {
          archiveSchedule(dialogProps.scheduleId, dialogProps.index);
          setDialogProps(initialDialogProps);
        }}
        color="primary"
      >
        {dialogProps.buttonOneText}
      </Button>
    );
  };
  const dialogButtonTwo = () => {
    return (
      <Button
        onClick={() => {
          setDialogProps(initialDialogProps);
        }}
        color="primary"
      >
        {dialogProps.buttonTwoText}
      </Button>
    );
  };

  const dialog = () => {
    return (
      <Dialog
        open={dialogProps.show}
        onClose={() => setDialogProps(initialDialogProps)}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {dialogProps.titleText}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            {dialogProps.contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}>
          {dialogProps.buttonOneText !== "" && dialogButtonOne()}
          {dialogButtonTwo()}
        </DialogActions>
      </Dialog>
    );
  };

  const returnAddressText = (addresses) => {
    if (addresses.length === 1) {
      return addresses[0];
    } else {
      return addresses.length + " locations";
    }
  };

  const navigateToSchedule = (id) => {
    history.push(location.pathname + "/" + id + "/0");
  };

  useEffect(() => {
    getSchedulesForTable(location.pathname.split("/")[3]);
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <StyledPaper>
        {snackbar.open && (
          <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
        )}
        {dialogProps.show && dialog()}
        {schedulesForTable.length === 0 ? (
          <h3 style={{ textAlign: "center" }}>
            No schedules have been created for this project yet
          </h3>
        ) : (
          <TableContainer>
            <table className="custom-table custom-table-border custom-table-hover ts-default-table">
              <CustomTableHead
                headers={tableHeader}
                orderBy={orderBy}
                order={order}
                onRequestSort={handleOnRequestSort}
              />
              <tbody>
                {schedulesForTable.map((schedule, index) => (
                  <tr key={schedule.scheduleID} style={{cursor: "pointer"}}>
                    <td
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      <Typography variant="caption">{schedule.scheduleName}</Typography>
                    </td>
                    <td
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      <Typography variant="caption">{returnAddressText(schedule.scheduleAddresses)}</Typography>
                    </td>
                    <td
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      <Typography variant="caption">{format(
                        new Date(schedule.scheduleDate),
                        "iiii, dd/MM/yyyy"
                      )}</Typography>
                    </td>
                    <td
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      <Typography variant="caption">{new Date(schedule.startTime).getFullYear() != "1970" &&
                        format(new Date(schedule.startTime), "hh:mm a")}</Typography>
                    </td>
                    <td
                      onClick={() => navigateToSchedule(schedule.scheduleID)}
                    >
                      <Typography variant="caption">{new Date(schedule.startTime).getFullYear() != "1970" &&
                        format(new Date(schedule.finishTime), "hh:mm a")}</Typography>
                    </td>
                    <td>
                      {project.isArchived !== 1 && (
                        <StyledButton
                          onClick={() =>
                            handleDeleteSchedule(
                              schedule.scheduleID,
                              schedule.hasInvoices,
                              index
                            )
                          }
                        >
                          <Delete style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        </StyledButton>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        )}
      </StyledPaper>
    );
  }
};

const mapStateToProps = (state) => ({
  schedulesForTable: state.schedules.schedulesForTable,
  loading: state.schedules.loadingSchedulesForTable,
  snackbar: state.snackbar,
  project: state.schedules.project,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  getSchedulesForTable,
  archiveSchedule,
  setSnackBarProps,
})(ScheduleTable);
