import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  resetSchedule,
  resetProject,
  resetScheduleTable,
} from "../actions/schedules";
import { resetOrphans } from "../actions/projects";
import { resetScheduleItems } from "../actions/scheduleItems";
import negDaysLeft from "../utils/workingDaysLeftNegatives";
import { resetBudget } from "../actions/budget";
import ViewListIcon from "@material-ui/icons/ViewList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DashboardIcon from "@material-ui/icons/Dashboard";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Tooltip,
} from "@material-ui/core";
import "./Projects.css";
import {
  Menu,
  ExpandMore,
  ExpandLess,
  TimerOutlined,
  Timer,
} from "@material-ui/icons";
import HomeIcon from "@material-ui/icons/Home";
import { format } from "date-fns";
import AdminPanel from "./Admin/AdminPanel";
import TSHeaderLogo from "../assets/images/TSHeaderLogo";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBarColor: {
    background: "red",
  },
  stickyAppBar: {
    position: "fixed",
    top: 0,
    width: "100%",
  },
}));

const ButtonAppBar = (props) => {
  const classes = useStyles();
  const projects = props.project;
  let history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleRedirectToHomePage = () => {
    const urlPathName = window.location.pathname;

    if (
      urlPathName?.toLowerCase().includes("client/home") ||
      urlPathName?.toLowerCase().includes("campaign/masterstatus") ||
      urlPathName?.toLowerCase().includes("influencer")
    ) {
      history.push("/v2/clients");
    } else {
      history.push("/");
      props.resetProject();
      props.resetBudget();
      props.resetSchedule();
      props.resetScheduleItems();
      props.resetScheduleTable();
    }
  };

  return (
    <div className="shared-header-root">
      {props.parent == "HomeTable" && (
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <IconButton
            onClick={props.handleOpenAllProjects}
            style={{
              color: "white",
              backgroundColor: "#000000",
              left: 5,
              padding: 5,
            }}
          >
            {!props.openAll ? <ExpandMore /> : <ExpandLess />}
          </IconButton>

          <Tooltip title="View/Hide Internal Projects">
            <IconButton
              onClick={() =>
                props.setShowInternalProjects(!props.showInternalProjects)
              }
              style={{
                color: "white",
                backgroundColor: "#000000",
                right: 5,
                padding: 5,
              }}
            >
              {!props.showInternalProjects ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </Tooltip>
        </div>
      )}
      {props.parent == "KanbanBoard" && (
        <Fragment>
          <IconButton
            onClick={() => props.setShowHomeTable(true)}
            style={{
              color: "white",
              backgroundColor: "#000000",
              left: 5,
              padding: 5,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={props.toggleOpenAll}
            style={{
              color: "white",
              backgroundColor: "#000000",
              left: 50,
              padding: 5,
            }}
          >
            {!props.openAll ? <ExpandMore /> : <ExpandLess />}
          </IconButton>

          <IconButton
            onClick={props.toggleShowExtraInfo}
            style={{
              color: "white",
              backgroundColor: "#000000",
              right: 5,
              padding: 5,
            }}
          >
            {!props.showExtraInfo ? <TimerOutlined /> : <Timer />}
          </IconButton>
          <Tooltip title="View/Hide Internal Projects">
            <IconButton
              onClick={() =>
                props.setShowInternalProjects(!props.showInternalProjects)
              }
              style={{
                color: "white",
                backgroundColor: "#000000",
                right: 50,
                padding: 5,
              }}
            >
              {!props.showInternalProjects ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </Tooltip>
        </Fragment>
      )}
      {projects ? (
        <div style={{display: "flex", alignItems: "center"}}>
          <Typography variant="h6" className={classes.title} align="center">
            {props.project.projectTag}: {props.project.projectName}
          </Typography>
          <Typography style={{ marginRight: 5, fontSize: ".8rem" }}>
            {"Date approved: " +
              format(new Date(props.project.dateApproved), "dd/MM/yy")}
          </Typography>
          <span className="days-left-green" style={{ fontSize: ".7rem" }}>
            {negDaysLeft(new Date(props.project.dateApproved), "A")}
          </span>
          {props.project.expectedTransmissionDate && <>
            <Typography style={{ fontSize: ".8rem" }}>
              {"Originally due: " +
                format(
                  new Date(props.project.expectedTransmissionDate),
                  "dd/MM/yy"
                )}
            </Typography>
            <span
              style={{ fontSize: ".7rem" }}
              className={
                negDaysLeft(
                  new Date(props.project.expectedTransmissionDate),
                  "D"
                ) > 0
                  ? "days-left-red"
                  : "days-left-green"
              }
            >
              {negDaysLeft(new Date(props.project.expectedTransmissionDate), "D")}
            </span>
          </>}
        </div>
      ) : (
        <Typography variant="h6" className={classes.title} align="center">
          {props.view == "archivedProjects" && props.parent != "KanbanBoard"
            ? "Archived Projects"
            : props.title
            ? props.title
            : ""}
        </Typography>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({});

// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  resetSchedule,
  resetScheduleItems,
  resetProject,
  resetBudget,
  resetScheduleTable,
  resetOrphans,
})(ButtonAppBar);
