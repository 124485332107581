import React, { useEffect } from "react";
import "./updateInfluencerForm.scss";
import { useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Switch,
  Fab,
  AppBar,
  Toolbar,
  FormGroup,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import {
  getCurrencies,
  isEmpty,
  validateEmail,
} from "../../utils/GeneralUtils";
import axios from "axios";
import InfluencerBasicCard from "./InfluencerBasicCard";
import CurrencyDropdownMenu from "../CurrencyExchange/CurrencyDropdownMenu";
import { useDataContext } from "../../context/data/DataContext";
import { Autocomplete } from "@material-ui/lab";

const initialInfluencerState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  address: "",
  phoneNumber: "",
  socialMediaProfile: [],
  bcOrIc: "ic",
  wtRate: "20",
  organisationFk: 5,
  bankAccount: "",
  ird: "",
  gstRegistered: 0,
  bcWtr: null,
  bcIsGstReg: null,
  bcIrd: null,
  bcBankAccount: null,
  currency: "NZD",
};

const initialSocialMediaProfile = {
  id: 0,
  application: "",
  avatar: "",
  profileUsername: "",
  profileDescription: "",
  followers: null,
  posts: null,
  videos: null,
  hearts: null,
  shares: null,
};

const initialErrorState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  bankAccount: "",
  ird: "",
  branchCode: "",
};
const UpdatePoiInfluencerForm = ({
  handleCancel,
  handleSubmit,
  selectedInfluencer,
  insideEditModal = false,
  isEditing = false,
  setSocialMediaProfiles,
  setShouldMoveDialog,
  isSaving
}) => {
  const { industries, ageCategories } = useDataContext();
  const [influencer, setInfluencer] = useState(initialInfluencerState);
  const [errorForm, setErrorForm] = useState(initialErrorState);
  const [instagramDetails, setInstagramDetails] = useState(null);
  const [tiktokDetails, setTikTokDetails] = useState(null);
  const [searchTermInstagram, setSearchTermInstagram] = useState(null);
  const [instagramLoading, setInstagramLoading] = useState(false);
  const [searchTermTikTok, setSearchTermTikTok] = useState(null);
  const [tiktokLoading, setTiktokLoading] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (selectedInfluencer) {
      if (selectedInfluencer.socialMediaProfile) {
        var instagramProfile = selectedInfluencer.socialMediaProfile.find(
          (s) => s.application === "INSTAGRAM"
        );
        var tiktokProfile = selectedInfluencer.socialMediaProfile.find(
          (s) => s.application === "TIKTOK"
        );

        if (instagramProfile) {
          setInstagramDetails(instagramProfile);
          setSearchTermInstagram(instagramProfile.influencerId);
        }
        if (tiktokProfile) {
          setTikTokDetails(tiktokProfile);
          setSearchTermTikTok(tiktokProfile.influencerId);
        }
      }
      setInfluencer(selectedInfluencer);
    }
  }, []);

  const handleChangeInput = (field, value) => {
    if (field === "currency" && value !== "NZD") {
      setInfluencer({
        ...influencer,
        [field]: value,
        ird: null,
        gstRegistered: 0,
        wtRate: null,
      });
    } else {
      setInfluencer({ ...influencer, [field]: value });
    }
  };

  const validateForm = () => {
    let isValidForm = true;
    const tempError = { ...initialErrorState };

    if (isEmpty(influencer.firstName)) {
      tempError.firstName = "Required field";
      isValidForm = false;
    }
    if (isEmpty(influencer.lastName)) {
      tempError.lastName = "Required field";
      isValidForm = false;
    }

    if (!validateEmail(influencer.emailAddress)) {
      tempError.emailAddress = "Enter a valid email";
      isValidForm = false;
    }
    if (influencer.bankAccount?.length > 19 && influencer.currency === "NZD") {
      tempError.bankAccount = "Too long (should be 16 numbers)";
      isValidForm = false;
    }
    if (isEmpty(influencer.bankAccount)) {
      tempError.bankAccount = "Required field";
      isValidForm = false;
    }
    if (isEmpty(influencer.branchCode) && influencer.currency === "AUD") {
      tempError.branchCode = "Required field";
      isValidForm = false;
    }
    if (isEmpty(influencer.address) && influencer.currency === "AUD") {
      tempError.address = "The address is required for international payments";
      isValidForm = false;
    }
    if (influencer.ird?.length > 9 && influencer.currency === "NZD") {
      tempError.ird = "Too long (should be 9 numbers)";
      isValidForm = false;
    }
    if (isEmpty(influencer.ird) && influencer.currency === "NZD") {
      tempError.ird = "Required field";
      isValidForm = false;
    }
    if (isEmpty(influencer.bcOrIc)) {
      tempError.bcOrIc = "Required field";
      isValidForm = false;
    }
    if (influencer.bcOrIc == "bc" && isEmpty(influencer.bcName)) {
      tempError.bcName = "Required field";
      isValidForm = false;
    }

    if (!isValidForm) {
      setErrorForm(tempError);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    return isValidForm;
  };

  const handleSave = () => {
    if (validateForm()) {
      let _influencer = { ...influencer };
      const isContractor = _influencer.bcOrIc == "ic";
      const bankAccountDetails = {
        invoiceAs: isContractor ? "contractor" : "company",
        accountNumber: influencer.bankAccount,
        accountName: isContractor
          ? influencer.firstName.trim() + " " + influencer.lastName.trim()
          : !isEmpty(_influencer.bcName?.trim())
          ? _influencer.bcName?.trim()
          : influencer.firstName.trim() + " " + influencer.lastName.trim(),
        taxNumber: _influencer.currency != "NZD" ? null : _influencer.ird,
        isTaxRegistered:
          _influencer.currency != "NZD" ? 0 : _influencer.gstRegistered,
        wtRate:
          _influencer.currency != "NZD"
            ? null
            : isContractor
            ? _influencer.wtRate
            : null,
        countryCode: getCurrencies().find(
          (c) => c.value === _influencer.currency
        ).code,
      };
      _influencer.bankAccountNavigation = bankAccountDetails;

      if (_influencer.bcOrIc == "bc") {
        _influencer.bcWtr = null;
        _influencer.bcIsGstReg = _influencer.gstRegistered;
        _influencer.bcIrd = _influencer.ird;
        _influencer.bcBankAccount = _influencer.bankAccount;

        _influencer.wtRate = null;
        _influencer.gstRegistered = null;
        _influencer.ird = null;
        _influencer.bankAccount = null;

        _influencer.firstName.trim();
        _influencer.lastName.trim();
        _influencer.bcName.trim();
      }

      handleSubmit(_influencer, file);
    }
  };

  const handleInstagramSearch = () => {
    setInstagramLoading(true);
    axios
      .get(`Influencer/GetInstagramBasic/${searchTermInstagram}`)
      .then((res) => {
        if (res.data.error) {
          setInstagramDetails(null);
          let profiles = [...influencer.socialMediaProfile];
          setInfluencer({
            ...influencer,
            socialMediaProfile: profiles.filter(
              (p) => p.application !== "INSTAGRAM"
            ),
          });
          if (isEditing && insideEditModal) {
            setSocialMediaProfiles(
              profiles.filter((p) => p.application !== "INSTAGRAM")
            );
          }
        } else {
          var newProfile = {
            ...initialSocialMediaProfile,
            followers: res.data.business_discovery.followers_count,
            posts: res.data.business_discovery.media_count,
            profileUsername: res.data.business_discovery.name,
            avatar: res.data.business_discovery.profile_picture_url,
            profileDescription: res.data.business_discovery.biography,
            application: "INSTAGRAM",
            influencerId: res.data.business_discovery.username,
          };
          setInstagramDetails(newProfile);
          let profiles = [...influencer.socialMediaProfile];
          profiles = profiles.filter((p) => p.application !== "INSTAGRAM");
          profiles.push(newProfile);
          setInfluencer({ ...influencer, socialMediaProfile: profiles });
          if (isEditing && insideEditModal) {
            setSocialMediaProfiles(profiles);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setInstagramLoading(false));
  };

  const handleTikTokSearch = () => {
    setTiktokLoading(true);
    axios
      .get(`Influencer/GetTikTokBasic/${searchTermTikTok}`)
      .then((res) => {
        if (res.data.error) {
          setTikTokDetails(null);
          let profiles = [...influencer.socialMediaProfile];
          setInfluencer({
            ...influencer,
            socialMediaProfile: profiles.filter(
              (p) => p.application !== "TIKTOK"
            ),
          });
          if (isEditing && insideEditModal) {
            setSocialMediaProfiles(
              profiles.filter((p) => p.application !== "TIKTOK")
            );
          }
        } else {
          var newProfile = {
            ...initialSocialMediaProfile,
            influencerId: res.data.userInfo.user.uniqueId,
            application: "TIKTOK",
            avatar: res.data.userInfo.user.avatarMedium,
            profileUsername: res.data.userInfo.user.nickname,
            profileDescription: res.data.userInfo.user.signature,
            followers: res.data.userInfo.stats.followerCount,
            posts: 0,
            hearts: res.data.userInfo.stats.heartCount,
            videos: res.data.userInfo.stats.videoCount,
          };
          setTikTokDetails(newProfile);
          let profiles = [...influencer.socialMediaProfile];
          profiles = profiles.filter((p) => p.application !== "TIKTOK");
          profiles.push(newProfile);
          setInfluencer({ ...influencer, socialMediaProfile: profiles });
          if (isEditing && insideEditModal) {
            setSocialMediaProfiles(profiles);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTiktokLoading(false));
  };

  return (
    <div>
      <div className="update-influencer-form-container">
        {!insideEditModal && (
          <div className="update-influencer-form-wrapper">
            <TextField
              fullWidth
              size="small"
              label="First Name*"
              onChange={(e) => handleChangeInput("firstName", e.target.value)}
              value={influencer?.firstName}
              variant="outlined"
              error={errorForm.firstName ? true : false}
              helperText={errorForm.firstName ? errorForm.firstName : ""}
            />
            <TextField
              fullWidth
              size="small"
              label="Last Name*"
              onChange={(e) => handleChangeInput("lastName", e.target.value)}
              value={influencer?.lastName}
              variant="outlined"
              error={errorForm.lastName ? true : false}
              helperText={errorForm.lastName ? errorForm.lastName : ""}
            />
            <Autocomplete
              options={industries}
              getOptionLabel={(option) => option.name}
              value={
                industries.find((i) => i.id == influencer?.mainIndustryId) ?? ""
              }
              onChange={(e, newValue) => {
                handleChangeInput("mainIndustryId", newValue?.id || null);
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Main Category"
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              options={ageCategories}
              getOptionLabel={(option) => option.generation}
              value={ageCategories.find(
                (i) => i.id == influencer?.ageCategoryId
              )}
              onChange={(e, newValue) => {
                handleChangeInput("ageCategoryId", newValue?.id || null);
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Age Category"
                  variant="outlined"
                />
              )}
            />
            <div className="update-influencer-form-currency-wrapper">
              <CurrencyDropdownMenu
                size={"small"}
                originalCurrency={influencer.currency}
                handleChangeCurrency={(currencyObj) =>
                  handleChangeInput("currency", currencyObj.value)
                }
                selectedCurrencies={getCurrencies()}
              />
              <TextField
                fullWidth
                size="small"
                label={`Bank Account*`}
                onChange={(e) =>
                  handleChangeInput("bankAccount", e.target.value)
                }
                value={influencer?.bankAccount}
                variant="outlined"
                error={errorForm.bankAccount ? true : false}
                helperText={errorForm.bankAccount ? errorForm.bankAccount : ""}
              />
            </div>
            {influencer.currency === "NZD" && (
              <TextField
                fullWidth
                size="small"
                label={`IRD Number*`}
                onChange={(e) => handleChangeInput("ird", e.target.value)}
                value={influencer?.ird}
                variant="outlined"
                error={errorForm.ird ? true : false}
                helperText={errorForm.ird ? errorForm.ird : ""}
              />
            )}
            {influencer.currency === "AUD" && (
              <TextField
                fullWidth
                size="small"
                label={`BSB - Bank State Branch *`}
                onChange={(e) =>
                  handleChangeInput("branchCode", e.target.value)
                }
                value={influencer?.branchCode}
                variant="outlined"
                error={errorForm.branchCode ? true : false}
                helperText={errorForm.branchCode ? errorForm.branchCode : ""}
              />
            )}
            {influencer.currency === "AUD" && (
              <TextField
                fullWidth
                size="small"
                label={`Address *`}
                onChange={(e) => handleChangeInput("address", e.target.value)}
                value={influencer?.address}
                variant="outlined"
                error={errorForm.address ? true : false}
                helperText={errorForm.address ? errorForm.address : ""}
              />
            )}
            {influencer.currency === "NZD" && (
              <div className="update-influencer-form-switch-container">
                <FormLabel
                  component="legend"
                  style={{
                    color: "#2d2d2d",
                  }}
                >
                  <Typography variant="caption">GST registered: </Typography>
                </FormLabel>
                <div style={{ borderBottom: "solid", borderColor: "#3f51b5" }}>
                  <Typography variant="caption">No</Typography>
                </div>
                <FormGroup>
                  <FormControlLabel
                    label={<Typography variant="caption">Yes</Typography>}
                    control={
                      <Switch
                        color="primary"
                        checked={influencer.gstRegistered === 1}
                        name="gstRegistered"
                        onChange={(e) => {
                          if (influencer.gstRegistered !== 1) {
                            handleChangeInput("gstRegistered", 1);
                          } else {
                            handleChangeInput("gstRegistered", 0);
                          }
                        }}
                        size="small"
                        className={"blueSwitch"}
                      />
                    }
                  />
                </FormGroup>
              </div>
            )}
            <div className="update-influencer-form-switch-container">
              <FormLabel
                component="legend"
                style={{
                  color: "#2d2d2d",
                }}
              >
                <Typography variant="caption">Bill as Company: </Typography>
              </FormLabel>
              <div style={{ borderBottom: "solid", borderColor: "#3f51b5" }}>
                <Typography variant="caption">No</Typography>
              </div>
              <FormGroup>
                <FormControlLabel
                  label={<Typography variant="caption">Yes</Typography>}
                  control={
                    <Switch
                      color="primary"
                      checked={influencer.bcOrIc === "bc"}
                      name="bcOrIc"
                      onChange={(e) => {
                        if (influencer.bcOrIc === "bc") {
                          handleChangeInput("bcOrIc", "ic");
                        } else {
                          handleChangeInput("bcOrIc", "bc");
                        }
                      }}
                      size="small"
                      className={"blueSwitch"}
                    />
                  }
                />
              </FormGroup>
            </div>
            {influencer.bcOrIc === "bc" && (
              <TextField
                fullWidth
                size="small"
                label="Comany Name*"
                onChange={(e) => handleChangeInput("bcName", e.target.value)}
                value={influencer?.bcName}
                variant="outlined"
                error={errorForm.bcName ? true : false}
                helperText={errorForm.bcName ? errorForm.bcName : ""}
              />
            )}
            {!isEmpty(influencer?.firstName) &&
              !isEmpty(influencer.lastName) && (
                <>
                  <TextField
                    fullWidth
                    size="small"
                    label={`Email Address *`}
                    onChange={(e) =>
                      handleChangeInput("emailAddress", e.target.value)
                    }
                    value={influencer?.emailAddress}
                    variant="outlined"
                    error={errorForm.emailAddress ? true : false}
                    helperText={errorForm.emailAddress ? errorForm.email : ""}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label={`Phone Number`}
                    onChange={(e) =>
                      handleChangeInput("phoneNumber", e.target.value)
                    }
                    value={influencer?.phoneNumber}
                    variant="outlined"
                  />
                </>
              )}
            {influencer?.bcOrIc === "ic" && influencer?.currency === "NZD" && (
              <React.Fragment>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => document.getElementById("file-input").click()}
                >
                  <span style={{ marginRight: "5px" }}>Upload IR330C</span>
                  <input
                    id="file-input"
                    style={{ display: "none" }}
                    type="file"
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      setFile(e.target.files[0]);
                    }}
                    multiple={true}
                  />
                </Button>
                {file && file.name && <Typography>{file.name}</Typography>}
              </React.Fragment>
            )}
          </div>
        )}
        {(insideEditModal ||
          (!isEmpty(influencer?.firstName) &&
            !isEmpty(influencer.lastName) &&
            !isEmpty(influencer.emailAddress))) && (
          <div
            className={`${
              insideEditModal ? "update-influencer-form-double-input" : ""
            }`}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginBottom: 20,
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  disabled={insideEditModal && !isEditing}
                  label="Find Instagram"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    try {
                      var filteredValue = e.target.value.replace("@", "");
                      setSearchTermInstagram(filteredValue);
                    } catch (e) {
                      setSearchTermInstagram(e.target.value);
                    }
                  }}
                  value={searchTermInstagram}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleInstagramSearch();
                    }
                  }}
                  placeholder="Type Id and press 'Enter'"
                  style={{ width: "90%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">@</InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {instagramLoading ? (
                    <CircularProgress />
                  ) : (
                    <IconButton
                      disabled={insideEditModal && !isEditing}
                      onClick={() => handleInstagramSearch()}
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
                </div>
              </div>
              {instagramDetails && (
                <InfluencerBasicCard
                  type="instagram"
                  influencer={instagramDetails}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginBottom: 20,
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  disabled={insideEditModal && !isEditing}
                  label="Find TikTok"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    try {
                      var filteredValue = e.target.value.replace("@", "");
                      setSearchTermTikTok(filteredValue);
                    } catch (e) {
                      setSearchTermTikTok(e.target.value);
                    }
                  }}
                  value={searchTermTikTok}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleTikTokSearch();
                    }
                  }}
                  placeholder="Type Id and press 'Enter'"
                  style={{ width: "90%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">@</InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {tiktokLoading ? (
                    <CircularProgress />
                  ) : (
                    <IconButton
                      disabled={insideEditModal && !isEditing}
                      onClick={() => handleTikTokSearch()}
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
                </div>
              </div>
              {tiktokDetails && (
                <InfluencerBasicCard type="tiktok" influencer={tiktokDetails} />
              )}
            </div>
          </div>
        )}
        {!insideEditModal && (
          <div className="update-influencer-form-actions">
            {isSaving ? (
              <CircularProgress />
            ) : (
              <>
                <Button className="default-button-grey" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="default-button"
                  onClick={handleSave}
                >
                  {"Save"}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdatePoiInfluencerForm;
