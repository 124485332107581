export const getDistance = async (locations) => {
  const directionsService = new window.google.maps.DirectionsService();
  debugger;
  let distance = 0;
  locations.push({
    locationFkNavigation: {
      address: "Level 1/3 Lorne Street, Auckland CBD, Auckland 1010",
    },
  });
  locations.unshift({
    locationFkNavigation: {
      address: "Level 1/3 Lorne Street, Auckland CBD, Auckland 1010",
    },
  });
  for (let i = 0; i < locations.length - 1; i++) {
    const request = {
      origin: locations[i].locationFkNavigation.address,
      destination: locations[i + 1].locationFkNavigation.address,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };
    try {
      await directionsService.route(request, function (response, status) {
        if (status == window.google.maps.DirectionsStatus.OK) {
          // travel =
          //   i < locations.length - 2
          //     ? {
          //         ...travel,
          //         go: (travel.go +=
          //           Number(response.routes[0].legs[0].distance.value) / 1000),
          //       }
          //     : {
          //         ...travel,
          //         return:
          //           Number(response.routes[0].legs[0].distance.value) / 1000,
          //       };
          distance += Number(response.routes[0].legs[0].distance.value) / 1000;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  // settravelObj(travel);
  // settravelDistance(distance);
  return distance;
};
