import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import OtherOutPuts from "./OtherOutputs";
import MediaPlanOutPuts from "./MediaPlanOutputs";
import DeliveredOutPuts from "./DeliveredOutputs";
import {
  blankOutputObject,
  backTransformRowObject,
} from "./outputsWrapperUtils";
import {
  CloseProjectDialog,
  ClosingUnarchivedProjectDialog,
} from "./CloseProjectDialogs";
import CloseProjectModal from "./CloseProjectModal/CloseProjectModal";
import { Button, CircularProgress } from "@material-ui/core";
import { v1 as uuidv1 } from "uuid";
import { convertDatetimeToMysqlFormat } from "../../../utils/TimeHelpers";
import { useHistory } from "react-router-dom";
import Snacky from "../../Shared/Snacky";
import { setSnackBarProps } from "../../../actions/snackbar";
import { setLoaderProps } from "../../../actions/loader";
import { getProjectNames } from "../../../actions/adminMenu";
import { updateLocationContact } from "../../../actions/schedules";
import {
  getAllProjectActiveItems,
  closeProjectAndSendAllInvoices,
} from "../../../actions/closeProject";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import LocationDetailsPopUp from "../../Project/Schedule/Location/LocationDetailsPopUp";
import Loader from "../../Loader";
import NoOutputsDialog from "./CloseProjectModal/NoOutputsDialog";

const OutputsWrapper = (props) => {
  let {
    setSnackBarProps,
    setLoaderProps,
    loader,
    snackbar,
    getProjectNames,
    getAllProjectActiveItems,
    activeItems,
    updateLocationContact,
    closeProjectAndSendAllInvoices,
    getProjects,
  } = props;
  const [openRequiredFieldsPopup, setOpenRequiredFieldsPopup] = useState(false);
  const [showActiveOutputsModal, setShowActiveOutputsModal] = useState(false);
  const [openActiveItemsModal, setOpenActiveItemsModal] = useState(false);
  const [openContactDetails, setOpenContactDetails] = useState(false);
  const [openLocationDetails, setOpenLocationDetails] = useState(false);
  const [location, setLocation] = useState();
  const [contact, setContact] = useState({ id: null });
  const [activeItem, setActiveItem] = useState({ id: null, category: null });
  const [showUnarchivedProjectsDialog, setShowUnarchivedProjectsDialog] =
    useState(false);
  const [allOutputs, setAllOutputs] = useState({
    id: 0,
    outputs: [],
    projectFk: 0,
    fetched: false,
  });
  const [mediaOutputs, setMediaOutputs] = useState({
    outputs: [],
    fetched: false,
  });
  const [deliveredOutputs, setDeliveredOutputs] = useState({
    id: 0,
    outputs: [],
    projectFk: 0,
    fetched: false,
  });
  const [editComplete, setEditComplete] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [deleteMediaOutput, setDeleteMediaOutput] = useState();
  const [editMode, setEditMode] = useState(false);
  const [mediaOutputsDifferenced, setMediaOutputsDifferenced] = useState(false);
  const [closeProject, setCloseProject] = useState(false);

  const [getOutputs, setGetOutputs] = useState(true);
  const [outputsLoaded, setOutputsLoaded] = useState(false);
  const [showNoOutputsDialog, setShowNoOutputsDialog] = useState(false);

  var history = useHistory();

  useEffect(() => {
    console.log(deliveredOutputs);
  }, [deliveredOutputs]);

  useEffect(() => {
    if (getOutputs) {
      setGetOutputs(false);
      Axios.get(`/OutputsV2/GetOutputs/${props.project.id}`)
        .then(({ data }) => {
          setMediaOutputs({
            outputs: data.filter(
              (d) => d.fromMediaCore === 1 && d.status !== 2
            ),
            fetched: true,
          });
          setDeliveredOutputs({
            outputs: data.filter((d) => d.status === 2),
            fetched: true,
          });
          setAllOutputs({
            outputs: data.filter(
              (d) => d.fromMediaCore === 0 && d.status !== 2
            ),
            fetched: true,
          });
          setOutputsLoaded(true);
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  }, [getOutputs]);

  useEffect(() => {
    if (
      mediaOutputs.fetched &&
      mediaOutputs.outputs.length === 0 &&
      allOutputs.fetched &&
      allOutputs.outputs.length === 0 &&
      deliveredOutputs.fetched &&
      deliveredOutputs.outputs.length > 0
    ) {
      setShowNoOutputsDialog(true);
    }
  }, [mediaOutputs, allOutputs]);

  useEffect(() => {
    getProjectNames();
    getAllProjectActiveItems(props.project.id);
  }, []);

  const handleCloseRequiredFieldsPopup = () => {
    setOpenRequiredFieldsPopup(false);
  };

  const handleSetEditFromChild = (value) => {
    setEditMode(value);
  };

  const preArchiveCheck = () => {
    if (props.project.isArchived == 2) {
      setShowUnarchivedProjectsDialog(true);
    } else {
      archiveProject();
    }
  };

  const handleCloseProjectNoOutputs = async () => {
    handleCloseNoOutputsDialog();
    setSnackBarProps("info", "Processing", true);
    await archiveProject(false);
    await closeProjectAndSendAllInvoices(activeItems, {
      name: `Magnesium`,
      email: "dev@magnesium.nz",
    });
    setSnackBarProps("success", "Project Archived", true);

    history.push("/");
  };

  const archiveProject = (redirect = true) => {
    Axios.put(`Projects/ArchiveProject/${props.project.id}`)
      .then((res) => {
        console.log(res.data);
        setCloseProject(false);
        setSnackBarProps("success", "Project Archived", true);
        if (redirect) {
          //history.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps("warning", "Archive Failed", true);
      });
  };

  useEffect(() => {
    if (closeProject) {
      if (activeItems.length > 0) {
        setOpenActiveItemsModal(true);
      } else {
        if (
          mediaOutputs.outputs.length == 0 &&
          allOutputs.outputs.length == 0
        ) {
          preArchiveCheck();
        } else {
          setShowActiveOutputsModal(true);
        }
      }
    }
  }, [closeProject]);

  useEffect(() => {
    if (!mediaOutputsDifferenced) {
      setMediaOutputsDifferenced(true);
      Axios.post(`Outputs/DifferenceMediaOutputs`)
        .then((res) => {})
        .catch(function (error) {
          console.log(error);
        });
    }
    return () => {
      setMediaOutputsDifferenced(false);
    };
  }, [mediaOutputs.fetched]);

  const deleteOutput = (rowObj, index) => {
    console.log("rowObj");
    console.log(rowObj);
    setAllOutputs({
      outputs: allOutputs.outputs.filter(
        (d) => d.fromMediaCore === 0 && d.status !== 2 && d.id !== rowObj.id
      ),
      fetched: true,
    });
    setEditComplete(false);
  };

  useEffect(() => {
    if (deleteMediaOutput) {
      var outputsToSave = [];
      for (var i = 0; i < mediaOutputs.outputs; i++) {
        if (
          mediaOutputs.outputs[i].rowId !== null &&
          mediaOutputs.outputs[i].rowId !== undefined
        ) {
          outputsToSave.push(mediaOutputs.outputs[i]);
        }
      }
      Axios.put("OutPutsMedia/PutOutputsMedia/1", {
        id: 1,
        outputs: JSON.stringify(mediaOutputs.outputs),
      })
        .then((res) => {
          console.log(res.data);
          setDeleteMediaOutput(false);
        })
        .catch(function (error) {
          console.log(error);
        });
      setEditComplete(false);
    }
  }, [deleteMediaOutput]);

  const handleInputChange = (event, rowObj) => {
    setAllOutputs({
      ...allOutputs,
      outputs: allOutputs.outputs.map((row) =>
        row === rowObj
          ? { ...row, [event.target.name]: event.target.value }
          : row
      ),
    });
    setShowSaveButton(true);
    setEditComplete(true);
  };

  const handleSaveKeynumber = (keynumberObj, rowObj) => {
    setAllOutputs({
      ...allOutputs,
      outputs: allOutputs.outputs.map((row) =>
        row === rowObj ? { ...row, keyNumberFkNavigation: keynumberObj } : row
      ),
    });
    setShowSaveButton(true);
    setEditComplete(true);
  };

  const resortTablesOnDelivery = (rowObj, link, parentTable, apiResult) => {
    setGetOutputs(true);
  };

  const handleRowUpdate = (oldRowObj, newRowObj) => {
    allOutputs.outputs.push(newRowObj);
    deleteOutput(oldRowObj, "index");
  };

  const handleDateChange = (name, dateValue, rowObj) => {
    dateValue = convertDatetimeToMysqlFormat(dateValue);
    setAllOutputs({
      ...allOutputs,
      outputs: allOutputs.outputs.map((row) =>
        row === rowObj ? { ...row, [name]: dateValue } : row
      ),
    });
    setShowSaveButton(true);
  };

  const handleDropDownChange = (name, value, rowObj) => {
    setAllOutputs({
      ...allOutputs,
      outputs: allOutputs.outputs.map((row) =>
        row === rowObj ? { ...row, [name]: value } : row
      ),
    });
    setShowSaveButton(true);
    setEditComplete(true);
  };

  const handlePublisherReset = (platformValue, publisherValue, rowObj) => {
    var rowObject = rowObj;
    if (rowObject !== undefined) {
      rowObject.platform = platformValue;
      rowObject.publisher = publisherValue;
      setAllOutputs({
        ...allOutputs,
        outputs: allOutputs.outputs.map((row) =>
          row === rowObj ? rowObject : row
        ),
      });
      setShowSaveButton(true);
      setEditComplete(true);
    }
  };

  useEffect(() => {
    if (deliveredOutputs.fetched && deliveredOutputs.outputs.length) {
      Axios.put(
        "OutputsDelivered/SaveChangesToOutputsDelivered/" + props.project.id,
        {
          id: deliveredOutputs.id,
          outputs: JSON.stringify(deliveredOutputs.outputs),
          projectsFk: props.project.id,
        }
      );
    }
  }, [deliveredOutputs.outputs]);

  const handleSaveChanges = (outputs) => {
    setShowSaveButton(false);
    console.log("Saving outputs...");
    console.log(outputs);
    if (editComplete) {
      if (!checkMissingFields()) {
        outputs.forEach((o) => {
          o.duration =
            typeof o.duration === "string"
              ? o.duration.trim() !== ""
                ? parseInt(o.duration)
                : 0
              : o.duration;
        });
        Axios.patch(`OutputsV2/SaveManualOutputs`, {
          outputs: outputs,
        })
          .then((res) => {
            setEditComplete(false);
            setGetOutputs(true);
            console.log("OutputsWrapper save calling getProjects");
            getProjects();
          })
          .catch((e) => {
            setEditComplete(false);
            setGetOutputs(true);
          });
      } else {
        setOpenRequiredFieldsPopup(true);
      }
    }
  };

  const handleSaveEditManualOutput = (output) => {
    setOutputsLoaded(false);
    Axios.patch(`OutputsV2/SaveManualOutputs`, {
      outputs: [output],
    })
      .then((res) => {
        setGetOutputs(true);
        console.log("OutputsWrapper SaveManualOut calling getProjects");
        getProjects();
      })
      .catch((e) => {
        setGetOutputs(true);
      });
  };

  const checkMissingFields = () => {
    var outputs = allOutputs.outputs;
    var errArr = [];
    for (let i = 0; i < outputs.length; i++) {
      if (outputs[i].CreativeName && outputs[i].CreativeName == "") {
        errArr.push("Name");
      } else if (outputs[i].creativeName && outputs[i].creativeName == "") {
        errArr.push("Name");
      } else if (outputs[i].duration == null) {
        errArr.push("Duration");
      } else if (outputs[i].platform == "") {
        errArr.push("Platform");
      } else if (outputs[i].targetAudience == "") {
        errArr.push("Target Audience");
      } else if (
        outputs[i].creativeDeadline &&
        outputs[i].creativeDeadline == ""
      ) {
        errArr.push("Creative Deadline");
      } else if (outputs[i].creativeDeadline === null) {
        errArr.push("Creative Deadline");
      }
    }
    if (errArr.length > 0) {
      return true;
    } else if (errArr.length == 0) {
      return false;
    }
  };

  const handleCopyEmpty = () => {
    var newRowId = uuidv1();
    var blankOP = blankOutputObject(props.project, newRowId);
    setAllOutputs({
      ...allOutputs,
      outputs: [...allOutputs.outputs, blankOP],
    });
    setShowSaveButton(true);
  };

  const handleEdit = (row) => {
    console.log(row);
    var backTransformed = backTransformRowObject(row, props.project);
    setDeliveredOutputs({
      ...deliveredOutputs,
      outputs: [...deliveredOutputs.outputs, backTransformed],
    });
    setAllOutputs({
      ...allOutputs,
      outputs: allOutputs.outputs.filter((o) => o.RowID != row.rowId),
    });
    setEditComplete(true);
  };

  const removeOutput = (output) => {
    const index = allOutputs.outputs.findIndex((o) => o === output);
    const newArr = [...allOutputs.outputs];
    newArr.splice(index, 1);
    setAllOutputs({
      ...allOutputs,
      outputs: newArr,
    });
  };

  const handleCloseNoOutputsDialog = () => {
    setShowNoOutputsDialog(false);
  };

  return (
    <div>
      {loader.open && <Loader />}
      {showActiveOutputsModal && (
        <CloseProjectDialog
          showActiveOutputsModal={showActiveOutputsModal}
          setShowActiveOutputsModal={setShowActiveOutputsModal}
          setCloseProject={setCloseProject}
          preArchiveCheck={preArchiveCheck}
          setLoaderProps={setLoaderProps}
          project={props.project}
        />
      )}
      {openActiveItemsModal && (
        <CloseProjectModal
          openActiveItemsModal={openActiveItemsModal}
          setCloseProject={setCloseProject}
          setOpenActiveItemsModal={setOpenActiveItemsModal}
          setContact={setContact}
          setActiveItem={setActiveItem}
          setOpenContactDetails={setOpenContactDetails}
          setOpenLocationDetails={setOpenLocationDetails}
          setLocation={setLocation}
          preArchiveCheck={preArchiveCheck}
        />
      )}
      {openContactDetails && (
        <ContractorPopUp
          handleModal={() => {
            setOpenContactDetails(false);
          }}
          modalState={openContactDetails}
          personId={contact.id}
          scheduleItemId={activeItem.id}
          reloadPersonList={() => console.log("No reload")}
          reloadScheduleItemsList={() => console.log("No reload")}
          type="EditScheduleItem"
          providerType={activeItem.category}
        />
      )}
      {openLocationDetails && (
        <LocationDetailsPopUp
          open={openLocationDetails}
          location={location}
          updateLocationContact={updateLocationContact}
          parent={"OutputsWrapper"}
          initialTab={2}
          handleModal={() => {
            setLocation(null);
            setOpenLocationDetails(false);
          }}
          setLoadingImagesFalse={() => console.log("this doesn't do anything")}
          setLoadingImagesTrue={() =>
            console.log("this doesn't do anything either")
          }
        />
      )}
      {showNoOutputsDialog && (
        <NoOutputsDialog
          open={showNoOutputsDialog}
          handleClose={handleCloseNoOutputsDialog}
          handleSubmit={handleCloseProjectNoOutputs}
        />
      )}
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      {showUnarchivedProjectsDialog && (
        <ClosingUnarchivedProjectDialog
          showUnarchivedProjectsDialog={showUnarchivedProjectsDialog}
          setShowUnarchivedProjectsDialog={setShowUnarchivedProjectsDialog}
          preArchiveCheck={preArchiveCheck}
          setCloseProject={setCloseProject}
          archiveProject={archiveProject}
          project={props.project}
        />
      )}

      <OtherOutPuts
        handleClosePopup={handleCloseRequiredFieldsPopup}
        open={openRequiredFieldsPopup}
        editMode={editMode}
        handleEditMode={handleSetEditFromChild}
        outputs={allOutputs.outputs}
        handleDelete={deleteOutput}
        copyEmpty={handleCopyEmpty}
        handleInputChange={handleInputChange}
        handleSaveKeynumber={handleSaveKeynumber}
        handleDateChange={handleDateChange}
        showSaveButton={showSaveButton}
        handleEdit={handleEdit}
        handleSaveChanges={handleSaveChanges}
        handleSaveEditManualOutput={handleSaveEditManualOutput}
        outputsLoaded={outputsLoaded}
        setGetOutputs={setGetOutputs}
        handleDropDownChange={handleDropDownChange}
        handleRowUpdate={handleRowUpdate}
        handlePublisherReset={handlePublisherReset}
        handleInputChangeDelivery={resortTablesOnDelivery}
        projectDetails={props.project}
        component={"OtherOutputs"}
        removeOutput={removeOutput}
        getOutputs={getOutputs}
      />

      <DeliveredOutPuts
        outputs={deliveredOutputs.outputs}
        deliveredOutputsFetched={deliveredOutputs.fetched}
      />
      <div style={{ position: "fixed", right: 10, bottom: 10 }}>
        <Button
          style={{
            backgroundColor: "#f44336",
            color: "white",
            position: "sticky",
            visibility: props.project.isArchived != 1 ? "visible" : "hidden",
          }}
          disabled={closeProject}
          onClick={() => setCloseProject(true)}
        >
          Close Project
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  projectNames: state.adminMenu.projectNames,
  activeItems: state.closeProject.activeItemsForProject,
  loader: state.loader,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
  getProjectNames,
  getAllProjectActiveItems,
  updateLocationContact,
  setLoaderProps,
  closeProjectAndSendAllInvoices,
})(OutputsWrapper);
