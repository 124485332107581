import React, { useState } from "react";
import "./campaignInfluencerNetwork.scss";
import SearchInfluencersDialog from "../../Project/Schedule/PrePost/InfluencerNetwork/SearchInfluencersDialog";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import { Skeleton } from "@material-ui/lab";
import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import { useUserContext } from "../../../context/user/UserContext";
import { useAuth } from "../../../context/auth/AuthContext";
import { v1 as uuidv1 } from "uuid";
import { format } from "date-fns";
import CampaignInfluencersCard from "./CampaignInfluencersCard";
import AddToCampaignButton from "./AddToCampaignButton";
const CampaignInfluencerNetwork = ({
  scheduleItems,
  setScheduleItems,
  project,
  isLoadingScheduleItems,
  setSnackBarProps,
  budgetItems,
  fetchProjectDetails,
  fetchAllBudget,
}) => {
  const { user } = useAuth();
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [categoryToBeAdded, setCategoryToBeAdded] = useState(null);
  const [openUpdateInfluencerDialog, setOpenUpdateInfluencerDialog] =
    useState(false);
  const [openSearchInfluencersDialog, setOpenSearchInfluencersDialog] =
    useState(false);

  const deleteScheduleItem = (item) => {
    setScheduleItems(scheduleItems.filter((sI) => sI.id !== item.id));
    axios
      .delete(`ScheduleItems/DeleteScheduleItem/${item.id}`)
      .then((res) => {
        fetchAllBudget();
        setSnackBarProps({
          severity: "success",
          text: "Record removed from the campaign",
          open: true,
        });

        //props.getBudget(props.projectId);
      })
      .catch((error) => {
        console.log(error);
        setSnackBarProps({
          severity: "warning",
          text: "Failed to delete item",
          open: true,
        });
      });
  };

  const updateScheduleItem = (scheduleItem) => {
    axios
      .patch(
        `ScheduleItems/PatchScheduleItem/${scheduleItem.id}`,
        scheduleItem,
        {
          params: {
            user: user?.email,
          },
        }
      )
      .then(({ data }) => {
        const _scheduleItemBeforeChange = scheduleItems.find(
          (si) => si.id === scheduleItem.id
        );
        if (
          _scheduleItemBeforeChange?.allocatedRates !==
            scheduleItem?.allocatedRates ||
          _scheduleItemBeforeChange?.category !== scheduleItem?.category
        ) {
          fetchAllBudget();
        }
        handleRefreshScheduleItemList(scheduleItem);
        setSnackBarProps({
          severity: "success",
          text: "Your changes have been saved!",
          open: true,
        });
        //props.getBudget(props.projectId);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          severity: "warning",
          text: "Hm, it looks like something went wrong.",
          open: true,
        });
      });
  };

  const handleRefreshScheduleItemList = (scheduleItem) => {
    setScheduleItems(
      scheduleItems.map((si) => (si.id === scheduleItem.id ? scheduleItem : si))
    );
  };

  const handleSaveScheduleItem = async (scheduleItem) => {
    await updateScheduleItem(scheduleItem);
  };

  const handleSaveInfluencer = (influencer) => {
    if (influencer) {
      updatePersonInScheduleItems(influencer);
      setSnackBarProps({
        open: true,
        severity: "success",
        text: "Your changes have been saved!",
      });
    } else {
      setSnackBarProps({
        open: true,
        severity: "warning",
        text: "Hm, it looks like something went wrong.",
      });
    }
  };

  const updatePersonInScheduleItems = (person) => {
    if (scheduleItems?.length > 0) {
      setScheduleItems(
        scheduleItems.map((i) =>
          i.personFk === person?.id
            ? { ...i, personFkNavigation: { ...person } }
            : i
        )
      );
    }
  };

  const handleScheduleItemEdit = (value, name, scheduleItem) => {
    setScheduleItems(
      scheduleItems.map((s) =>
        s.id === scheduleItem.id
          ? {
              ...s,
              [name]: value,
            }
          : s
      )
    );
    if (
      name === "startTime" ||
      name === "endTime" ||
      name === "isAucklandStandardContract"
    ) {
      scheduleItem[name] = value;
      axios
        .patch(
          `ScheduleItems/PatchScheduleItem/${scheduleItem.id}`,
          scheduleItem,
          {
            params: {
              user: user?.email,
            },
          }
        )
        .then((res) => console.log(res.data))
        .catch((err) => console.log(err));
    }
    //props.getBudget(props.projectId);
  };

  const handleEditInfluencer = (influencer) => {
    setSelectedInfluencer(influencer);
    setOpenUpdateInfluencerDialog(true);
  };

  const handleCloseUpdateInfluencerDialog = () => {
    setSelectedInfluencer(null);
    setOpenUpdateInfluencerDialog(false);
  };

  const handleAddToCampaign = (categoryType) => {
    setCategoryToBeAdded(categoryType);
    setOpenSearchInfluencersDialog(true);
  };

  const handleCloseSearchInfluencerDialog = () => {
    setOpenSearchInfluencersDialog(false);
  };

  const addInfluencerToProject = (influencer) => {
    handleConfirmAddInfluencerToProject(influencer);
  };

  const handleCloseInfluencerDetailsForScheduleItem = () => {
    setSelectedInfluencer(null);
  };

  const handleConfirmAddInfluencerToProject = (influencer) => {
    const categoryToAllocate = budgetItems.find((b) =>
      b.category.toLowerCase().includes("influencer spend") 
    || b.category.toLowerCase().includes("influencer/content creator")
    );
    if (!categoryToAllocate) {
      alert("No Budget for Influencer Spend");
      return;
    }
    axios
      .post(`/ScheduleItems/PostTalentScheduleItemFromPrePost/${project.id}`, {
        category: categoryToAllocate?.projectCategory,
        budgetId: categoryToAllocate.budgetId,
        startTime: format(new Date(), "yyyy-MM-dd"),
        usageInMonths: categoryToBeAdded === "Influencer" ? 3 : null,
        exclusivityInMonths: categoryToBeAdded === "Influencer" ? 3 : null,
        invoiceStatus: null,
        scheduleSentStatus: null,
        providerName: influencer?.firstName + " " + influencer?.lastName,
        isStaff: 2,
        isClient: 0,
        prePostNotes: "-",
        companyFk: project?.companyFk ? project?.companyFk : null,
        personFk: influencer.id,
        contractStatus: "Send",
        singleUseParam: uuidv1(),
        talentContractType: "BuyOut",
        talentContractStatus: "Complete",
        roleFk: 175,
        createdBy: user?.email,
        autoChase: 1,
        type: categoryToBeAdded === "Influencer" ? "organic" : "ugc",
        scheduleItemType: categoryToBeAdded,
      })
      .then(({ data }) => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "The influencer has been added.",
        });
        handleCloseInfluencerDetailsForScheduleItem();
        handleCloseSearchInfluencerDialog();
        updateScheduleList(data);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Something went wrong.",
        });
      });
  };

  const updateScheduleList = (newItm) => {
    setScheduleItems([...scheduleItems, newItm]);
    //props.getBudget(props.projectId);
  };

  return (
    <div className="campaign-influencer-network-wrapper">
      {openSearchInfluencersDialog && categoryToBeAdded && (
        <SearchInfluencersDialog
          open={openSearchInfluencersDialog}
          handleClose={handleCloseSearchInfluencerDialog}
          addInfluencerToProject={addInfluencerToProject}
          cateogries={[categoryToBeAdded]}
        />
      )}

      {openUpdateInfluencerDialog && (
        <ContractorPopUp
          handleModal={handleCloseUpdateInfluencerDialog}
          modalState={openUpdateInfluencerDialog}
          personId={selectedInfluencer?.id}
          scheduleItemId={null}
          reloadPersonList={() => console.log("No reload")}
          reloadScheduleItemsList={() => fetchProjectDetails()}
          type="Influencer"
          providerType={selectedInfluencer.personCategory}
          handleEditPerson={handleSaveInfluencer}
          selectedContact={selectedInfluencer}
          rowUpdated={() => {}}
        />
      )}
      <div className="project-schedule-items-table">
        {isLoadingScheduleItems && (
          <>
            <Skeleton height={100} variant="rect" />
          </>
        )}

        {scheduleItems?.length > 0 ? (
          <CampaignInfluencersCard
            scheduleItems={scheduleItems}
            handleEditInfluencer={handleEditInfluencer}
            handleScheduleItemEdit={handleScheduleItemEdit}
            budgetItems={budgetItems}
            handleSaveScheduleItem={handleSaveScheduleItem}
            setSnackBarProps={setSnackBarProps}
            deleteScheduleItem={deleteScheduleItem}
            handleAddToCampaign={handleAddToCampaign}
            project={project}
            handleRefreshScheduleItemList={handleRefreshScheduleItemList}
          />
        ) : (
          <div className="client-campaign-flex-container-center">
            <AddToCampaignButton handleAddToCampaign={handleAddToCampaign} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignInfluencerNetwork;
