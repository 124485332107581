import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import axios from "axios";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

const filter = createFilterOptions();
const RoleAutocomplete = ({
  size = "small",
  variant = "outlined",
  category = "",
  role,
  handleSelectRole,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRoles = async (query) => {
    if (category) {
      try {
        setLoading(true);
        const response = await axios.get(`roles/GetRoles`, {
          params: { search: query, category: category },
        });
        setOptions(response.data || [])
      } catch (error) {
        console.error("Error fetching roles:", error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }
  };

  // Debounced version of fetchRoles to avoid multiple API calls
  const debouncedFetchRoles = useMemo(
    () => debounce((query) => fetchRoles(query), 300),
    [category]
  );

  useEffect(() => {
    debouncedFetchRoles(inputValue, category);
  }, [inputValue, category, debouncedFetchRoles]);

  // Function to handle role creation if no option is available
  const handleRoleCreation = async (roleName) => {
    try {
      const response = await axios.post("roles/PostRole", {
        fieldRole: roleName,
        category: category,
      });
      return response.data;
    } catch (error) {
      console.error("Error creating role:", error);
    }
  };
  return (
    <Autocomplete
      freeSolo
      value={role || null}
      options={options}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.fieldRole
      }
      loading={loading}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const createOption = [
          {
            inputValue: params.inputValue,
            fieldRole: `Create "${params.inputValue}"`,
          },
        ];
        let newOptions = [];
        if (params.inputValue !== "") {
          newOptions = createOption.concat(filtered);
        } else {
          newOptions = filtered;
        }
        return newOptions;
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={async (event, newValue) => {
        if (newValue && newValue.inputValue) {
          const createdRole = await handleRoleCreation(newValue.inputValue);
          setOptions([...options, createdRole]);
          handleSelectRole(createdRole);
        } else {
          handleSelectRole(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Role"
          size={size}
          variant={variant}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default RoleAutocomplete;
