import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  List,
} from "@material-ui/core";
import InfluencerAdminTemplate from "./Influencer/InfluencerAdminTemplate";
import "../Admin/Admin.css";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const LegalTemplates = () => {
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState("");

  const templates = [
    {
      name: "Master Influencer Agreement",
      docFileId: "1cvtWYiFkGnt1aqP6yO-Zm3bN_oH7j86qVZCmt6OSaD4",
    },
    {
      name: "Statement of Work",
      docFileId: "10hG8MVmP-s8Yx7O4zy0t70oZk7U_8zqJB2QLZbR1O8Y",
    },
    {
      name: "Child - Contractor SoW",
      docFileId: "1_8ubA0ZAhZGI6_sOGWPf19KxAFR2RAzs3Uyck0GcVTM",
    },
    {
      name: "Child - PrePost BuyOut Talent Sow",
      docFileId: "1dD6v-Hf723zeNA3Yxf3TLhuD5r8_1qRSFCYdJyxXr1g",
    },
    {
      name: "Child - Talent Restricted Contract (With Location) SoW",
      docFileId: "1oKSjhJ96jY0ieVwj5gJo9QBuLxFL_LJbbvprbv2tKDE",
    },
    {
      name: "Contractor - SoW",
      docFileId: "1UJDfYkxxIgA0hWolQd5KrBIYMVXU2dShGOCulfTtqMk",
    },
    {
      name: "Contractor - PrePost SoW",
      docFileId: "1MiIa0gLqVm0bgno6_6u92GmMTYSKzgnF2_zEB1iCWac",
    },
    {
      name: "Location - SoW",
      docFileId: "1EMxRNalI5fe3YqHqhW9C8J4k_jVNlskUTzVexTdRB1I",
    },
    {
      name: "Talent - PrePost BuyOut SoW",
      docFileId: "11NAR_WmA6Cmane-wmAnhNmHzcsH-nAhSrBD4kJ_lqrw",
    },
    {
      name: "Talent - PrePost Agent Restricted SoW",
      docFileId: "113bWAXlJrLdLqlfIiOm7zKVHd_GYSbpr5p-FNR3-yv_c",
    },
    {
      name: "Talent - PrePost (No Agent) Restricted SoW",
      docFileId: "14LsamdrNBPKREOLP9oK5j6GRfJPdb83mHuEG9D-VTCw",
    },
    {
      name: "Talent - Agent Restricted GST SoW",
      docFileId: "17RxL5DT1yZMkFl-w-BuPBj3nirpaNBik9xSPAc59JyU",
    },
    {
      name: "Talent - No Agent Restricted SoW",
      docFileId: "14LsamdrNBPKREOLP9oK5j6GRfJPdb83mHuEG9D-VTCw",
    },
  ];

  return (
    <main className={classes.content}>
      <Grid container spacing={2}>
        {/* Sidebar */}
        <Grid item xs={3}>
          <div className="main-content">
            <Typography variant="h5">Legal Templates</Typography>
            <Paper className="contract-releases-list">
              <List component="nav">
                {templates.map((template) => (
                  <ListItem
                    key={template.name}
                    button
                    className={selectedMenu === template.name ? "selected-menu" : ""}
                    onClick={() => setSelectedMenu(template.name)}
                  >
                    <ListItemText primary={template.name} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </div>
        </Grid>

        {/* Content Area */}
        <Grid item xs={9}>
          {templates.map((template) => (
            selectedMenu === template.name && (
              <InfluencerAdminTemplate
                key={template.name}
                title={template.name}
                docFileId={template.docFileId}
              />
            )
          ))}
        </Grid>
      </Grid>
    </main>
  );
};

export default LegalTemplates;
