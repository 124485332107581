import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import ProcessingSpinnerDialog from '../../Shared/Spinner/ProcessingSpinnerDialog';

const GenerateOldCampaignBriefDialog = ({
  open,
  handleClose,
  currentProject,
  handleSuccessCallback = (data) => { }
}) => {

  const [previousCampaignBriefs, setPreviousCampaignBriefs] = useState([]);
  const [selectedBrief, setSelectedBrief] = useState(null);
  const [generatingBrief, setGeneratingBrief] = useState(false);

  const handleGenerateBrief = () => {
    setGeneratingBrief(true);
    axios
      .post(`Campaign/GeneratePreviousBrief/${selectedBrief?.projectId}/${currentProject?.id}`)
      .then(({ data }) => {
        handleSuccessCallback(data)
      })
      .catch((err) => {
        setGeneratingBrief(false);
      });
  };

  const getPreviousBriefs = () => {
    axios.get(`Campaign/GetPreviousCampaigns/${currentProject?.companyFk}`)
      .then(res => {
        setPreviousCampaignBriefs(res.data);
        debugger;
      }).catch(e => {
        console.log(e)
      })
  }

  useEffect(() => {
    getPreviousBriefs();
  }, [])

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason != "backdropClick" && reason != "escapeKeyDown") {
          handleClose();
        }
      }}
      maxWidth="md"
      className="default-dialog"
    >
      {generatingBrief && (
        <ProcessingSpinnerDialog
          loading={generatingBrief}
        />
      )}
      <DialogTitle style={{ textAlign: "center" }}>
        Generate previous campaign brief
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          Choose a brief to duplicate.
        </DialogContentText>
        <div className="default-text-field-container">
          <Autocomplete
            options={previousCampaignBriefs}
            getOptionLabel={(option) => option?.campaignName}
            value={selectedBrief}
            onChange={(event, newValue) => {
              setSelectedBrief(newValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="roboto-regular"
                size="small"
                label="Brief to duplicate"
                variant="filled"
              />
            )}
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          className={generatingBrief ? "default-disabled-button" : "default-button-grey"}
          disabled={generatingBrief}
        >
          Close
        </Button>
        <Button
          onClick={() => handleGenerateBrief()}
          className={(selectedBrief == null || generatingBrief) ? "default-disabled-button" : "default-button"}
          disabled={selectedBrief == null || generatingBrief}
        >
          Generate selected brief
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GenerateOldCampaignBriefDialog;