import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../custom.scss";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../../actions/snackbar";
import {
  editScheduleItem,
  postScheduleItem,
  getScheduleItems,
  deleteScheduleItem,
} from "../../../actions/scheduleItems";
import { getLowerCasePeople } from "../../../actions/people";
import { updateScheduleProgress } from "../../../actions/schedules";
import PropTypes from "prop-types";
import BudgetItemsTable from "./BudgetItemsTable";
import RunSheet from "./RunSheet";
import ScheduleTab from "./ScheduleTab";
import ScheduleItemTable from "./ScheduleItemTable/ScheduleItemTable";
import NewScheduleItem from "./NewScheduleItem";
import ClientTable from "./ScheduleItemClientTable/ClientTable";
import ScheduleItemStaffTable from "./ScheduleItemStaffTable/ScheduleItemStaffTable";
import Loader from "../../Loader";

import Skeleton from "@material-ui/lab/Skeleton";
import { CheckCircle, Brightness1 } from "@material-ui/icons";
import { Card, Tabs, Tab, AppBar, Typography, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Snacky from "../../Shared/Snacky";
import { useUserContext } from "../../../context/user/UserContext";
import { useAuth } from "../../../context/auth/AuthContext";
import AddNewScheduleItemForm from "./ScheduleItem/AddNewScheduleItemForm";
import moment from "moment";
import { isEmpty } from "../../../utils/GeneralUtils";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box
          style={{
            padding: "0px",
            background: "white",
            height: "calc(100vh - 241px)",
            overflowY: "auto",
          }}
          p={3}
        >
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
    color: "#000000",
    justifyContent: "center",
    height: "48px",
  },
  scroller: {
    flexGrow: "0",
    overflow: "hidden",
  },
}));

const StyledTab = withStyles((theme) => ({
  wrapper: {
    flexDirection: "row-reverse",
    padding: "0px 0px 20px 0px",
  },
}))(Tab);

const StyledTabLong = withStyles((theme) => ({
  root: {
    minWidth: "150px",
  },
  wrapper: {
    flexDirection: "row-reverse",
    height: "36.8px",
    padding: "0px 0px 20px 0px",
  },
}))(Tab);

const ScheduleWrapper = (props) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [allCompanies, setCompanies] = useState([]);
  const [allPeople, setPeople] = useState([]);
  const [companiesFetched, setCompaniesFetched] = useState(false);
  const [peopleFetched, setPeopleFetched] = useState(false);
  const [epContractor, setEPContractor] = useState(null);
  const [epTalent, setEPTalent] = useState(null);
  const [scheduleItem, setScheduleItem] = useState({
    startTime: props.schedule?.startTime || new Date(),
    endTime: props.schedule?.endTime || new Date(),
  });
  const [isSavingScheduleItem, setIsSavingScheduleItem] = useState(false);

  const [selectedCompanyIdForInternal, setSelectedCompanyIdForInternal] = useState(props.project?.companyFk);
  const [isInternal, setIsInternal] = useState(false);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    if (isInternal) {
      getSalesClients();
    }
  }, [isInternal]);

  useEffect(() => {
    if (scheduleItem?.scheduleItemType === "Client") {
      checkIfInternal();
    }
  }, [scheduleItem?.scheduleItemType]);

  const checkIfInternal = () => {
    axios.get(`Companies/CheckIfInternal/${props.project?.companyFk}`)
      .then(res => {
        setIsInternal(res.data);
      }).catch(e => {
        console.log(e)
      })
  }

  const getSalesClients = () => {
    axios.get(`Companies/GetSalesClientsShortForm`)
      .then(res => {
        setClientList(res.data)
      }).catch(e => {
        console.log(e)
      })
  }

  var history = useHistory();
  var location = useLocation();
  const [tabValue, setTabValue] = useState(0);
  var tabval = location.pathname.split("/")[6];
  useEffect(() => {
    const selectedTab = parseInt(location.pathname.split("/")[6]);
    if (selectedTab === 1) {
      setScheduleItem({ ...scheduleItem, scheduleItemType: "Staff", personFk: null, personFkNavigation: null });
    } else if (selectedTab === 2) {
      setScheduleItem({ ...scheduleItem, scheduleItemType: "Client", personFk: null, personFkNavigation: null });
    } else if (selectedTab === 3) {
      setScheduleItem({ ...scheduleItem, scheduleItemType: "Contractor", personFk: null, personFkNavigation: null });
    } else if (selectedTab === 4) {
      setScheduleItem({ ...scheduleItem, scheduleItemType: "Talent", personFk: null, personFkNavigation: null });
    }
    setTabValue(selectedTab);
    if (selectedTab >= 1 && selectedTab <= 4) {
      props.getLowerCasePeople(returnCategory(selectedTab));
      props.getScheduleItems(parseInt(location.pathname.split("/")[5]));
    }
  }, [tabval]);

  const returnCategory = (tabNumber) => {
    switch (tabNumber) {
      case 1:
        return "Staff";
      case 2:
        return "Client";
      case 3:
        return "Contractor";
      case 4:
        return "Talent";
      default:
        return "";
    }
  };

  const tabChange = (event, newValue) => {
    axios
      .get(`Schedules/GetScheduleHoursAndExpressPayment/${props.scheduleId}`)
      .then((res) => {
        var r = res.data;
        setEPContractor(r.epContractor);
        setEPTalent(r.epTalent);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        history.push(
          `/project/home/${props.project.id}/3/${props.schedule.id}/${newValue}`
        );
      });
  };

  useEffect(() => {
    if (!peopleFetched) {
      axios
        .get(`People/GetPeople`)
        .then((people) => {
          setPeople(people.data);
          setPeopleFetched(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [peopleFetched]);

  useEffect(() => {
    if (!companiesFetched) {
      axios
        .get(`/Companies/GetCompanies`)
        .then((companies) => {
          setCompanies(companies.data);
          setCompaniesFetched(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [companiesFetched]);

  const reloadPersonList = () => {
    setPeopleFetched(false);
    getSalesClients();
  };

  const checkRequiredFieldsForScheduleItem = () => {
    const {
      scheduleItemType,
      personFk,
      allocatedRates,
      category,
      budgetId,
      roleFk,
    } = scheduleItem;

    const isContractorOrTalent = (type) =>
      type === "Contractor" || type === "Talent";

    const isStaff = (type) => type === "Staff";

    const isMissingPersonFk = () => !personFk;

    const isMissingContractorOrTalentFields = () =>
      isContractorOrTalent(scheduleItemType) &&
      (allocatedRates === null ||
        isEmpty(category) ||
        (category !== "Unpaid" &&
          (!budgetId || Number(allocatedRates) === 0)) ||
        !roleFk);

    const isMissingStaffFields = () =>
      isStaff(scheduleItemType) && (isEmpty(category) || !roleFk || !personFk);

    if (
      isEmpty(scheduleItemType) ||
      isMissingPersonFk() ||
      isMissingContractorOrTalentFields() ||
      isMissingStaffFields()
    ) {
      props.setSnackBarProps("warning", "Missing fields", true);
      return false;
    }

    return true;
  };

  const submitNewScheduleItem = () => {
    if (checkRequiredFieldsForScheduleItem()) {
      setIsSavingScheduleItem(true);
      const isClient =
        scheduleItem.personFkNavigation?.personCategory === "Client";
      const isStaff =
        scheduleItem.personFkNavigation?.personCategory === "Staff";
      let url = `/ScheduleItems/PostScheduleItem`;
      if (scheduleItem.scheduleItemType === "Talent") {
        url = `/ScheduleItems/PostScheduleItemTalent`;
      }
      axios
        .post(url, {
          scheduleFk: props.schedule?.id,
          category: isClient ? "ClientItem" : scheduleItem.category || "",
          scheduleItemType: scheduleItem.scheduleItemType,
          budgetId: scheduleItem.budgetId || null,
          startTime: moment(scheduleItem.startTime).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          endTime: moment(scheduleItem.endTime).format("YYYY-MM-DD HH:mm:ss"),
          allocatedRates: scheduleItem.allocatedRates || 0,
          allocatedRatesInNzd: scheduleItem.allocatedRatesInNzd || 0,
          providerName:
            scheduleItem.personFkNavigation?.firstName +
            " " +
            scheduleItem.personFkNavigation?.lastName,
          isStaff: isStaff
            ? 1
            : scheduleItem.personFkNavigation?.personCategory === "Talent"
            ? 2
            : 0,
          isClient: isClient ? 1 : 0,
          prePostNotes: isClient || isStaff ? null : "-",
          companyFk: props.project?.companyFk || null,
          personFk: scheduleItem.personFk,
          contractStatus:
            scheduleItem?.personFkNavigation?.currency !== "NZD" ||
            scheduleItem.scheduleItemType !== "Talent"
              ? "Send"
              : scheduleItem.category !== "Unpaid"
              ? "Disabled"
              : "Send",
          talentContractType:
            scheduleItem?.personFkNavigation?.currency !== "NZD"
              ? "BuyOut"
              : scheduleItem.scheduleItemType === "Talent"
              ? "Restricted"
              : scheduleItem.scheduleItemType === "Contractor"
              ? "BuyOut"
              : null,
          talentContractStatus:
            scheduleItem.scheduleItemType === "Talent" ? "Incomplete" : null,
          roleFk: scheduleItem.roleFk || null,
          createdBy: user?.email,
        })
        .then(({ data }) => {
          props.getScheduleItems(parseInt(location.pathname.split("/")[5]));
          props.setSnackBarProps(
            "success",
            "All done! Your record has been saved.",
            true
          );
          setScheduleItem({
            scheduleItemType: scheduleItem.scheduleItemType,
            startTime: props.schedule?.startTime || new Date(),
            endTime: props.schedule?.endTime || new Date(),
          });
        })
        .catch((error) => {
          props.setSnackBarProps(
            "warning",
            "Oh no! Something went wrong.",
            true
          );
          console.log(error);
        })
        .finally(() => setIsSavingScheduleItem(false));
    }
  };

  const handleCompleteTalentContract = (sItem, outputs) => {
    props.editScheduleItem(
      sItem,
      "talent",
      "contractStatus",
      "Send",
      1,
      user?.email
    );
    props.editScheduleItem(
      sItem,
      "talent",
      "talentContractStatus",
      "Complete",
      1,
      user?.email
    );
    props.editScheduleItem(
      sItem,
      "talent",
      "contractStatus",
      "Send",
      1,
      user?.email
    );
    props.editScheduleItem(
      sItem,
      "talent",
      "isAucklandStandardContract",
      0,
      1,
      user?.email
    );
    var payload = {
      ScheduleItemFk: sItem.id,
      Outputs: JSON.stringify(outputs),
      TalentContractOutput: outputs,
    };
    axios
      .post(`TalentContracts/PostTalentContract/${sItem.id}`, payload)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const handleCompleteAucklandContract = (sItem, outputs) => {
    props.editScheduleItem(
      sItem,
      "talent",
      "talentContractStatus",
      "Draft",
      1,
      user?.email
    );
    // props.editScheduleItem(sItem, "talent", "contractStatus", "Send",1, user?.email);
    props.editScheduleItem(
      sItem,
      "talent",
      "isAucklandStandardContract",
      1,
      1,
      user?.email
    );
    console.log("PostAucklandTalentContract");
    console.log(outputs);
    var payload = {
      talentContract: {
        ScheduleItemFk: sItem.id,
        Outputs: JSON.stringify(outputs),
        TalentContractOutput: outputs,
      },
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phoneNumber,
    };
    axios
      .post(`TalentContracts/PostAucklandTalentContract/${sItem.id}`, payload)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const handleCompleteTalentNotes = (sItem, notes) => {
    props.editScheduleItem(
      sItem,
      "talent",
      "talentNotes",
      notes,
      1,
      user?.email
    );
    axios
      .patch(`ScheduleItems/PatchTalentNotes/${sItem.id}`, {
        id: sItem.id,
        talentNotes: notes,
      })
      .then((res) => {
        console.log(res.data);
        props.setSnackBarProps("success", "Talent Notes Saved", true);
      })
      .catch((err) => {
        console.log(err);
        props.setSnackBarProps("warning", "Error Saving Talent Notes", true);
      });
  };

  const returnNextStage = (progress) => {
    switch (progress) {
      case "IP":
        return "C";
      case "C":
      case undefined:
      case null:
        return "IP";
      default:
        return "";
    }
  };
  const toggleTrafficLight = (stage, schedule) => {
    props.updateScheduleProgress(
      stage,
      returnNextStage(schedule[stage]),
      schedule
    );
  };
  const returnIcon = (schedule, stage) => {
    if (schedule[stage] == null) {
      return (
        <Brightness1
          onClick={() => toggleTrafficLight(stage, schedule)}
          style={{ color: "#d1d1d1", marginTop: "5%", marginLeft: "5%" }}
        />
      );
    } else if (schedule[stage] === "IP") {
      return (
        <Brightness1
          onClick={() => toggleTrafficLight(stage, schedule)}
          style={{
            color: "#ff8c3b",
            marginTop: "5%",
            marginLeft: "5%",
          }}
        />
      );
    } else {
      return (
        <CheckCircle
          style={{
            color: "#4dfa59",
            marginTop: "5%",
            marginLeft: "5%",
          }}
          onClick={() => toggleTrafficLight(stage, schedule)}
        />
      );
    }
  };

  var scheduleTable = (
    <div style={{ background: "#eee" }}>
      <Card style={{ marginBottom: "15px", marginLeft: "15px" }}>
        {!props.budget.budgetLoading ? (
          <BudgetItemsTable
            budgetItems={props.budget.budget}
            parent={"ScheduleWrapper"}
          />
        ) : (
          <Skeleton width={"auto"} height={"500"} styles={{}} />
        )}
      </Card>
      <Card style={{ marginLeft: "15px" }}>
        <AppBar position="static">
          <Tabs
            classes={{ root: classes.root, scroller: classes.scroller }} //root: classes.root,
            value={tabValue}
            onChange={tabChange}
            variant={"scrollable"}
            scrollButtons={"on"}
          >
            <StyledTabLong
              icon={returnIcon(props.schedule, "runSheetStage")}
              label="Run Sheet"
            ></StyledTabLong>
            <StyledTab
              style={{ display: "flex" }}
              icon={returnIcon(props.schedule, "staffStage")}
              label="Staff"
            ></StyledTab>
            <StyledTab
              icon={returnIcon(props.schedule, "clientStage")}
              label="Clients"
            ></StyledTab>
            <StyledTab
              icon={returnIcon(props.schedule, "contractorStage")}
              label="Contractors"
            ></StyledTab>
            <StyledTab
              icon={returnIcon(props.schedule, "talentStage")}
              label="Talent"
            ></StyledTab>
            <StyledTab
              icon={returnIcon(props.schedule, "scheduleStage")}
              label="Schedule"
            ></StyledTab>
          </Tabs>
        </AppBar>
        <TabPanel
          value={tabValue}
          index={1}
          style={{ margin: "0px", padding: "0px" }}
        >
          {props.schedule.staffStage ? (
            <div style={{ margin: "0px", padding: "0px" }}>
              <div>
                {props.loadingSchedule !== true ? (
                  <>
                    <AddNewScheduleItemForm
                      budgetItems={
                        !props.budgetLoading
                          ? props.budget?.budget?.filter(
                              (b) =>
                                b.category !== "Location Fees" &&
                                b.category !== "Catering"
                            )
                          : []
                      }
                      scheduleItemCategories={["Staff"]}
                      showTimePicker={true}
                      scheduleItem={scheduleItem}
                      setScheduleItem={setScheduleItem}
                      handleSubmitForm={submitNewScheduleItem}
                      isSavingScheduleItem={isSavingScheduleItem}
                    />
                    <NewScheduleItem
                      people={allPeople}
                      projectCFk={props.project.companyFk}
                      providerType={"Staff"}
                      reloadPersonList={reloadPersonList}
                    />
                  </>
                ) : (
                  <Skeleton width={"auto"} height={"auto"} />
                )}
              </div>
              <div>
                {props.loadingSchedule !== true ? (
                  <ScheduleItemStaffTable
                    companies={allCompanies}
                    deleteScheduleItem={props.deleteScheduleItem}
                    editScheduleItem={props.editScheduleItem}
                    getBudget={props.getBudget}
                    people={allPeople}
                    project={props.project}
                    providerType={"Staff"}
                    scheduleItems={props.scheduleItems.staff}
                    setSnackBarProps={props.setSnackBarProps}
                  />
                ) : (
                  <Skeleton width={"auto"} height={"auto"} />
                )}
              </div>
            </div>
          ) : (
            <div>Please click the checkbox to start.</div>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <TabPanel value={tabValue} index={2}>
            {props.schedule.clientStage && props.loadingSchedule !== true ? (
              <>
                <AddNewScheduleItemForm
                  budgetItems={
                    !props.budgetLoading
                      ? props.budget?.budget?.filter(
                          (b) =>
                            b.category !== "Location Fees" &&
                            b.category !== "Catering"
                        )
                      : []
                  }
                  scheduleItemCategories={["Client"]}
                  companyId={props.project?.companyFk}
                  showTimePicker={true}
                  scheduleItem={scheduleItem}
                  setScheduleItem={setScheduleItem}
                  handleSubmitForm={submitNewScheduleItem}
                  isSavingScheduleItem={isSavingScheduleItem}
                  selectedCompanyIdForInternal={selectedCompanyIdForInternal}
                  setSelectedCompanyIdForInternal={setSelectedCompanyIdForInternal}
                  isInternal={isInternal}
                  clientList={clientList}
                />

                <NewScheduleItem
                  people={allPeople}
                  projectCFk={props.project.companyFk}
                  providerType={"Client"}
                  reloadPersonList={reloadPersonList}
                  selectedCompanyIdForInternal={selectedCompanyIdForInternal}
                  isInternal={isInternal}
                />
              </>
            ) : (
              ""
            )}
            {props.schedule.clientStage && props.loadingSchedule !== true ? (
              <ClientTable
                companies={allCompanies}
                deleteScheduleItem={props.deleteScheduleItem}
                editScheduleItem={props.editScheduleItem}
                getBudget={props.getBudget}
                getScheduleItems={props.getScheduleItems}
                people={allPeople}
                project={props.project}
                projectCFk={props.project.companyFk}
                providerType={"Client"}
                reloadPersonList={reloadPersonList}
                scheduleItems={props.scheduleItems.client}
                setSnackBarProps={props.setSnackBarProps}
              />
            ) : (
              <div>Please click the checkbox to start.</div>
            )}
          </TabPanel>
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={3}
          style={{ margin: "0px", padding: "0px" }}
        >
          {props.schedule.contractorStage ? (
            <div style={{ margin: "0px", padding: "0px" }}>
              <div>
                <AddNewScheduleItemForm
                  budgetItems={
                    !props.budgetLoading
                      ? props.budget?.budget?.filter(
                          (b) =>
                            b.category !== "Location Fees" &&
                            b.category !== "Catering"
                        )
                      : []
                  }
                  showTimePicker={true}
                  addUnpaidOption={true}
                  scheduleItem={scheduleItem}
                  setScheduleItem={setScheduleItem}
                  handleSubmitForm={submitNewScheduleItem}
                  isSavingScheduleItem={isSavingScheduleItem}
                  scheduleItemCategories={["Contractor"]}
                />
                <NewScheduleItem
                  people={allPeople}
                  projectCFk={props.project.companyFk}
                  providerType={"Contractor"}
                  reloadPersonList={reloadPersonList}
                />
              </div>
              <div>
                <ScheduleItemTable
                  companies={allCompanies}
                  deleteScheduleItem={props.deleteScheduleItem}
                  editScheduleItem={props.editScheduleItem}
                  epContractor={epContractor}
                  getBudget={props.getBudget}
                  people={allPeople}
                  project={props.project}
                  providerType={"Contractor"}
                  reloadPersonList={() => setPeopleFetched(false)} //;reloadPersonList()
                  scheduleItems={props.scheduleItems.contractor}
                  setSnackBarProps={props.setSnackBarProps}
                />
              </div>
            </div>
          ) : (
            <div>Please click the checkbox to start. </div>
          )}
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={4}
          style={{ margin: "0px", padding: "0px" }}
        >
          {props.schedule.talentStage ? (
            <div style={{ margin: "0px", padding: "0px" }}>
              <div>
                <AddNewScheduleItemForm
                  budgetItems={
                    !props.budgetLoading
                      ? props.budget.budget.filter(
                          (b) =>
                            b.category !== "Location Fees" &&
                            b.category !== "Catering"
                        )
                      : []
                  }
                  showTimePicker={true}
                  addUnpaidOption={true}
                  scheduleItem={scheduleItem}
                  setScheduleItem={setScheduleItem}
                  handleSubmitForm={submitNewScheduleItem}
                  isSavingScheduleItem={isSavingScheduleItem}
                  scheduleItemCategories={["Talent"]}
                />
                <NewScheduleItem
                  people={allPeople}
                  projectCFk={props.project.companyFk}
                  providerType={"Talent"}
                  reloadPersonList={reloadPersonList}
                />
              </div>
              <div>
                <ScheduleItemTable
                  completeTalentContract={handleCompleteTalentContract}
                  handleCompleteAucklandContract={
                    handleCompleteAucklandContract
                  }
                  handleCompleteTalentNotes={handleCompleteTalentNotes}
                  deleteScheduleItem={props.deleteScheduleItem}
                  editScheduleItem={props.editScheduleItem}
                  epTalent={epTalent}
                  getBudget={props.getBudget}
                  people={allPeople}
                  project={props.project}
                  projectTag={props.project.projectTag}
                  projectid={props.project.id}
                  providerType={"Talent"}
                  reloadPersonList={() => setPeopleFetched(false)} //;reloadPersonList()
                  scheduleItems={props.scheduleItems.talent}
                  setSnackBarProps={props.setSnackBarProps}
                />
              </div>
            </div>
          ) : (
            <div>Please click the checkbox to start. </div>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={0}>
          {props.schedule.runSheetStage && props.schedule.startTime ? (
            <RunSheet
              scheduleId={props.schedule.id}
              scheduleTime={props.schedule.startTime}
            />
          ) : (
            <div>Please click the checkbox to start. </div>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          {props.schedule.scheduleStage ? (
            <ScheduleTab
              project={props.project}
              scheduleId={props.schedule.id}
              schedule={props.schedule}
            />
          ) : (
            <div>Please click the checkbox to start. </div>
          )}
        </TabPanel>
      </Card>
      <Snacky
        snackprops={props.snackbar}
        setSnackBarProps={props.setSnackBarProps}
      />
    </div>
  );
  return props.schedule ? scheduleTable : <Loader />;
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  schedule: state.schedules.schedule,
  budgetLoading: state.budget.budgetLoading,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  deleteScheduleItem,
  editScheduleItem,
  getLowerCasePeople,
  getScheduleItems,
  postScheduleItem,
  setSnackBarProps,
  updateScheduleProgress,
})(ScheduleWrapper);
