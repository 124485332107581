import React, { useEffect, useState } from "react";
import "./clientCampaign.scss";
import { Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ProjectCampaignDetails from "../../Project/Schedule/PrePost/InfluencerNetwork/Campaign/ProjectCampaignDetails";
import CustomAccordion from "../../Shared/Accordion/CustomAccordion";
import CustomAccordionControlled from "../../Shared/Accordion/CustomAccordionControlled";
import axios from "axios";
import Snacky from "../../Shared/Snacky";
import { checkIfContainsBudget } from "../../../utils/BudgetItemsUtils";
import CampaignInfluencerNetwork from "../Influencer/CampaignInfluencerNetwork";
import ProcessingSpinner from "../../Shared/Spinner/ProcessingSpinner";
import ScriptWrapper from "../../Project/ScriptWrapper";
import ProjectCampaignProgress from "../../Project/Schedule/PrePost/InfluencerNetwork/Campaign/ProjectCampaignProgress";
import ApprovalRequestDialog from "../../Project/Schedule/PrePost/InfluencerNetwork/ApprovalRequest/ApprovalRequestDialog";
import OutreachDialog from "../../Project/Schedule/PrePost/InfluencerNetwork/Outreach/OutreachDialog";
import { useUserContext } from "../../../context/user/UserContext";
import { useAuth } from "../../../context/auth/AuthContext";
import { Skeleton } from "@material-ui/lab";
import GenerateOldCampaignBriefDialog from "../Brief/GenerateOldCampaignBriefDialog";
const ClientCampaign = ({
  selectedCampaign,
  handleGoBackClick,
  budgetItems,
  fetchAllBudget,
}) => {
  const { user } = useAuth();
  const [campaign, setCampaign] = useState(null);
  const [project, setProject] = useState(null);
  const [scheduleItems, setScheduleItems] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [isLoadingScheduleItems, setIsLoadingScheduleItems] = useState(false);
  const [campaignHasChanges, setCampaignHasChanges] = useState(false);
  const [briefSlides, setBriefSlides] = useState(null);
  const [isGeneratingBrief, setIsGeneratingBrief] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [openApprovalRequestDialog, setOpenApprovalRequestlDialog] =
    useState(false);
  const [openOutreachDialog, setOpenOutreachDialog] = useState(false);
  const [showDuplicateBrief, setShowDuplicateBrief] = useState(false);

  useEffect(() => {
    setCampaign(selectedCampaign);
    fetchProjectDetails();
  }, []);

  useEffect(() => {
    if (!briefSlides) {
      getBrief();
    }
  }, [briefSlides, project]);

  const getBrief = () => {
    if (project) {
      axios
        .get(`ProjectSlides/GetSlides/Influencer/${project.id}`)
        .then(({ data }) => {
          if (data?.length > 0) {
            setBriefSlides(data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleGenerateBrief = () => {
    if (campaign?.id) {
      setIsGeneratingBrief(true);
      axios
        .post(`campaign/generateBrief/${project?.id}/${campaign?.id}`)
        .then(({ data }) => {
          if (data) {
            setBriefSlides([data]);
          }
          setIsGeneratingBrief(false);
        })
        .catch((err) => {
          setIsGeneratingBrief(false);
        });
    } else {
      alert("Please make sure to enter and save the campaign details");
    }
  };

  const handleOnChangeCampaign = (field, value) => {
    setCampaign({ ...campaign, [field]: value });
    setCampaignHasChanges(true);
  };

  const fetchProjectDetails = async (projectId) => {
    if (selectedCampaign?.projectFk || projectId) {
      setIsLoadingScheduleItems(true);
      axios
        .get(
          `projects/GetProjectDetailsForCampaign/${selectedCampaign?.projectFk || projectId
          }`
        )
        .then(({ data }) => {
          setProject(data);
          if (data.schedule?.length > 0) {
            const _schedule = data.schedule[0];
            setSchedule(_schedule);
            setScheduleItems(_schedule.scheduleItem);
          }
        })
        .catch((err) => {
          console.log(err);
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Unable to Find Campaign Details",
          });
        })
        .finally(() => setIsLoadingScheduleItems(false));
    }
  };

  const handleSaveCampaign = () => {
    axios
      .post(`Campaign/UpdateCampaign`, campaign)
      .then(({ data }) => {
        setCampaign(data);
        if (!project) {
          fetchProjectDetails(data.projectFk);
        }
        setCampaignHasChanges(false);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Your changes have been saved!",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Hm, it looks like something went wrong.",
        });
      });
  };

  const handleOnChangeCampaignObject = (newCampaignObject) => {
    setCampaign({ ...newCampaignObject });
    setCampaignHasChanges(true);
  };

  const isCampaignValid = () => {
    let isValid = false;
    if (
      campaign?.id &&
      campaign?.name 
    ) {
      isValid = true;
    }
    return isValid;
  };

  const handleOpenApprovalRequestDialog = () => {
    setOpenApprovalRequestlDialog(true);
  };

  const handleCloseApprovalRequestDialog = () => {
    setOpenApprovalRequestlDialog(false);
  };

  const handleApprovalRequestSentCallback = (sentScheduleItems) => {
    setScheduleItems(
      scheduleItems.map((si) => {
        var sentSchduleItem = sentScheduleItems.find((s) => s.id === si.id);
        if (sentSchduleItem) {
          return {
            ...si,
            scheduleItemStatusFkNavigation:
              sentSchduleItem.scheduleItemStatusFkNavigation,
          };
        }

        return si;
      })
    );
  };

  const handleOpenOutreachInfluencersDialog = () => {
    setOpenOutreachDialog(true);
  };

  const handleCloseOutreachInfluencersDialog = () => {
    setOpenOutreachDialog(false);
  };

  const handleManualClientApproval = (scheduleItem) => {
    axios
      .post(
        `ScheduleItems/UpdateClientApprovalStatus/${scheduleItem.id}/approved/${user?.email
        }`
      )
      .then(({ data }) => {
        if (data) {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Your changes have been saved!",
          });
          handleUpdateScheduleItemStatus(
            scheduleItem.id,
            data.scheduleItemStatusFkNavigation
          );
        } else {
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Hm, it looks like something went wrong.",
          });
        }
      });
  };

  const handleManualContentReview = (scheduleItem) => {
    axios
      .post(
        `ScheduleItems/UpdateContentReviewStatus/${scheduleItem.id}/approved`
      )
      .then(({ data }) => {
        if (data) {
          setSnackBarProps({
            open: true,
            severity: "success",
            text: "Your changes have been saved!",
          });
        } else {
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Hm, it looks like something went wrong.",
          });
        }
      });
  };

  const handleManualContentApproval = (scheduleItem, status) => {
    handleUpdateScheduleItemStatus(scheduleItem.id, status);
  };

  const handleUpdateScheduleItemStatus = (
    scheduleItemId,
    scheduleItemStatus
  ) => {
    setScheduleItems(
      scheduleItems.map((si) =>
        si.id === scheduleItemId
          ? { ...si, scheduleItemStatusFkNavigation: scheduleItemStatus }
          : si
      )
    );
  };

  const handleUpdateScheduleItemContractStatus = (
    scheduleItemId,
    scheduleItemStatus
  ) => {
    setScheduleItems(
      scheduleItems.map((si) =>
        si.id === scheduleItemId
          ? { ...si, contractStatus: scheduleItemStatus }
          : si
      )
    );
  };

  return (
    <div className="campaign-form-root">
      {openApprovalRequestDialog && scheduleItems.length > 0 && (
        <ApprovalRequestDialog
          open={openApprovalRequestDialog}
          handleClose={handleCloseApprovalRequestDialog}
          scheduleItems={scheduleItems}
          scheduleId={scheduleItems[0]?.scheduleFk}
          setSnackBarProps={setSnackBarProps}
          project={project}
          handleApprovalRequestSentCallback={handleApprovalRequestSentCallback}
        />
      )}
      {openOutreachDialog && scheduleItems.length > 0 && (
        <OutreachDialog
          open={openOutreachDialog}
          handleClose={handleCloseOutreachInfluencersDialog}
          scheduleItems={scheduleItems}
          scheduleId={scheduleItems[0]?.scheduleFk}
          setSnackBarProps={setSnackBarProps}
          handleSaveCallback={async () => { await fetchProjectDetails(project.id) }}
        />
      )}
      {showDuplicateBrief && (
        <GenerateOldCampaignBriefDialog
          open={showDuplicateBrief}
          currentProject={project}
          handleClose={() => { setShowDuplicateBrief(false) }}
          handleSuccessCallback={(data) => {
            if (data) {
              setBriefSlides([data]);
              setShowDuplicateBrief(false)
            }
            setIsGeneratingBrief(false);
          }}
        />
      )}
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      <div className="campaign-form-nav-header">
        <Button
          size="small"
          startIcon={<ArrowBackIcon />}
          className="campaign-form-nav-header-btn"
          onClick={handleGoBackClick}
        >
          Go Back
        </Button>
      </div>
      <div>
        {campaign && (
          <CustomAccordion title={"Campaign Details"} alertMessage={""}>
            <ProjectCampaignDetails
              campaign={campaign}
              handleOnChange={handleOnChangeCampaign}
              handleSaveCampaign={handleSaveCampaign}
              handleOnChangeCampaignObject={handleOnChangeCampaignObject}
            />
          </CustomAccordion>
        )}
      </div>
      {isCampaignValid() ? (
        scheduleItems?.length > 0 || checkIfContainsBudget(budgetItems, "influencer spend")
        || checkIfContainsBudget(budgetItems, "influencer/content creator") ? (
          <CustomAccordion title={"Influencers & Content Creators"}>
            <CampaignInfluencerNetwork
              project={project}
              scheduleItems={scheduleItems}
              setScheduleItems={setScheduleItems}
              isLoadingScheduleItems={isLoadingScheduleItems}
              setSnackBarProps={setSnackBarProps}
              budgetItems={budgetItems}
              fetchProjectDetails={fetchProjectDetails}
              fetchAllBudget={fetchAllBudget}
            />
          </CustomAccordion>
        ) : (
          <div className="client-campaign-flex-container-center ">
            <Typography variant="h6">
              {" "}
              No Budget has been allocated to Influencer Spend.
            </Typography>
          </div>
        )
      ) : (
        <></>
      )}

      {isCampaignValid() && scheduleItems?.length === 0 && (
        <div style={{ marginTop: 50 }}></div>
      )}


      {isCampaignValid() && scheduleItems?.length > 0 && (
        <CustomAccordionControlled title={"Brief"} defaultExpanded={false}>
          {!briefSlides ? (
            isGeneratingBrief ? (
              <div className="project-influencer-generating-brief-loader">
                <ProcessingSpinner duration={60} unitTime={"seconds"} />
              </div>
            ) : (
              <div>
                <Button
                  variant="contained"
                  onClick={handleGenerateBrief}
                  className={isGeneratingBrief ? "margin-sides-10 default-disabled-button" : "margin-sides-10 default-button"}
                  size="small"
                  disabled={isGeneratingBrief}
                >
                  Generate new brief
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setShowDuplicateBrief(true)}
                  className={showDuplicateBrief ? "margin-sides-10 default-disabled-button" : "margin-sides-10 default-button"}
                  size="small"
                  disabled={showDuplicateBrief}
                >
                  Generate from old brief
                </Button>
              </div>
            )
          ) : (
            <ScriptWrapper
              url={project.projectScript}
              projectId={project.id}
              project={project}
              slidesFromParent={briefSlides}
            />
          )}
        </CustomAccordionControlled>
      )
      }


      {
        isCampaignValid() && scheduleItems?.length > 0 && (
          <CustomAccordion title={"Run Campaign"}>
            <div className="project-influencer-network-campaign-status-container">
              <div className="client-campaign-flex-container-start">
                <Button
                  size="small"
                  className="default-button"
                  variant="contained"
                  onClick={handleOpenApprovalRequestDialog}
                >
                  Send to Client
                </Button>
                <Button
                  size="small"
                  className="default-button"
                  variant="contained"
                  onClick={handleOpenOutreachInfluencersDialog}
                >
                  Influencer Outreach
                </Button>
              </div>
              <div>
                <ProjectCampaignProgress
                  scheduleItems={scheduleItems}
                  handleManualClientApproval={handleManualClientApproval}
                  handleManualContentReview={handleManualContentReview}
                  handleManualContentApproval={handleManualContentApproval}
                  handleUpdateScheduleItemContractStatus={
                    handleUpdateScheduleItemContractStatus
                  }
                />
              </div>
            </div>
          </CustomAccordion>
        )
      }
    </div >
  );
};

export default ClientCampaign;
