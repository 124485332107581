import { Table, TableCell, TableRow, TableBody, Typography } from "@material-ui/core";
import React from "react";
import { currencyFormat } from "../../../utils/GeneralUtils";

const BudgetItemTotalTable = ({ budget }) => {

  return (
    <Table className="budget-accordion-footer-table">
      <TableBody>
        <TableRow>
          <TableCell className="column1 no-border-cell no-padding-cell no-background-cell roboto-medium">
            <strong>Total</strong>
          </TableCell>
          <TableCell className="column2 no-border-cell no-padding-cell no-background-cell">
            <div className="budget-accordion-header-total-column-container">
              <Typography variant="caption" className="roboto-medium">Quoted</Typography>

              <Typography variant="caption">
                {currencyFormat(Number(budget.quoted), budget.currency)}
              </Typography>
            </div>
          </TableCell>
          <TableCell className="column3 no-border-cell no-padding-cell no-background-cell">
            <div className="budget-accordion-header-total-column-container">
              <Typography variant="caption" className="roboto-medium">Allocated</Typography>

              <Typography variant="caption">
                  {currencyFormat(Number(budget.allocated),budget.currency)}
              </Typography>
            </div>
          </TableCell>
          <TableCell className="column4 no-border-cell no-padding-cell no-background-cell">
            <div className="budget-accordion-header-total-column-container">
              <Typography variant="caption" className={Number(budget.remaining) > 0 ? "roboto-medium text-green" : "roboto-medium"}>Remaining</Typography>
              <Typography variant="caption" className={Number(budget.remaining) > 0 ? "roboto-medium text-green" : "roboto-medium"}>
                  {currencyFormat(Number(budget.remaining), budget.currency)}
              </Typography>
            </div>
          </TableCell>
          <TableCell className="column5 no-border-cell no-padding-cell no-background-cell"></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default BudgetItemTotalTable;
