import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Typography } from "@material-ui/core";
import NewPrePost from "./NewPrePost";
import PrePostTable from "./PrePostTable";
import BudgetItemsTable from "../BudgetItemsTable";
import { setSnackBarProps } from "../../../../actions/snackbar";
import { getBudget } from "../../../../actions/budget";
import Snacky from "../../../Shared/Snacky";
import { useAuth } from "../../../../context/auth/AuthContext";
import ClientPurchaseOrder from "../../../Client/PurchaseOrder/ClientPurchaseOrder";
import "./prePostProduction.scss";
import { Skeleton } from "@material-ui/lab";
const PrePostProduction = (props) => {
  const { user } = useAuth();
  const [scheduleItems, setScheduleItems] = useState([]);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [budgetsForPO, setBudgetsForPO] = useState([]);
  const [budgetsForPoLoading, setBudgetsForPoLoading] = useState(false);

  const passNewScheduleUp = (newItm) => {
    setScheduleItems([...scheduleItems, newItm]);
    props.getBudget(props.projectId);
  };

  const deleteScheduleItem = (item) => {
    setScheduleItems(scheduleItems.filter((sI) => sI !== item));
    axios
      .delete(`ScheduleItems/DeleteScheduleItem/${item.id}`)
      .then((res) => {
        props.setSnackBarProps(
          "success",
          "Record removed from the project",
          true
        );
        props.getBudget(props.projectId);
      })
      .catch((error) => {
        console.log(error);
        props.setSnackBarProps("warning", "Failed to delete item", true);
      });
  };

  useEffect(() => {
    getScheduleItemsForPrePostProduction();
  }, []);

  const getScheduleItemsForPrePostProduction = () => {
    setIsLoadingItems(true);
    // axios
    //   .get(`/PrePostProductions/GetPrePostProductions/${props.projectId}`)
    //   .then((response) => {
    //     console.log("PrePostProductions");
    //     console.log(response.data);
    //     setItems(response.data.prePostScheduleItems);
    //     setPrepostScheduleId(response.data.prePostScheduleId);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
    //   .finally(() => setIsLoadingItems(false));
    axios
      .get(`/ProductionScheduleItem/GetPrePostScheduleItems/${props.projectId}`)
      .then(({ data }) => {
        setScheduleItems(data);
      })
      .catch((error) => {
        console.log(error);
        props.setSnackBarProps("warning", "Unable to fetch records", true);
      })
      .finally(() => setIsLoadingItems(false));
  };

  const removePreviousContract = (sItem) => {
    axios
      .patch(`TalentContracts/ClearContractDriveId/${sItem.id}`)
      .then((res) => {
        console.log("Cleared Contract");
      })
      .catch((e) => {
        console.log("Error clearing contract");
      });
  };

  const handleSwitchContractType = (sItem) => {
    var contractType =
      sItem.talentContractType === "Restricted" ? "BuyOut" : "Restricted";
    var talentContractStatus = "";
    var contractStatus = "";
    if (contractType === "Restricted") {
      talentContractStatus = "Incomplete";
      contractStatus = "Disabled";
      setScheduleItems(
        scheduleItems.map((sI) =>
          sI.id === sItem.id
            ? {
                ...sI,
                talentContractType: contractType,
                talentContractStatus: talentContractStatus,
                contractStatus: contractStatus,
                isAucklandStandardContract: 0,
              }
            : sI
        )
      );
      sItem.talentContractType = contractType;
      sItem.talentContractStatus = talentContractStatus;
      sItem.contractStatus = contractStatus;
      sItem.isAucklandStandardContract = 0;
      removePreviousContract(sItem);
    } else if (contractType === "BuyOut") {
      talentContractStatus = "Complete";
      contractStatus = "Send";
      setScheduleItems(
        scheduleItems.map((sI) =>
          sI.id === sItem.id
            ? {
                ...sI,
                talentContractType: contractType,
                talentContractStatus: talentContractStatus,
                contractStatus: contractStatus,
              }
            : sI
        )
      );
      sItem.talentContractType = contractType;
      sItem.talentContractStatus = talentContractStatus;
      sItem.contractStatus = contractStatus;
    }
    var payload = sItem;
    axios
      .put(`ScheduleItems/UpdateScheduleItem/${sItem.id}`, payload, {
        params: {
          user: user?.email,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCompletePrePostNotes = (sItem, notes) => {
    setScheduleItems(
      scheduleItems.map((sI) =>
        sI.id === sItem.id
          ? { ...sI, prePostNotes: notes, contractStatus: "Send" }
          : sI
      )
    );
    sItem.contractStatus = "Send";
    var payload = sItem;
    payload.PrePostNotes = notes;
    console.log("handleCompletePrePostNotes");
    axios
      .put(`ScheduleItems/UpdateScheduleItem/${sItem.id}`, payload, {
        params: {
          user: user?.email,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCompleteTalentContract = (sItem, outputs) => {
    setScheduleItems(
      scheduleItems.map((sI) =>
        sI.id === sItem.id
          ? { ...sI, talentContractStatus: "Complete", contractStatus: "Send" }
          : sI
      )
    );
    var payload = sItem;
    payload.talentContractStatus = "Complete";
    payload.contractStatus = "Send";
    axios
      .put(`ScheduleItems/UpdateScheduleItem/${sItem.id}`, payload, {
        params: {
          user: user?.email,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    payload = {
      ScheduleItemFk: sItem.id,
      Outputs: JSON.stringify(outputs),
    };
    axios
      .post(`TalentContracts/PostTalentContract/${sItem.id}`, payload)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const handleChangeAllocatedRate = (
    currency,
    allocatedRates,
    allocatedRatesInNzd,
    scheduleItem
  ) => {
    const _scheduleItem = {
      ...scheduleItem,
      allocatedRates: allocatedRates || null,
      allocatedRatesInNzd: allocatedRatesInNzd || null,
    };
    setScheduleItems(
      scheduleItems.map((si) =>
        si.id === scheduleItem.id ? _scheduleItem : si
      )
    );

    axios
      .patch(
        `ScheduleItems/PatchScheduleItem/${scheduleItem.id}`,
        _scheduleItem,
        {
          params: {
            user: user?.email,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        props.setSnackBarProps("warning", "Failed to update item", true);
        console.log(err);
      });
  };

  const handleScheduleItemEdit = (value, name, scheduleItem) => {
    setScheduleItems(
      scheduleItems.map((s) =>
        s.id === scheduleItem.id
          ? {
              ...s,
              [name]: value,
            }
          : s
      )
    );
    if (
      name === "startTime" ||
      name === "endTime" ||
      name === "isAucklandStandardContract"
    ) {
      scheduleItem[name] = value;
      axios
        .patch(
          `ScheduleItems/PatchScheduleItem/${scheduleItem.id}`,
          scheduleItem,
          {
            params: {
              user: user?.email,
            },
          }
        )
        .then((res) => console.log(res.data))
        .catch((err) => console.log(err));
    }
    props.getBudget(props.projectId);
  };

  const handleCompleteAucklandContract = (sItem, outputs) => {
    setScheduleItems(
      scheduleItems.map((sI) =>
        sI.id === sItem.id
          ? {
              ...sI,
              talentContractStatus: "Draft",
              isAucklandStandardContract: 1,
            }
          : sI
      )
    );
    var payload = {
      talentContract: {
        ScheduleItemFk: sItem.id,
        Outputs: JSON.stringify(outputs),
        TalentContractOutput: outputs,
      },
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
    };
    axios
      .post(`TalentContracts/PostAucklandTalentContract/${sItem.id}`, payload)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const confirmAucklandStandard = (sItem) => {
    setScheduleItems(
      scheduleItems.map((s) =>
        s.id === sItem.id
          ? {
              ...s,
              ["talentContractStatus"]: "Complete",
              ["contractStatus"]: "Send",
              ["isAucklandStandardContract"]: 1,
            }
          : s
      )
    );
    sItem["talentContractStatus"] = "Complete";
    sItem["contractStatus"] = "Send";
    sItem["isAucklandStandardContract"] = 1;
    axios
      .patch(`ScheduleItems/PatchScheduleItem/${sItem.id}`, sItem, {
        params: {
          user: user?.email,
        },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const fetchAllBudget = () => {
    setBudgetsForPoLoading(true);
    axios
      .get(
        `budgetitems/GetBudgetForCompanyByProject/${props.project.companyFk}`,
        {
          params: {
            isArchived: 0,
            projectId: props.project.id,
            showHidden: true,
          },
        }
      )
      .then(({ data }) => {
        setBudgetsForPO(data);
      })
      .finally(() => {
        setBudgetsForPoLoading(false);
      });
  };

  useEffect(() => {
    fetchAllBudget();
  }, []);

  return (
    <div>
      <Snacky
        snackprops={props.snackbar}
        setSnackBarProps={props.setSnackBarProps}
      />
      <div style={{ marginBottom: 20 }}>
        <BudgetItemsTable
          budgetItems={props.budget.budget}
          parent={"PrePost"}
        />
      </div>
      <>
        <div className="pre-post-production-form-table-container">
          <div className="pre-post-production-add-new-container">
            <NewPrePost
              schedule={{
                id: 1000,
              }}
              // providerType={"Contractor"}
              // providerType={""}
              passNewScheduleUp={passNewScheduleUp}
              budgetItems={
                !props.budgetLoading
                  ? props.budget.budget.filter(
                      (b) =>
                        b.category !== "Location Fees" &&
                        b.category !== "Catering"
                    )
                  : []
              }
              project={props.project}
              snackbar={props.snackbar}
              setSnackBarProps={props.setSnackBarProps}
            />
          </div>
          <div className="pre-post-production-table-container">
            {isLoadingItems ? (
              <div style={{ padding: 30 }}>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
              </div>
            ) : scheduleItems?.length > 0 ? (
              <PrePostTable
                scheduleItems={scheduleItems}
                budgetItems={props.budget.budget}
                snackbar={props.snackbar}
                setSnackBarProps={props.setSnackBarProps}
                deleteScheduleItem={deleteScheduleItem}
                reloadScheduleItemsList={() =>
                  getScheduleItemsForPrePostProduction()
                }
                completeTalentContract={handleCompleteTalentContract}
                handleScheduleItemEdit={handleScheduleItemEdit}
                completePrePostNotes={handleCompletePrePostNotes}
                switchContractType={handleSwitchContractType}
                projectTag={props.project.projectTag}
                projectid={props.project.id}
                handleCompleteAucklandContract={handleCompleteAucklandContract}
                confirmAucklandStandard={confirmAucklandStandard}
                projectName={props.project.projectName}
                project={props.project}
                handleChangeAllocatedRate={handleChangeAllocatedRate}
              />
            ) : (
              <div className="pre-post-production-no-records-container">
                <Typography variant="h5">No Records Found</Typography>
              </div>
            )}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          {!budgetsForPoLoading && (
            <ClientPurchaseOrder
              budgetItems={budgetsForPO}
              companyId={props.project.companyFk}
              fetchAllBudget={() => fetchAllBudget()}
            />
          )}
        </div>
      </>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  budgetLoading: state.budget.budgetLoading,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
  getBudget,
})(PrePostProduction);
