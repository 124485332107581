import React, { Component } from "react";
import axios from "axios";

import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Fab,
  TextField,
  RootRef,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicator from "@material-ui/icons/DragIndicator";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import AccessTime from "@material-ui/icons/AccessTime";
import "../../../custom.scss";
import { v1 as uuidv1 } from "uuid";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import { format, parseJSON } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { withStyles } from "@material-ui/core/styles";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result[0].startTime = list[0].startTime;
  result[0].endTime = addMinutes(result[0].startTime, result[0].duration);
  for (var i = 1; i < result.length; i++) {
    result[i].startTime = result[i - 1].endTime;
    result[i].endTime = addMinutes(result[i].startTime, result[i].duration);
  }

  itemsArr = result;
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "#d1edfc",
  }),
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#fafafa" : "#d1edfc",
});

var addMinutes = function (dt, minutes) {
  var dtToDate = new Date(dt);
  return new Date(dtToDate.getTime() + minutes * 60000);
};

var itemsArr = [];

const StyledFab = withStyles((theme) => ({
  root: {
    background: "#000000",
    color: "white",
    "&:hover": {
      background: "#000000",
    },
  },
}))(Fab);

export default class RunSheet extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      id: props.scheduleId,
      created: false,
      runsheetId: 0,
    };
  }

  componentDidMount() {
    this.populateRunsheet();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.items !== prevState.items) {
      this.updateRunsheet(this.state.runsheetId);
    }
  }

  componentWillUnmount() {
    if (this.state.items.length > 0) {
      this.setState({ items: [] });
      itemsArr = [];
    }
  }

  populateRunsheet = () => {
    const id = this.state.id;
    const scheduleStartTime = this.props.scheduleTime;
    axios
      .get("/RunSheetJsons/GetRunSheetJsonForSchedule/" + id)
      .then((res) => {
        const runsheetResponse = res.data;
        if (runsheetResponse.length > 0) {
          const items = JSON.parse(runsheetResponse[0].items);
          this.setState({
            items: items,
            loading: false,
            runsheetId: res.data[0].id,
          });
          itemsArr = items;
          this.changeStartTime(scheduleStartTime, 0);
        } else {
          this.createRunsheet();
          this.setState({ loading: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  createRunsheet = () => {
    const id = this.state.id;
    axios
      .post("/RunSheetJsons/PostRunSheetJson/", {
        Items: "[]",
        ScheduleFk: id,
      })
      .then((res) => {
        const id = res.data.id;
        this.setState({ runsheetId: id, created: true });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  updateRunsheet = (id) => {
    axios
      .put("/RunSheetJsons/PutRunSheetJson/" + id, {
        Id: id,
        Items: JSON.stringify(this.state.items),
        ScheduleFk: this.state.id,
      })
      .then((res) => {
        console.log("Updated________");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  addItem = () => {
    if (itemsArr.length > 0) {
      itemsArr.push({
        id: uuidv1(),
        startTime: itemsArr[itemsArr.length - 1].endTime,
        endTime: addMinutes(itemsArr[itemsArr.length - 1].endTime, 60),
        duration: 60,
        activity: "",
      });
    } else {
      var date = this.props.scheduleTime;
      itemsArr.push({
        id: uuidv1(),
        startTime: date,
        endTime: addMinutes(date, 60),
        duration: 60,
        activity: "",
      });
    }
    this.setState({
      items: itemsArr,
    });

    this.updateRunsheet(this.state.runsheetId);
  };

  removeItem = (index) => {
    itemsArr.splice(index, 1);

    for (var i = 1; i < itemsArr.length; i++) {
      itemsArr[i].startTime = addMinutes(
        itemsArr[i - 1].endTime,
        itemsArr[i - 1].duration
      );
      itemsArr[i].endTime = addMinutes(
        itemsArr[i].startTime,
        itemsArr[i].duration
      );
    }

    this.setState({
      items: itemsArr,
    });

    this.updateRunsheet(this.state.runsheetId);
  };

  changeStartTime = (date, index) => {
    itemsArr[index].startTime = date;
    itemsArr[0].endTime = addMinutes(
      itemsArr[0].startTime,
      itemsArr[0].duration
    );
    for (var i = 1; i < itemsArr.length; i++) {
      itemsArr[i].startTime = itemsArr[i - 1].endTime;
      itemsArr[i].endTime = addMinutes(
        itemsArr[i].startTime,
        itemsArr[i].duration
      );
    }
    this.setState({
      items: itemsArr,
    });
    this.updateRunsheet(this.state.runsheetId);
  };

  changeDuration = (value, index) => {
    itemsArr[index].duration = value;
    itemsArr[index].endTime = addMinutes(itemsArr[index].startTime, value);

    itemsArr[0].endTime = addMinutes(
      itemsArr[0].startTime,
      itemsArr[0].duration
    );
    for (var i = 1; i < itemsArr.length; i++) {
      itemsArr[i].startTime = itemsArr[i - 1].endTime;
      itemsArr[i].endTime = addMinutes(
        itemsArr[i].startTime,
        itemsArr[i].duration
      );
    }

    this.setState({
      items: itemsArr,
    });

    this.updateRunsheet(this.state.runsheetId);
  };

  changeActivity = (value, index) => {
    itemsArr[index].activity = value;

    this.setState({
      items: itemsArr,
    });

    this.updateRunsheet(this.state.runsheetId);
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    this.setState({
      items,
    });
  };

  render() {
    return (
      <div>
        <div style={{ marginLeft: "auto" }}>
          <StyledFab
            size="small"
            style={{ position: "absolute", zIndex: "10", marginTop: "-20px" }}
            onClick={() => this.addItem()}
          >
            <AddIcon />
          </StyledFab>
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <RootRef rootRef={provided.innerRef}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  style={{ height: "100%" }}
                >
                  <List style={{ background: "#fafafa" }}>
                    {this.state.items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <ListItem
                            dense={true}
                            divider={true}
                            ContainerComponent="li"
                            ContainerProps={{ ref: provided.innerRef }}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <DragIndicator
                              fontSize="large"
                              style={{
                                padding: 0,
                                marginLeft: "-15px",
                                color: "#b3b3b3",
                              }}
                            />
                            <KeyboardTimePicker
                              ampm={false}
                              disabled={true}
                              margin="normal"
                              //id="start-time-picker"
                              label="Start Time *"
                              value={item.startTime}
                              size="small"
                              // onChange={() => this.changeStartTime(index)}
                              onChange={(date) =>
                                this.changeStartTime(date, index)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "start time",
                              }}
                              keyboardIcon={<AccessTime />}
                              style={{
                                minWidth: "96px",
                                maxWidth: "96px",
                                marginRight: "5px",
                                color: "black !important",
                              }}
                            />
                            <KeyboardTimePicker
                              ampm={false}
                              disabled={true}
                              margin="normal"
                              id={`start-time-picker-runsheet-${index}`}
                              label="End Time *"
                              value={item.endTime}
                              size="small"
                              KeyboardButtonProps={{
                                "aria-label": "End time",
                              }}
                              keyboardIcon={<AccessTime />}
                              style={{
                                minWidth: "96px",
                                maxWidth: "96px",
                                marginRight: "5px",
                              }}
                            />
                            <ListItemText
                              style={{ height: "43px", marginTop: "4px" }}
                            >
                              <TextField
                                id={`standard-basic-runsheet ${index}`}
                                label="Duration"
                                onChange={(value) =>
                                  this.changeDuration(value.target.value, index)
                                }
                                defaultValue={this.state.items[index].duration}
                                style={{ width: "60px" }}
                              />
                            </ListItemText>
                            <TextField
                              id="outlined-basic"
                              label="Activity"
                              onChange={(value) =>
                                this.changeActivity(value.target.value, index)
                              }
                              defaultValue={this.state.items[index].activity}
                              variant="outlined"
                              multiline={true}
                              style={{ width: "100%", marginLeft: "30px" }}
                            />
                            <ListItemSecondaryAction
                              style={{ marginLeft: "20px" }}
                            >
                              <IconButton style={{ marginRight: "-15px" }}>
                                <DeleteIcon
                                  style={{ color: "rgba(0, 0, 0, 0.54)" }}
                                  onClick={() => this.removeItem(index)}
                                />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                </MuiPickersUtilsProvider>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

// Hello From Cameron Houston 19/3/2020 (We think the end of the world might be soon) Hopefully someone is reading this

//Hello It's me again. we are surviving 03/03/2021
