import React, { useState, useEffect } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { setSnackBarProps } from "../../../../actions/snackbar";
import { getAllPeople } from "../../../../actions/people";
import "../Location/locationStyles.css";
import Snacky from "../../../Shared/Snacky";
import { useLocation } from "react-router-dom";
import { checkEmail } from "../../../../utils/Validation";
import { setDialogProps } from "../../../../actions/dialog";
import InformationDialogy from "../../../Shared/InformationDialogy";
import { AgentSwitch } from "./NewContactComponents/AgentSwitch";
import AddAgentPopUp from "./AddAgentPopUp";
import { AgentAutoComplete } from "./NewContactComponents/AgentAutoComplete";
import { useAuth } from "../../../../context/auth/AuthContext";
import CurrencyDropdownMenu from "../../../CurrencyExchange/CurrencyDropdownMenu";
import { getCurrencies } from "../../../../utils/GeneralUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //marginTop: "30px"
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const StyledButton = withStyles(() => ({
  root: {
    marginLeft: "auto",
    background: "#00b2e2",
    color: "white",
    "&:hover": {
      background: "#008cb3",
    },
  },
}))(Button);

const AddContactStepper = (props) => {
  const classes = useStyles();
  const { user } = useAuth();

  const [showWarning, setShowWarning] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showAddAgentContact, setShowAddAgentContact] = useState(false);
  const [requiredInfo, setRequiredInfo] = useState(
    props.goAhead === true
      ? {
          firstName: props.goAheadContact.firstName,
          lastName: props.goAheadContact.lastName,
          emailAddress: props.goAheadContact.emailAddress,
          phoneNumber: props.goAheadContact.phoneNumber,
          hasAgent: props.goAheadContact.hasAgent,
          currency: props.goAheadContact.currency || "NZD",
        }
      : {
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          hasAgent: 0,
          currency: "NZD",
        }
  );
  const [category, setCategory] = useState(props.category);
  const [agent, setAgent] = useState(
    props.goAhead === true ? props.goAheadContact.agent : null
  );
  const [getAgents, setGetAgents] = useState(true);
  let [agentList, setAgentList] = useState([]);
  var location = useLocation();

  const changeAgent = (object, value) => {
    // setAgent(value);
    if (value !== null) {
      setAgent(value);
    }
  };

  useEffect(() => {
    if (getAgents) {
      Axios.get(`Agents/GetAgents`, {
        params: {
          organisationId:
            props.category === "Influencer" ||
            props.category === "Content Creator"
              ? 2
              : 1,
        },
      })
        .then((res) => {
          console.log(res.data);
          setAgentList(res.data);
          setGetAgents(false);
        })
        .catch((e) => {
          console.log(e);
          setGetAgents(false);
        });
    }
  });

  const handleRequiredInfoChange = (field) => (event) => {
    var value = event.target.value;
    if (field === "emailAddress") {
      setRequiredInfo({ ...requiredInfo, [field]: event.target.value.trim() });
    } else if (field === "hasAgent") {
      if (event.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
      setRequiredInfo({ ...requiredInfo, [field]: value });
    } else {
      setRequiredInfo({ ...requiredInfo, [field]: event.target.value });
    }
  };

  const saveNewAgent = (agent) => {
    console.log(agent);
    Axios.post(`Agents/PostAgent`, agent)
      .then((res) => {
        console.log(res.data);
        setAgent(res.data);
        setGetAgents(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkForDuplicates = () => {
    if (!props.allPeople) {
      return true;
    } else if (props.goAhead === true) {
      return true;
    } else {
      let duplicatesArr = [];
      props.allPeople.forEach((person) => {
        if (
          person.firstName == requiredInfo.firstName.toLowerCase() &&
          person.lastName == requiredInfo.lastName.toLowerCase()
        ) {
          duplicatesArr.push(person);
        } else if (person.emailAddress) {
          if (person.emailAddress == requiredInfo.emailAddress.toLowerCase()) {
            duplicatesArr.push(person);
          }
        } else if (person.phoneNumber) {
          if (person.phoneNumber == requiredInfo.phoneNumber) {
            duplicatesArr.push(person);
          }
        }
      });
      if (duplicatesArr.length == 0) {
        return true;
      } else {
        props.setDuplicates(duplicatesArr);
        props.setNewContact(requiredInfo);
        return false;
      }
    }
  };

  const handleSaveContact = () => {
    if (checkForDuplicates()) {
      setShowLoader(true);
      Axios.post(`/People/PostPerson?user=${user?.email}`, {
        firstName: requiredInfo.firstName,
        lastName: requiredInfo.lastName,
        emailAddress: requiredInfo.emailAddress,
        phoneNumber: requiredInfo.phoneNumber,
        fullName: requiredInfo.firstName + " " + requiredInfo.lastName,
        personCategory: category,
        currency: requiredInfo.currency || "NZD",
        organisationFk:
          props.category === "Influencer" ||
          props.category === "Content Creator"
            ? 2
            : 1,
        companyFk: category === "Client" ? props.company : null,
        agentFk: agent !== null && agent !== undefined ? agent.id : null,
      })
        .then((response) => {
          console.log("after saving new person");
          console.log(response.data);
          if (props.category == "Location Contact") {
            Axios.put(
              `/Locations/AddContactToLocation/${props.locationId}/${response.data.id}`
            )
              .then((response) => {
                console.log(response);
                props.reloadContacts();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (props.parent === "NewScheduleItem") {
            props.setGoAhead(false);
            props.setNewContact([]);
            props.getAllPeople(location.pathname.split("/")[5]);
            props.addContactAndCloseModal(response.data);
            props.reloadPersonList();
          }
          props.setSnackBarProps("success", "Created contact", true);
          setRequiredInfo({
            firstName: "",
            lastName: "",
            emailAddress: "",
            phoneNumber: "",
          });
          setShowLoader(false);
        })
        .catch((error) => {
          console.log(error);
          props.setSnackBarProps("warning", "Failed to create", true);
          setShowLoader(false);
        });
    }
  };

  useEffect(() => {
    if (props.goAhead) {
      handleSaveContact();
    }
  }, []);

  return (
    <div className={classes.root}>
      {showWarning && (
        <InformationDialogy
          setDialogProps={setDialogProps}
          buttonProps={{
            show: showWarning,
            titleText: "Invalid data",
            contentText:
              "One of the fields is incorrect. Please fix this before continuing",
            buttonColor: "primary",
            buttonText: "Ok",
          }}
          handleButton={() => setShowWarning(false)}
        />
      )}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <FormControl
            style={{
              display: props.category !== "Location Contact" ? "none" : "",
            }}
          >
            <StyledButton onClick={props.setAddExisting}>
              Add Existing
            </StyledButton>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              required
              variant="outlined"
              style={{ width: "100%" }}
              id="fname-input"
              label="First Name"
              size="small"
              value={requiredInfo.firstName}
              onChange={handleRequiredInfoChange("firstName")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              required
              id="lname-input"
              variant="outlined"
              label="Last Name"
              size="small"
              value={requiredInfo.lastName}
              onChange={handleRequiredInfoChange("lastName")}
            />
          </FormControl>
        </Grid>
        {showAddAgentContact && (
          <AddAgentPopUp
            modalState={showAddAgentContact}
            handleModal={() => {
              setShowAddAgentContact(false);
              props.addAgentPopUpClosed();
            }}
            handleSave={saveNewAgent}
            organisationId={
              props.category === "Influencer" ||
              props.category === "Content Creator"
                ? 2
                : 1
            }
            parent={"AddTalentContact"}
          />
        )}
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ display: "flex", alignItems: "center", width: "100%" }}
        >
          <CurrencyDropdownMenu
            size={"small"}
            originalCurrency={requiredInfo.currency}
            handleChangeCurrency={(currencyObj) =>
              setRequiredInfo({
                ...requiredInfo,
                currency: currencyObj.value,
              })
            }
            selectedCurrencies={getCurrencies().filter(
              (c) => c.value === "NZD"
            )}
            // selectedCurrencies={getCurrencies().filter(
            //   (c) => c.value === "NZD" || c.value === "AUD"
            // )}
          />
          <div style={{ flex: "1" }}>
            <AgentSwitch
              category={props.category}
              handleRequiredInfoChange={handleRequiredInfoChange}
              requiredInfo={requiredInfo}
            />
          </div>
        </Grid>

        {requiredInfo.hasAgent === 1 && (
          <Grid item xs={12} md={12} lg={12}>
            <AgentAutoComplete
              addAgentPopUpOpened={props.addAgentPopUpOpened}
              agent={agent}
              agentList={agentList}
              changeAgent={changeAgent}
              requiredInfo={requiredInfo}
              setShowAddAgentContact={setShowAddAgentContact}
            />
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              size="small"
              required={requiredInfo.hasAgent === 0}
              variant="outlined"
              id="phone-number-input"
              label={
                requiredInfo.hasAgent === 0
                  ? "Phone Number"
                  : "Phone Number (Optional)"
              }
              aria-describedby="Phone Number is Required"
              value={requiredInfo.phoneNumber}
              onChange={handleRequiredInfoChange("phoneNumber")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              error={
                checkEmail(requiredInfo.emailAddress) &&
                requiredInfo.hasAgent === 0
              }
              helperText={
                checkEmail(requiredInfo.emailAddress) &&
                requiredInfo.hasAgent === 0
                  ? "Not a valid email address"
                  : null
              }
              required={requiredInfo.hasAgent === 0}
              variant="outlined"
              size="small"
              id="email-input"
              label={
                requiredInfo.hasAgent === 0
                  ? "Email Address"
                  : "Email Address (Optional)"
              }
              aria-describedby="Email Address is Required"
              value={requiredInfo.emailAddress}
              onChange={handleRequiredInfoChange("emailAddress")}
            />
          </FormControl>
        </Grid>
        <FormControl style={{ marginTop: "10px", marginRight: "10px" }}>
          <CircularProgress
            size={30}
            style={showLoader ? {} : { display: "none" }}
          />
        </FormControl>
      </Grid>
      <div
        style={
          (requiredInfo.firstName != "" &&
            requiredInfo.lastName != "" &&
            requiredInfo.emailAddress !== "" &&
            requiredInfo.emailAddress !== null &&
            !checkEmail(requiredInfo.emailAddress) &&
            requiredInfo.phoneNumber != "") ||
          (requiredInfo.hasAgent === 1 &&
            agent !== null &&
            requiredInfo.firstName != "" &&
            requiredInfo.lastName != "")
            ? { display: "flex", justifyContent: "flex-end" }
            : { display: "none" }
        }
      >
        <FormControl style={{ marginTop: "10px" }}>
          <StyledButton
            onClick={() =>
              checkEmail(requiredInfo.emailAddress) &&
              requiredInfo.hasAgent === 0
                ? setShowWarning(true)
                : handleSaveContact()
            }
          >
            Submit
          </StyledButton>
        </FormControl>
      </div>
      <Snacky
        snackprops={props.snackbar}
        setSnackBarProps={props.setSnackBarProps}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  people: state.people,
  project: state.schedules.project,
  dialog: state.dialog,
});
export default connect(mapStateToProps, {
  setSnackBarProps,
  getAllPeople,
  setDialogProps,
})(AddContactStepper);
