import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSchedule,
  editProject,
  getSchedulesForTable,
  createSchedule,
  createNewSchedule,
  resetSchedule,
  editScheduleLocation,
  addScheduleLocation,
  editSchedule,
  getScheduleDates,
  changeLocation,
  deleteScheduleLocation,
  updateLocationContact,
  promoteScheduleLocation,
  cancelCatering,
  rescheduleCatering,
} from "../../../actions/schedules";
import {
  getScheduleItems,
  resetScheduleItems,
  updateScheduleItemDates,
} from "../../../actions/scheduleItems";
import { getProjects } from "../../../actions/projects";
import { setSnackBarProps } from "../../../actions/snackbar";
import { getBudget } from "../../../actions/budget";
import Loader from "../../Loader";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Paper,
  Grid,
  Fab,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import ScriptWrapper from "../ScriptWrapper";
import ScheduleTable from "../Schedule/SchedulesTable";
import ScheduleWrapper from "../Schedule/ScheduleWrapper";
import PrePostProduction from "../Schedule/PrePost/PrePostProduction";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Brightness1 from "@material-ui/icons/Brightness1";
import CheckCircle from "@material-ui/icons/CheckCircle";
import EditSchedule from "../Schedule/EditSchedule/EditSchedule";
import OutputsWrapper from "../ProjectOutputs/OutputsWrapper";
import Axios from "axios";
import Snacky from "../../Shared/Snacky";
import { getAllPeople } from "../../../actions/people";
import { isObjectEmpty } from "../../../utils/utils";
import "./ProjectHeader.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`header-tabpanel-${index}`}
      aria-labelledby={`header-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={{ padding: "15px", background: "#eee", height: "auto" }}
          p={3}
        >
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const StyledTab = withStyles((theme) => ({
  wrapper: {
    flexDirection: "row-reverse",
    padding: "0px 0px 20px 0px",
  },
}))(Tab);

const StyledTabLong = withStyles((theme) => ({
  root: {
    minWidth: "270px",
  },
  wrapper: {
    flexDirection: "row-reverse",
    height: "36.8px",
    padding: "0px 0px 20px 0px",
  },
}))(Tab);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    height: "48px",
  },
  scroller: {
    flexGrow: "0",
    overflow: "hidden",
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
  hidden: {
    padding: 0,
    display: "none",
  },
  visible: {
    padding: 0,
    height: "auto",
    width: "auto",
    background: "#eee",
  },
  autoHeight: {
    height: "auto",
  },
  noMargin: {
    padding: "0px 0px 0px 0px",
    marginLeft: "-20px",
    marginTop: "-50px",
  },
  addFab: {
    position: "absolute",
    top: theme.spacing(12),
    right: "5px",
    zIndex: "999",
    background: "#00b2e2",
  },
  // stickyAppBar: {
  //   position: "fixed",
  //   top: 48,
  //   width: "100%",
  // },
  headerBuffer: {
    // paddingTop: 100,
  },
  headerBuffer2: {
    // paddingTop: 140,
  },
}));

const StyledFab = withStyles((theme) => ({
  root: {
    position: "absolute",
    top: 80,
    right: "5px",
    zIndex: "1200", //999
    background: "#000000",
    color: "white",
    "&:hover": {
      background: "#757575",
    },
  },
}))(Fab);

const StyledFabBack = withStyles((theme) => ({
  root: {
    position: "absolute",
    top: 80,
    left: "5px",
    zIndex: "1200", //999
    background: "#000000",
    color: "white",
    "&:hover": {
      background: "#757575",
    },
  },
}))(Fab);

const ProjectHeader = ({
  project,
  schedule,
  budget,
  scheduleItems,
  snackbar,
  resetSchedule,
  getSchedule,
  getAllPeople,
  getScheduleDates,
  setSnackBarProps,
  loadingSchedule,
  editScheduleLocation,
  addScheduleLocation,
  deleteScheduleLocation,
  editSchedule,
  createSchedule,
  createNewSchedule,
  changeLocation,
  scheduleDates,
  getSchedulesForTable,
  getScheduleItems,
  updateLocationContact,
  editProject,
  getBudget,
  updateScheduleItemDates,
  resetScheduleItems,
  promoteScheduleLocation,
  schedulesForTable,
  cancelCatering,
  rescheduleCatering,
  projectList,
  projects,
  getProjects,
}) => {
  const classes = useStyles();
  const [creatingSchedule, setCreatingSchedule] = useState(false);
  const [showInitialsDialog, setShowInitialsDialog] = useState(false);
  const [initials, setInitials] = useState();
  const [epLocation, setEPLocation] = useState();
  //----------------------------------- Deposits ------------------------------------//
  const [withinSevenDays, setWithinSevenDays] = useState(false);

  // ----------------------------------- Router Props -------------------------------//
  var history = useHistory();
  var location = useLocation();
  const [showScheduleTable, setShowScheduleTable] = useState(
    location.pathname.split("/").length === 4
  );
  const [scheduleId, setScheduleId] = useState(location.pathname.split("/")[5]);
  const [value, setValue] = useState(parseInt(location.pathname.split("/")[4]));

  //-------Lock all but outputs --------//
  const [outputsOverdue, setOutputsOverdue] = useState(true);

  useEffect(() => {
    setValue(parseInt(location.pathname.split("/")[4]));
  }, [location.pathname.split("/")[4]]);

  useEffect(() => {
    if (schedule.id != undefined && location.pathname.split("/").length == 5) {
      history.push(location.pathname + "/" + schedule.id);
    }
  }, [schedule.id]);

  useEffect(() => {
    if (value != 3 && Object.entries(schedule).length !== 0) {
      resetSchedule();
      resetScheduleItems();
    }
  }, [value]);

  useEffect(() => {
    if (location.pathname.split("/").length > 5) {
      setShowScheduleTable(false);
      setCreatingSchedule(false);
      if (loadingSchedule || (schedule && !!location.pathname.split("/")[5] && schedule?.id != location.pathname.split("/")[5])) {
        getSchedule(location.pathname.split("/")[5]);
        getAllPeople(location.pathname.split("/")[5]);
        getScheduleItems(location.pathname.split("/")[5]);
      }
      setValue(parseInt(location.pathname.split("/")[4]));
    } else {
      setShowScheduleTable(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    Axios.get(`ExpressPayments/GetLastExpressPayment`)
      .then((res) => {
        setEPLocation(res.data.locationThreshold);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const tabChange = (event, newValue) => {
    if (location.pathname.split("/").length === 7) {
      resetSchedule();
    }
    history.push(`/project/home/${project.id}/${newValue}`);
    setCreatingSchedule(false);
    setShowScheduleTable(true);
    setScheduleId(null);
  };

  const saveCompleterOfOutputs = () => {
    editProject("outputStage", "C", { ...project, outputStage: "C" });
    Axios.put(`/Projects/SaveProjectOutputsComplete/${project.id}`, {
      outputsUserInitials: initials,
    })
      .then((response) => {
        console.log("Initials Saved");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleNewSchedule = () => {
    if (creatingSchedule) {
      setCreatingSchedule(false);
    } else {
      setCreatingSchedule(true);
    }
  };

  const onToggleScheduleTable = () => {
    if (showScheduleTable) {
      setShowScheduleTable(false);
    } else {
      setShowScheduleTable(true);
      setScheduleId(null);
      resetSchedule();
      resetScheduleItems();
      history.push(`/project/home/${project.id}/3`);
    }
  };

  const submitInitials = () => {
    if (initials) {
      setShowInitialsDialog(false);
      editProject("outputStage", "C", { ...project, outputStage: "C" });
      saveCompleterOfOutputs();
      setSnackBarProps("success", "Intials recorded", true);
      setInitials();
    } else {
      // Todo - snackbars
      setSnackBarProps("info", "Please enter intials", true);
    }
  };

  const returnNextStage = (progress) => {
    switch (progress) {
      case "IP":
        return "C";
        break;
      case "C":
      case null:
      case undefined:
        return "IP";
        break;
    }
  };
  const toggleTrafficLight = (stage) => {
    let nextStage = returnNextStage(project[stage]);
    if (stage == "outputStage" && nextStage == "C") {
      setShowInitialsDialog(true);
    } else {
      editProject([stage], nextStage, { ...project, [stage]: nextStage });
    }
  };

  const returnIcon = (project, stage) => {
    let margin =
      stage == "outputStage" || stage == "scheduleStage" ? "5%" : "2.767%";
    if (
      project[stage] === "IP" ||
      project[stage] === null ||
      project[stage] === undefined
    ) {
      return (
        <Brightness1
          onClick={() => toggleTrafficLight(stage)}
          style={{
            color: "#ff8c3b",
            marginTop: margin,
            marginLeft: margin,
          }}
        />
      );
    } else {
      return (
        <CheckCircle
          style={{
            color: "#4dfa59",
            marginTop: margin,
            marginLeft: margin,
          }}
          onClick={() => toggleTrafficLight(stage)}
        />
      );
    }
  };

  const checkDeposit = () => {
    return (
      project.paymentType === "Deposit" &&
      project.paymentStatus !== "PAID" &&
      project.paymentStatus !== "VOIDED" &&
      project.depositDismissed === 0 &&
      withinSevenDays
    );
  };

  useEffect(() => {
    console.log("Store project changed");
    if (
      !isObjectEmpty(project) &&
      project.expectedShootDate !== null &&
      project.expectedShootDate !== undefined
    ) {
      var cutOffDate = new Date(project.expectedShootDate);
      cutOffDate.setDate(cutOffDate.getDate() - 7);
      // console.log("new Date()")
      // console.log(new Date())
      // console.log("cutOffDate")
      // console.log(cutOffDate)
      if (new Date() >= cutOffDate) {
        setWithinSevenDays(true);
      } else {
        setWithinSevenDays(false);
      }
    } else {
      setWithinSevenDays(false);
    }
  }, [project]);

  useEffect(() => {
    if (projectList.length === 0) {
      console.log("ProjectHeader calling getProjects")
      getProjects();
    } else {
      var p = projectList.find((p) => p.id === project.id);
      if (p !== undefined) {
        if (p.daysOverdue > 0) {
          setOutputsOverdue(true);
        } else {
          setOutputsOverdue(false);
        }
      } else {
        setOutputsOverdue(false);
      }
    }
  }, [projectList]);

  const renderTabByProjectStatus = () => {
    if (project?.organisationFk === 1) {
      return projects?.lockProductionCore
        ? "hidden"
        : project.outputStage === "C"
        ? ""
        : "hidden";
    }

    return "";
  };

  return (
    <Paper classes={{ root: classes.root }}>
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      <Dialog
        open={showInitialsDialog}
        onClose={() => setShowInitialsDialog(false)}
        aria-labelledby="intitials-dialog-label"
        aria-describedby="initials-dialog"
      >
        <DialogTitle id="initials-dialog-title">
          {"Please enter your initials to confirm outputs complete"}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <TextField
            value={initials}
            variant="outlined"
            onChange={(e) => setInitials(e.target.value)}
          ></TextField>
          <Button onClick={submitInitials} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        {checkDeposit() && value === 3 && (
          <Typography variant="h5" align="center" className="awaiting-deposit">
            {`Awaiting Deposit (Discuss with accounts before shooting)`}
          </Typography>
        )}
        <Tabs
          classes={{ root: classes.root, scroller: classes.scroller }}
          value={value}
          onChange={(e, newValue) => {
            tabChange(e, newValue);
          }}
          variant={"scrollable"}
          scrollButtons={"on"}
        >
          <StyledTab
            style={{ display: project.organisationFk !== 1 ? "none" : "" }}
            icon={returnIcon(project, "outputStage")}
            label="Outputs"
          ></StyledTab>
          <StyledTabLong
            style={{
              visibility: renderTabByProjectStatus(),
              display: project.organisationFk !== 1 ? "none" : "",
            }}
            icon={returnIcon(project, "scriptStage")}
            label={"Scripts and Story Board"}
          ></StyledTabLong>

          <StyledTabLong
            label={`${
              project.organisationFk === 1 ? "Pre/Post" : "Influencers"
            }`}
            style={{ visibility: renderTabByProjectStatus() }}
            icon={returnIcon(project, "prePostStage")}
          ></StyledTabLong>
          <StyledTab
            style={{ visibility: renderTabByProjectStatus() }}
            icon={returnIcon(project, "scheduleStage")}
            label="Schedule"
          ></StyledTab>
        </Tabs>
      </div>
      {project.organisationFk === 1 && (
        <TabPanel value={value} index={0} className={classes.headerBuffer}>
          {projects?.lockProductionCore && outputsOverdue && (
            <Typography
              variant="h5"
              align="center"
              className="overdue-outputs-header"
            >
              {`Please deliver all overdue outputs`}
            </Typography>
          )}
          <OutputsWrapper project={project} getProjects={getProjects} />
        </TabPanel>
      )}
      {project.organisationFk === 1 && (
        <TabPanel value={value} index={1} className={classes.headerBuffer}>
          <ScriptWrapper
            url={project.projectScript}
            projectId={project.id}
            project={project}
          />
        </TabPanel>
      )}
      <TabPanel value={value} index={2} className={classes.headerBuffer}>
        <PrePostProduction
          projectId={project.id}
          budget={budget}
          getBudget={getBudget}
          project={project}
          scheduleItems={scheduleItems}
        />
      </TabPanel>
      <TabPanel
        value={value}
        index={3}
        className={
          checkDeposit()
            ? (classes.autoHeight, classes.headerBuffer2)
            : (classes.autoHeight, classes.headerBuffer)
        }
      >
        <div>
          <div
            id="scheduleTable-panel"
            className={creatingSchedule ? classes.hidden : classes.visible}
          >
            <div
              className={!showScheduleTable ? classes.hidden : classes.visible}
            >
              {project.isArchived !== 1 && (
                <StyledFab
                  aria-label="add"
                  onClick={() => {
                    toggleNewSchedule();
                    createSchedule(project.id);
                  }}
                >
                  <AddIcon />
                </StyledFab>
              )}
              <ScheduleTable />
            </div>
          </div>
          <div>
            <div
              className={showScheduleTable ? classes.hidden : classes.visible}
            >
              {!loadingSchedule ? (
                <Grid container>
                  <Grid item xs={12} md={3} lg={3}>
                    <Card>
                      <EditSchedule
                        promoteScheduleLocation={promoteScheduleLocation}
                        addScheduleLocation={addScheduleLocation}
                        changeLocation={changeLocation}
                        deleteScheduleLocation={deleteScheduleLocation}
                        editSchedule={editSchedule}
                        editScheduleLocation={editScheduleLocation}
                        epLocation={epLocation}
                        getBudget={getBudget}
                        getSchedule={getSchedule}
                        getScheduleDates={getScheduleDates}
                        loadingSchedule={loadingSchedule}
                        project={project}
                        projectId={project.id}
                        schedule={schedule}
                        scheduleDates={scheduleDates}
                        setSnackBarProps={setSnackBarProps}
                        snackbar={snackbar}
                        updateLocationContact={updateLocationContact}
                        updateScheduleItemDates={updateScheduleItemDates}
                        cancelCatering={cancelCatering}
                        rescheduleCatering={rescheduleCatering}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={9} lg={9}>
                    <ScheduleWrapper
                      budget={budget}
                      getBudget={getBudget}
                      loadingSchedule={loadingSchedule}
                      project={project}
                      schedule={schedule}
                      scheduleId={schedule.id}
                      scheduleItems={scheduleItems}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Loader />
              )}
            </div>
            <div
              id="newSchedule-panel"
              className={creatingSchedule ? classes.visible : classes.hidden}
            >
              <StyledFabBack
                aria-label="back"
                onClick={() => {
                  resetSchedule();
                  resetScheduleItems();
                  toggleNewSchedule();
                }}
              >
                <ArrowBackIcon />
              </StyledFabBack>
              <Grid>
                <Grid item xs={12} md={9} lg={3}>
                  <Card>
                    {creatingSchedule && (
                      <EditSchedule
                        promoteScheduleLocation={promoteScheduleLocation}
                        addScheduleLocation={addScheduleLocation}
                        changeLocation={changeLocation}
                        createNewSchedule={createNewSchedule}
                        creatingSchedule={creatingSchedule}
                        deleteScheduleLocation={deleteScheduleLocation}
                        editSchedule={editSchedule}
                        editScheduleLocation={editScheduleLocation}
                        epLocation={epLocation}
                        getSchedule={getSchedule}
                        getScheduleDates={getScheduleDates}
                        loadingSchedule={false}
                        projectId={project.id}
                        project={project}
                        schedule={schedule}
                        scheduleDates={scheduleDates}
                        setCreatingSchedule={setCreatingSchedule}
                        setShowScheduleTable={setShowScheduleTable}
                        setSnackBarProps={setSnackBarProps}
                        snackbar={snackbar}
                        updateLocationContact={updateLocationContact}
                        updateScheduleItemDates={updateScheduleItemDates}
                        cancelCatering={cancelCatering}
                        rescheduleCatering={rescheduleCatering}
                      />
                    )}
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </TabPanel>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  project: state.schedules.project,
  loadingProject: state.schedules.loadingProject,
  schedule: state.schedules.schedule,
  loadingSchedule: state.schedules.loadingSchedule,
  scheduleDates: state.schedules.scheduleDates,
  scheduleItems: state.scheduleItems,
  snackbar: state.snackbar,
  budget: state.budget,
  schedulesForTable: state.schedules.schedulesForTable,
  projectList: state.projects.projects,
  projects: state.projects,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  getSchedule,
  getAllPeople,
  updateScheduleItemDates,
  getScheduleItems,
  getScheduleDates,
  getBudget,
  getSchedulesForTable,
  resetSchedule,
  resetScheduleItems,
  editScheduleLocation,
  addScheduleLocation,
  deleteScheduleLocation,
  promoteScheduleLocation,
  changeLocation,
  editSchedule,
  createSchedule,
  createNewSchedule,
  updateLocationContact,
  setSnackBarProps,
  editProject,
  cancelCatering,
  rescheduleCatering,
  getProjects,
})(ProjectHeader);
