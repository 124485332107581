import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { 
  Edit as EditIcon,
  PictureAsPdf as PictureAsPdfIcon,
  ReportProblemOutlined as ReportProblemOutlinedIcon,
  History as HistoryIcon
} from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GeneralDocumentViewer from "../../../Shared/Dialog/GeneralDocumentViewer";
import EditInvoiceReferenceModal from "./EditInvoiceReferenceModal";
import { currencyFormat, isEmpty } from "../../../../utils/GeneralUtils";
import Axios from "axios";
import MediaInvoiceHistoryDialog from "./MediaInvoiceHistoryDialog";
import { useAuth } from "../../../../context/auth/AuthContext";

const MediaCampaignInvoiceTable = ({
  mediaInvoices,
  handleToggleItem,
  handleToggleAll,
  selectedItems,
  selectAll,
  organisationId,
  updateReferenceMediaInvoice,
  setSnackBarProps,
  company
}) => {
  const { user } = useAuth();
  const [pdfUrl, setPdfUrl] = useState("");
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(false);
  const [showReferenceDialog, setShowReferenceDialog] = useState(false);
  const [showInvoiceHistory, setShowInvoiceHistory] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [saving, setSaving] = useState(false);

  const handleOpenPdf = (e, xeroInvoiceId) => {
    setPdfUrl(
      `/invoice/GetXeroInvoiceAsPdf/${xeroInvoiceId}/${organisationId}`
    );
    setShowDocumentViewer(true);
  };

  const updateInvoice = (invoiceId, reference) => {
    try {
      if (invoiceId) {
        setSaving(true);
        Axios.post(
          `Invoice/UpdateInvoiceReference/${invoiceId}`, null, {
            params: {
              reference: reference || "",
              user: user.email
            }
          })
          .then((res) => {
            updateReferenceMediaInvoice(invoiceId, reference);
            setSnackBarProps({
              open: true,
              severity: "success",
              text: "The reference has been updated.",
            });
            handleCloseReferenceDialog();
          })
          .catch((e) => {
            setSnackBarProps({
              open: true,
              severity: "warning",
              text: "Failed to update the reference",
            });
          })
          .finally(() => {
            setSaving(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReferenceDialog = (invoice) => {
    setSelectedInvoice(invoice);
    setShowReferenceDialog(true);
  };

  const handleCloseReferenceDialog = () => {
    setShowReferenceDialog(false);
  };

  const handleSaveReference = async (reference) => {
    updateInvoice(selectedInvoice.xeroInvoiceId, reference);
  };

  const isPoRequired = (mediaInvoice) => {
    return isEmpty(mediaInvoice.reference) && company?.forcedPo
  }

  const isMissingAnyReference = () => {
    return mediaInvoices.some((mediaInvoice) => isPoRequired(mediaInvoice));
  }

  useEffect(() => {
    if(selectedInvoiceId){
      setShowInvoiceHistory(true);
    }
  }, [selectedInvoiceId])

  return (
    <div>
      {showDocumentViewer && pdfUrl && (
        <GeneralDocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setPdfUrl("");
            setShowDocumentViewer(false);
          }}
          headerColor="#000"
          docType={"PDF"}
          urlFile={pdfUrl}
        />
      )}
      {showReferenceDialog && (
        <EditInvoiceReferenceModal
          invoice={selectedInvoice}
          open={setShowReferenceDialog}
          handleClose={handleCloseReferenceDialog}
          saveReference={handleSaveReference}
          saving={saving}
        />
      )}
      {showInvoiceHistory && (
        <MediaInvoiceHistoryDialog
          handleClose={() => {
            setShowInvoiceHistory(false);
            setSelectedInvoiceId(null);
          }}
          mediaInvoiceId={selectedInvoiceId}
          open={showInvoiceHistory}
        />
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 30 }}>
              <Checkbox
                checked={selectAll}
                onChange={handleToggleAll}
                color="primary"
                disabled={isMissingAnyReference()}
              />
            </TableCell>
            <TableCell>Invoice Number</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Subtotal</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mediaInvoices.map((mediaInvoice) => (
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectedItems.includes(mediaInvoice.id)}
                  onChange={() => handleToggleItem(mediaInvoice.id)}
                  color="primary"
                  disabled={isPoRequired(mediaInvoice)}
                />
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {mediaInvoice.invoiceNumber}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {moment(mediaInvoice.date).format("DD/MM/YYYY")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {isPoRequired(mediaInvoice) ? (
                    <Tooltip title="Missing PO Number">
                      <IconButton onClick={(e) => handleReferenceDialog(mediaInvoice)}>
                        <ReportProblemOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  ) : mediaInvoice.reference !== "" ? (
                    mediaInvoice.reference
                  ) : (
                    "-"
                  )}
                  {!isPoRequired(mediaInvoice) && (mediaInvoice.status === "DRAFT" ||
                  mediaInvoice.status === "SUBMITTED") ? (
                    <EditIcon
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        marginLeft: 10,
                      }}
                      onClick={(e) => {
                        handleReferenceDialog(mediaInvoice);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{mediaInvoice.status}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {currencyFormat(Number(mediaInvoice.subtotal))}
                </Typography>
              </TableCell>
              <TableCell>
                <div>
                  {mediaInvoice.sentToContact === 1 ? (
                    <div className="chip-short chip green-chip">
                      <Typography variant="caption">Sent</Typography>
                    </div>
                  ) : (
                    <div className="chip-short chip orange-chip">
                      <Typography variant="caption">Not Sent</Typography>
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell>
                {mediaInvoice.xeroInvoiceId && (
                  <div>
                    <IconButton
                      aria-label="pdf"
                      size="small"
                      onClick={(e) =>
                        handleOpenPdf(e, mediaInvoice.xeroInvoiceId)
                      }
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                    <IconButton
                      aria-label="history"
                      size="small"
                      onClick={(e) => { setSelectedInvoiceId(mediaInvoice.id) }}
                    >
                      <HistoryIcon />
                    </IconButton>
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default MediaCampaignInvoiceTable;
