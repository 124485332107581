import { InputAdornment, Menu, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { currencyFormat } from "../../../utils/GeneralUtils";
import Snacky from "../../Shared/Snacky";

const MediaSpendBudgetAllocationInput = ({
  date,
  mediaSpend,
  handleUpdateAllocation,
  isClientView,
  currency = "NZD"
}) => {
  const [contextMenu, setContextMenu] = useState(null);
  const inputRef = useRef(null);
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleCopyToClipboard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard
      .writeText(mediaSpend?.code)
      .then(() => {
        setSnackBarProps({
          open: true,
          severity: "info",
          text: "Copied to Clipboard",
        })
        handleClose()
      })
      .catch(() => {
        handleClose()
      });
  }

  return (
    <div
      className={`media-spend-budget-allocation-input-root  ${
        mediaSpend?.budget?.hasBeenInvoiced === 0
          ? "media-spend-budget-allocation-input-invoice-pending"
          : ""
      } ${!isClientView ? "media-spend-budget-allocation-input-supplier-cost-view" : ""}`}
      onClick={(e) => handleUpdateAllocation(date, mediaSpend)}
      ref={inputRef}
      onContextMenu={handleContextMenu}
    >
      <Typography>
        {isClientView
          ? mediaSpend?.total
            ? currencyFormat(Number(mediaSpend?.total), currency)
            : "-"
          : mediaSpend?.totalWithoutCommission
          ? currencyFormat(Number(mediaSpend?.totalWithoutCommission), currency)
          : "-"}
      </Typography>
      <Snacky
          snackprops={snackBarProps}
          setSnackBarProps={setSnackBarProps}
        />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {mediaSpend?.code ? (
          <MenuItem onClick={handleCopyToClipboard}>Copy to clipboard</MenuItem>
        ) : (
          <MenuItem onClick={handleClose}>No code generated</MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MediaSpendBudgetAllocationInput;
