import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import ProcessingSpinner from "../../Shared/Spinner/ProcessingSpinner";
import "./sendPurchaseOrderDialog.scss";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import { useAuth } from "../../../context/auth/AuthContext";
import { currencyFormat, isEmpty } from "../../../utils/GeneralUtils";
const SendPurchaseOrderDialog = ({
  open,
  handleClose,
  purchaseOrder,
  setSnackBarProps,
}) => {
  const { user } = useAuth();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [emailAddress, setEmailAddress] = useState(
    purchaseOrder?.xeroContactEmail || ""
  );
  const [loadingPdf, setLoadingPdf] = useState(true);
  const [loadingEmailTemplate, setLoadingEmailTemplate] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const handlePdfLoaded = () => {
    setLoadingPdf(false);
  };

  useEffect(() => {
    getPurchaseOrderEmailTemplate();
  }, []);

  const getPurchaseOrderEmailTemplate = () => {
    setLoadingEmailTemplate(true);
    axios
      .get(`PurchaseOrder/GetPurchaseOrderEmailTemplate`)
      .then(({ data }) => {
        if (data) {
          const { template } = data;
          setEmailTemplate(
            template
              ?.replace("{SupplierName}", purchaseOrder.xeroContactName)
              ?.replaceAll(
                "{PurchaseOrderNumber}",
                purchaseOrder.purchaseOrderNumber
              )
          );
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoadingEmailTemplate(false);
      });
  };

  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleSendEmail = () => {
    setIsSendingEmail(true);
    axios
      .post(
        `PurchaseOrder/SendPurchaseOrderEmail/${purchaseOrder.id}/${emailAddress}`,
        null,
        {
          params: {
            userEmail: user.email,
          },
        }
      )
      .then(({ data }) => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! The email has been sent.",
        });
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to send email.",
        });
      })
      .finally(() => {
        setIsSendingEmail(false);
      });
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
        <div>
          <AppBar position="static" style={{ background: "#000000" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Purchase Order
              </Typography>
              <IconButton onClick={handleClose} color="inherit">
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        <div className="send-po-dialog-body-root">
          {isSendingEmail ? (
            <ProcessingSpinner duration={30} unitTime={"seconds"} />
          ) : (
            <div className="send-po-dialog-body-container">
              <div className="send-po-dialog-iframe-container">
                {purchaseOrder ? (
                  <>
                    {loadingPdf && (
                      <div className="send-po-dialog-skeleton-container">
                        {[...Array(7)].map((_, index) => (
                          <Skeleton height={70} key={index} />
                        ))}
                      </div>
                    )}

                    <iframe
                      title={`Purchase Order - ${
                        purchaseOrder?.purchaseOrderNumber || "Loading..."
                      }`}
                      src={`/purchaseOrder/GetPurchaseOrderPdfByNumber/${purchaseOrder.purchaseOrderNumber}`}
                      className={"document-image"}
                      width={"100%"}
                      height={"100%"}
                      onLoad={handlePdfLoaded}
                      style={{
                        border: "1px solid #fff",
                        display: loadingPdf ? "none" : "block",
                      }}
                      allow="clipboard-read; clipboard-write"
                    ></iframe>
                  </>
                ) : (
                  <div className="send-po-dialog-skeleton-container">
                    {[...Array(7)].map((_, index) => (
                      <Skeleton height={70} key={index} />
                    ))}
                  </div>
                )}
              </div>
              <div className="send-po-dialog-supplier-details-wrapper">
                <div className="send-po-dialog-supplier-details">
                  <Typography className="send-po-dialog-heading-container">
                    <strong>Supplier Details</strong>
                  </Typography>

                  <Typography variant="caption">
                    <strong>PO Number:</strong>{" "}
                    {purchaseOrder?.purchaseOrderNumber}
                  </Typography>
                  <Typography variant="caption">
                    <strong>Name:</strong> {purchaseOrder?.xeroContactName}
                  </Typography>
                  <Typography variant="caption">
                    <strong>Service:</strong> {purchaseOrder?.serviceName}
                  </Typography>
                  <Typography variant="caption">
                    <strong>Total:</strong>{" "}
                    {currencyFormat(
                      purchaseOrder?.total,
                      purchaseOrder.currency
                    )}
                  </Typography>
                </div>
                <div className="send-po-dialog-supplier-details">
                  <Typography className="send-po-dialog-heading-container">
                    <strong>Send Purchase Order to:</strong>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    size="small"
                    value={emailAddress}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="send-po-dialog-supplier-details">
                  <Typography className="send-po-dialog-heading-container">
                    <strong>Email Preview</strong>
                  </Typography>
                  {loadingEmailTemplate && <Skeleton height={200} />}
                  <div className="send-po-dialog-email-preview-container">
                    {emailTemplate && (
                      <Typography variant="caption">
                        <span
                          dangerouslySetInnerHTML={{ __html: emailTemplate }}
                        />
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="outreach-dialog-footer-container">
          <Button
            size="small"
            onClick={handleClose}
            variant="outlined"
            className="default-button-grey"
          >
            {" "}
            Cancel
          </Button>

          <Button
            size="small"
            className={
              isEmpty(emailAddress)
                ? "default-disabled-button"
                : `default-button`
            }
            variant="contained"
            onClick={handleSendEmail}
            disabled={isSendingEmail || isEmpty(emailAddress)}
          >
            Send Now
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default SendPurchaseOrderDialog;
