import React from "react";
import MediaReconciliationSheetActions from "../ReconRecords/MediaReconciliationSheetActions";
import "./reconcileMediaUndersAndOvers.scss";
import { Typography } from "@material-ui/core";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import ReconcileMediaUndersAndOversTable from "./ReconcileMediaUndersAndOversTable";
const ReconcileMediaUndersAndOvers = ({
  reconLines,
  payable,
  handleUpdateSelectedReconLines,
  handleRemoveSelectedReconLine,
}) => {
  return (
    <div className="reconcile-media-unders-and-overs-root">
      <div className="reconcile-media-unders-and-overs-header">
        <div className="reconciliation-totals-wrapper">
          <Typography variant="caption" className="reconciliation-totals-label">
            Bill Total:
          </Typography>
          <div className="reconciliation-totals-amount-container">
            <Typography variant="caption">
              {currencyFormat(payable.subtotal)}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <ReconcileMediaUndersAndOversTable
          reconLines={reconLines}
          handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
          handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
          payable={payable}
        />
      </div>
    </div>
  );
};

export default ReconcileMediaUndersAndOvers;
