import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  AppBar,
  Toolbar,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Button,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";

const headCells = [
  {
    id: "openColapse",
    align: "left",
    disablePadding: false,
    label: "",
    width: 50,
  },
  {
    id: "projectName",
    align: "left",
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "projectTag",
    align: "left",
    disablePadding: false,
    label: "Code",
  },
  {
    id: "customerName",
    align: "left",
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "nextDeadline",
    align: "left",
    disablePadding: false,
    label: "5:00pm Deadline",
  },
  {
    id: "tMinus",
    align: "left",
    disablePadding: false,
    label: "Days to Deadline",
  },
];

//------------------------ Sorting ---------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    // fontSize: 14,
    // maxWidth: "50px",
    // height: "50px",
    // overflow: "hidden"
    padding: "20px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100px",
  },
  root: {
    padding: "10px 15px 10px 15px",
    maxHeight: "50px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const HomeTableHead = ({
  classes,
  order,
  orderBy,
  onRequestSort,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            style={{ width: headCell.width }}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default HomeTableHead;
