import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Delete } from "@material-ui/icons";

import LocationStepper from "./LocationStepper";

import { Close, SentimentSatisfiedAltSharp } from "@material-ui/icons";

import "./locationStyles.css";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "80vH",
    width: "80vW",
    overflow: "auto",
  },
}));

function NewLocationPopUp(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = useState(false);
  //These images are just for the previews. By putting them here we overcome the rerendering
  //browser security issue.
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState(0);

  const handleImages = (fileArray) => {
    if (fileArray.length > 0) {
      setFiles(fileArray);
    }
  };

  const handleStep = (stepNumber) => {
    setStep(stepNumber);
  };

  const StyledDelete = withStyles((theme) => ({
    root: {
      position: "absolute",
      right: "2px",
      color: "#c8c8c8",
      cursor: "pointer",
    },
  }))(Delete);

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    marginTop: "-3%",
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    position: "relative",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <StyledDelete
          onClick={() => {
            handleRemoveFile(file);
          }}
        />
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  const handleRemoveFile = (file) => {
    setFiles(files.filter((item) => item !== file));
  };

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.modalState}
        onClose={props.handleModal}
        className={classes.modal}
      >
        <div>
          <AppBar position="static" style={{ background: "#000000" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Create New Location
              </Typography>
              <IconButton
                aria-label="Shrink Budget Table"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={props.handleModal}
                style={{color: "#ffffff" }}
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.paper}>
            <LocationStepper
              reloadLocations={props.reloadLocations}
              setLocation={props.setLocation}
              passImagesUp={handleImages}
              passStepUp={handleStep}
              closeModal={props.handleModal}
            />
            <aside style={{ display: step === 0 ? "" : "none" }}>
              {thumbs}
            </aside>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NewLocationPopUp;
