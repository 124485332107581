import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  ButtonGroup,
  Grid,
  Grow,
  Button,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { getCurrentTime } from "../../../../utils/TimeHelpers";
import { contains } from "../../../../utils/utils";
import { connect } from "react-redux";
import { editScheduleItem, cancelInvoice } from "../../../../actions/scheduleItems";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";
import ConfirmationDialogActions from "../../../Shared/ConfirmationDialogActions";

const changeButton = (contractStatus) => {
  switch (contractStatus) {
    case "Disabled":
      return { color: "#c8c8c8", options: ["Send"] };
    case "Send":
      return { color: "#3f51BF", options: ["Send"] };
      break;
    case "Pending":
      return {
        color: "#FFA500",
        options: ["Pending", "Cancel Contract", "Resend"],
      };
      break;
    case "Signed":
      return {
        color: "#F987C5",
        options: ["Signed", "Cancel Contract", "Send for Invoicing"],
      };
      break;
    case "Declined":
      return {
        color: "#FF0000",
        options: ["Declined", "Cancel Contract"],
      };
      break;
    case "Invoiced":
      return { color: "#008000", options: ["Invoiced"] };
      break;
    case "Sent":
      return { color: "#008000", options: ["Sent"] };
    case "InvoicePending":
      return { color: "#FFA500", options: ["Invoice Pending", "Cancel Invoice"] };
      break;
    default:
      return { color: "#c8c8c8", options: [] };
      break;
  }
};

const PrePostContractButton = (props) => {
  const { user } = useAuth();
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [person, setPerson] = useState(props.si?.personFkNavigation);
  const [buttonProps, setButtonProps] = useState(
    changeButton(props.si.contractStatus)
  );
  const [showConfirmInternal, setShowConfirmInternal] = useState(false);

  const anchorRef = useRef(null);
  let si = props.si;
  useEffect(() => {
    setButtonProps(changeButton(si.contractStatus));
  }, [si.contractStatus]);

  console.log("si", si)

  const handleClick = () => {
    if (si.contractStatus === "Disabled") {
      console.log("This button is disabled");
    } else {
      if (buttonProps.options[selectedIndex] === "Send") {
        if (props.providerType === "Talent" || props.providerType === "Influencer" || props.providerType === "Content Creator") {
          if (
            si.talentContractStatus === "Complete" ||
            si.talentContractType == "BuyOut"
          ) {
            props.handleOnClick("Pending", si);
            props.editScheduleItem(
              si,
              props.providerType.toLowerCase(),
              "contractStatus",
              "Pending",
              1, user?.email
            );
            handleSendContract();
          } else {
            console.log(si);
          }
        } else {
          if (si.category.trim() == "Unpaid") {
            props.handleOnClick("Sent", si);
            props.editScheduleItem(
              si,
              props.providerType.toLowerCase(),
              "contractStatus",
              "Sent",
              1, user?.email
            );
            handleSendContract();
          } else {
            props.handleOnClick("Pending", si);
            props.editScheduleItem(
              si,
              props.providerType.toLowerCase(),
              "contractStatus",
              "Pending",
              1, user?.email
            );
            handleSendContract();
          }
        }
      }
    }
  };
  const handleMenuItemClick = (event, index) => {
    if (buttonProps.options[index] === "Cancel Contract") {
      axios
        .put(`ScheduleItems/UpdateContractStatus/${si.id}`, {
          id: si.id,
          contractStatus: "Send",
        })
        .then((res) => {
          if (si.contractStatus === "Pending") {
            props.handleOnClick("Send", si);
            props.editScheduleItem(
              si,
              props.providerType.toLowerCase(),
              "contractStatus",
              "Send",
              1, user?.email
            );
            handleCancelContract();
          } else if (si.contractStatus === "Signed") {
            props.handleOnClick("Send", si);
            handleCancelSignedContract();
          }
          if (props.providerType === "Talent" || props.providerType === "Influencer" || props.providerType === "Content Creator") {
            props.handleOnClick("Send", si);
            props.editScheduleItem(
              si,
              props.providerType.toLowerCase(),
              "contractStatus",
              "Send",
              1, user?.email
            );
            // Where is the logic for handling cancellation of talent contracts?
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => { });
    } else if (buttonProps.options[index] === "Send for Invoicing") {
      // props.handleOnClick("Invoiced", si);
      props.handleOnClick("InvoicePending", si);
      props.editScheduleItem(
        si,
        props.providerType.toLowerCase(),
        "contractStatus",
        "InvoicePending",
        1, user?.email
      );
      sendInvoice();
    } else if (buttonProps.options[index] === "Resend") {
      var url = "";
      if (si.personFkNavigation.personCategory === "Talent" || si.personFkNavigation.personCategory === "Influencer" || si.personFkNavigation.personCategory === "Content Creator") {
        url = `ResendMail/ResendTalentContract/${si.id}`;
      } else {
        url = `ResendMail/ResendContractorContract/${si.id}`;
      }
      axios
        .post(url, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email
        })
        .then((res) => {
          console.log(res);
          props.handleOnClick("Pending", si);
          props.editScheduleItem(
            si,
            props.providerType.toLowerCase(),
            "contractStatus",
            "Pending",
            1, user?.email
          );
          handleSendContract();
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (buttonProps.options[index] === "Cancel Invoice") {
      props.cancelInvoice(
        si,
        props.providerType.toLowerCase(),
        "contractStatus",
        "Signed"
      );
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    if (anchorRef.current && contains(anchorRef.current, event.target)) {
      return;
    }
    setOpen(false);
  };

  const sendInvoice = () => {
    axios
      .post(`Mail/SendInvoice/${si.personFk}/${si.id}`, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email
      })
      .then((res) => {
        // console.log(res.data);
        // axios.put(`ScheduleItems/UpdateContractStatus/${si.id}`, {
        //   id: si.id,
        //   contractStatus: "Invoiced",
        // });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancelContract = () => {
    var url = "";
    if (si.personFkNavigation.personCategory === "Talent" || si.personFkNavigation.personCategory === "Influencer" || si.personFkNavigation.personCategory === "Content Creator") {
      url = `TalentContracts/UpdateOnCancelContract/${si.id}`;
    } else {
      url = `ContractorContracts/UpdateOnCancelContract/${si.id}`;
    }
    axios
      .put(url, {
        dateCancelled: getCurrentTime(),
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancelSignedContract = () => {
    var url;
    if (si.personFkNavigation.personCategory === "Talent" || si.personFkNavigation.personCategory === "Influencer" || si.personFkNavigation.personCategory === "Content Creator") {
      url = `TalentContracts/UpdateOnCancelSignedContract/${si.id}`;
    } else {
      url = `ContractorContracts/UpdateOnCancelSignedContract/${si.id}`;
    }
    axios
      .put(url, {
        dateCancelled: getCurrentTime(),
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateContractStatus = (scheduleItemId, status) => {
    axios
      .put(`ScheduleItems/UpdateContractStatus/${scheduleItemId}`, {
        id: scheduleItemId,
        contractStatus: status,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createCrossOrganisationRecord = (si) => {
    console.log(si)
    const crossOrganisationRecord = {
      scheduleItemId: si.id,
      personId: si.personFk,
      fromOrganisationId: 1,
      toOrganisationId: 2,
      requestedBy: si.createdBy,
      budgetId: si.budgetId,
      companyId: props.project.companyFk,
      originalProjectId: props.project.id,
      originalJobId: props.project.jobId,
      totalToSpend: si.allocatedRates,
      title: si?.roleFkNavigation?.fieldRole || null
    }

    axios.post(`CrossOrganisation/CreateCrossOrganisationProjectRecord`, crossOrganisationRecord)
      .then(({ data }) => {
        console.log("crossOrganisationRecord", data)
      })
      .catch((err) => {
        alert("unable to create cross organisation project.")
      })
  }

  const handleSendContract = () => {
    if (
      si.category.toLowerCase().trim() === "unpaid" ||
      props.providerType === "Client" ||
      props.providerType === "Staff"
    ) {
      //SendScheduleItemEvent
      axios
        .post(`GoogleCalendar/SendScheduleItemEvent/${si.id}`)
        .then((res) => {
          console.log("Sent Calendar");
          axios
            .put(`ScheduleItems/UpdateContractStatus/${si.id}`, {
              id: si.id,
              contractStatus: "Sent",
            })
            .then((res) => {
              console.log(res.data);
              props.providerType === "Client" || props.providerType === "Staff"
                ? props.handleOnClick("Sent", si)
                : console.log("invite sent");
            });
        })
        .catch((e) => {
          console.log("Error sending Calendar");
        });
    } else {
      var url;
      var url2;
      if (props.providerType === "Contractor") {
        url = `Mail/SendScheduleContractEmail/${si.personFk}`;
        url2 = `ContractorContracts/PostContractorContract`;

      } else if (props.providerType === "Talent" || props.providerType === "Influencer" || props.providerType === "Content Creator") {
        url = `Mail/SendTalentContractEmail/${si.personFk}`;
        if (si.talentContractType === "BuyOut") {
          url2 = `TalentContracts/PostTalentContract/${si.id}`;
        } else if (si.talentContractType === "Restricted") {
          url2 = `TalentContracts/UpdateRestrictedContractOnSend/${si.id}`;
        }
      }
      axios
        .post(url2, {
          scheduleItemFk: si.id,
          dateSent: getCurrentTime(),
          status: "Sent",
        })
        .then(async (res) => {
          console.log(res);
          if (person?.isInternal === 1) {
            await updateContractStatus(si.id, "Pending")
            await createCrossOrganisationRecord(si)
          } else {
            const sendEmailResponse = await axios
              .post(url, {
                scheduleItem: {
                  id: si.id,
                  category: si.category,
                  providerName: si.providerName,
                  allocatedRates: si.allocatedRates,
                  startTime: si.startTime,
                  endTime: si.endTime,
                },
                sender: {
                  name: `${user.firstName} ${user.lastName}`,
                  email: user.email
                }
              });
            console.log(sendEmailResponse.data);
            await updateContractStatus(si.id, "Pending")
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  return (
    <Grow in={checked} mountOnEnter unmountOnExit>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          {showConfirmInternal && (
            <ConfirmationDialogActions
              title={"Are you sure?"}
              open={showConfirmInternal}
              contentText={"This action will create an internal company quote."}
              button1Action={() => setShowConfirmInternal(false)}
              button1Text={"Cancel"}
              button2Text={"Proceed"}
              button2Action={() => {
                handleClick();
                setShowConfirmInternal(false);
              }}
            />
          )}
          <ButtonGroup
            variant="contained"
            // color="secondary"
            ref={anchorRef}
            aria-label="split button"
            size="small"
            style={{
              width: "120px",
              backgroundColor: buttonProps.color,
            }}
          >
            <Button
              onClick={() => {
                if (props.si.personFkNavigation.isInternal == 1) {
                  setShowConfirmInternal(true);
                } else {
                  handleClick();
                }
              }}
              style={{
                padding: "0px 0px 0px 0px",
                width: "100px",
                backgroundColor: buttonProps.color,
                color: "white",
              }}
            >
              {buttonProps.options[selectedIndex]}
            </Button>
            <Button
              //color="secondary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select declined option"
              aria-haspopup="menu"
              onClick={handleToggle}
              style={{
                width: "20px",
                backgroundColor: buttonProps.color,
                color: "white",
              }}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{
              zIndex: "999",
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {buttonProps.options.map(
                        (option, index) =>
                          index > 0 && (
                            <MenuItem
                              key={option}
                              disabled={index === 0}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              } //console.log(event)}
                            >
                              {option}
                            </MenuItem>
                          )
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Grow>
  );
};

// export default PrePostContractButton;

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  editScheduleItem,
  cancelInvoice
})(PrePostContractButton);
