import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import axios from "axios";
import { formatUTCDate } from "../../../../utils/GeneralUtils";
import "./mediaInvoiceHistoryDialog.scss";


const MediaInvoiceHistoryDialog = ({
    open,
    handleClose,
    mediaInvoiceId
}) => {

    
    const [historyRecords, setHistoryRecords] = useState([]);

    const getHistory = () => {
        axios.get(`MediaCampaign/GetMediaInvoiceHistory/${mediaInvoiceId}`)
            .then(res => {
                setHistoryRecords(res.data)
            }).catch(e => {
                console.log(e);
            })
    }

    useEffect(() => {
        getHistory();
    }, []);

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if (reason != "backdropClick" && reason != "escapeKeyDown") {
                    handleClose();
                }
            }}
            maxWidth="md"
        >
            <div className="media-invoice-dialog-content-container">
                <DialogTitle>History</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="date-heading">Date</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historyRecords.map(r => {
                                    return (
                                        <TableRow>
                                            <TableCell>{formatUTCDate(r.date)}</TableCell>
                                            <TableCell>{r.description}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                {historyRecords.length === 0 && (
                                    <TableRow>
                                        <TableCell align="center" colSpan={2}>No History</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button className="default-button-grey" onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default MediaInvoiceHistoryDialog;