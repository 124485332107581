import {
  Button,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import "./purchaseOrderForm.scss";
import XeroContactAutocomplete from "../../Shared/XeroContactAutocomplete/XeroContactAutocomplete";
import MultiCurrencyInputField from "../../CurrencyExchange/MultiCurrencyInputField";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import { internalOrganisationIds } from "../../../utils/OrganisationUtils";
import PullFromBudgetAutocomplete from "../../Shared/BudgetItem/PullFromBudgetAutocomplete";
import { getCurrencies } from "../../../utils/GeneralUtils";

const PurchaseOrderForm = ({
  purchaseOrder,
  budgetItems,
  handlePurchaseOrderOnChange,
  handleCreatePurchaseOrder,
  handleChangeTotal,
  showHiddenBudget = false,
  baseCurrency = "NZD",
}) => {
  const [showConfirmInternal, setShowConfirmInternal] = useState(false);
  const handleChangeXeroContact = (xeroContact) => {
    //xeroContact.ContactID
    handlePurchaseOrderOnChange("xeroContactId", xeroContact);
  };

  const renderTaxLabel = () => {
    return getCurrencies().find((c) => c.value === purchaseOrder.currency)
      ?.taxLabel;
  };

  return (
    <div className="purchase-order-form-root">
      <Paper>
        {showConfirmInternal && (
          <ConfirmationDialogActions
            title={"Are you sure?"}
            open={showConfirmInternal}
            contentText={"This action will create an internal company quote."}
            button1Action={() => setShowConfirmInternal(false)}
            button1Text={"Cancel"}
            button2Text={"Proceed"}
            button2Action={() => {
              handleCreatePurchaseOrder();
              setShowConfirmInternal(false);
            }}
          />
        )}
        <div className="purchase-order-form-container">
          <XeroContactAutocomplete
            organisationId={2}
            handleOnChange={handleChangeXeroContact}
            value={purchaseOrder.xeroContactId}
            variant={"outlined"}
          />
          <PullFromBudgetAutocomplete
            options={budgetItems.filter((b) => b.remaining > 0)} //b.isHidden !== 1 &&
            handleOnChange={(event, newValue) => {
              handlePurchaseOrderOnChange("budget", newValue || null);
            }}
            value={budgetItems.find(
              (bi) => bi.projectCategory === purchaseOrder.budgetCategory
            )}
          />
          <TextField
            className="purchase-order-form-container-campaign-input"
            label="What is this for?"
            placeholder="Service Name"
            variant="outlined"
            size="small"
            value={purchaseOrder.serviceName}
            onChange={(e) => {
              handlePurchaseOrderOnChange("serviceName", e.target.value);
            }}
          />

          <div>
            <MultiCurrencyInputField
              totalAmount={purchaseOrder.total}
              nzdTotalAmount={purchaseOrder.totalInNzd}
              size="small"
              currency={purchaseOrder.currency}
              handleChange={handleChangeTotal}
              partOfCurrencyExchangeForm={false}
              baseCurrency={baseCurrency}
            />
          </div>

          <div className="purchase-order-form-switch-container">
            {/* <FormControlLabel
              control={
                <Switch
                  color={"primary"}
                  checked={purchaseOrder.includeTax === 1 ? true : false}
                  onChange={(e) => {
                    handlePurchaseOrderOnChange("includeTax", e.target.checked ? 1 : 0);
                  }}
                />
              }
              label={`Include ${renderTaxLabel()}`}
            /> */}
            <Button
              size="small"
              className="default-button"
              variant="contained"
              onClick={() => {
                if (
                  internalOrganisationIds.includes(purchaseOrder.xeroContactId)
                ) {
                  setShowConfirmInternal(true);
                } else {
                  handleCreatePurchaseOrder();
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default PurchaseOrderForm;
