/**
 * Check if value is numeric
 * 
 * Determines whether a variable is a number or numeric string.
 * 
 * @param {*} str - The value to check.
 * @returns {boolean} - True if the value is numeric, false otherwise.
 */
function isNumeric(str) {
  if (["string", "number"].indexOf(typeof str) === -1) return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

/**
 * Validate email format
 * 
 * Checks if the provided email address has a valid format.
 * 
 * @param {string} email - The email address to validate.
 * @returns {boolean} - True if the email format is valid, false otherwise.
 */
function validateEmail(email) {
  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email?.trim());
  return isValid;
}

/**
 * Validate strong password format
 * 
 * Checks if the password meets strength criteria.
 * 
 * @param {string} password - The password to validate.
 * @returns {boolean} - True if the password is strong, false otherwise.
 */
function validateStrongPassword(password) {
  return /(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password);
}

/**
 * Check if string is empty
 * 
 * Determines if the provided input is an empty or whitespace-only string.
 * 
 * @param {string} input - The input string to check.
 * @returns {boolean} - True if the input is empty or whitespace-only, false otherwise.
 */
function isEmpty(input) {
  return input === "" || input === null || input === undefined || input === " ";
}

/**
 * Check if array has elements
 * 
 * Determines if an array contains one or more elements.
 * 
 * @param {Array} array - The array to check.
 * @returns {boolean} - True if the array has elements, false otherwise.
 */
function hasElements(array) {
  return array && array.length > 0;
}

/**
 * Check if user is authenticated
 * 
 * Verifies if the user is authenticated based on a stored token in localStorage.
 * 
 * @returns {boolean} - True if the user is authenticated, false otherwise.
 */
function isAuth() {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    const token = window.localStorage.getItem("key");
    return !!token;
  }
  return true;
}

/**
 * Capitalize a word
 * 
 * Capitalizes the first letter of a word and makes the rest lowercase.
 * 
 * @param {string} word - The word to capitalize.
 * @returns {string} - The capitalized word.
 */
function capitalizeWord(word) {
  return word && word.length > 1 ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word;
}

/**
 * Format number to currency
 * 
 * Formats a number to two decimal places.
 * 
 * @param {number} num - The number to format.
 * @returns {string} - The formatted number with two decimal places.
 */
function currencyNumberFormat(num) {
  return num == null || isNaN(num) ? "0" : num.toFixed(2);
}

/**
 * Format number as currency string
 * 
 * Formats a number as a currency string in a specified currency.
 * 
 * @param {number} num - The number to format.
 * @param {string} currency - The currency code (default is "NZD").
 * @returns {string} - The formatted currency string.
 */
function currencyFormat(num, currency = "NZD") {
  return num == null || isNaN(num) ? "$0" : num.toLocaleString("en-UK", { style: "currency", currency, minimumFractionDigits: 2 });
}

/**
 * Generate unique hash
 * 
 * Generates a unique hash using the current timestamp and random characters.
 * 
 * @returns {string} - A unique hash string.
 */
function generateUniqueHash() {
  const timestamp = Date.now().toString();
  const randomChars = generateRandomCharacters(5);
  return (timestamp + randomChars).substring(0, 15);
}

/**
 * Generate random characters
 * 
 * Generates a random string of specified length using uppercase and lowercase letters.
 * 
 * @param {number} length - The length of the random string.
 * @returns {string} - The random string.
 */
function generateRandomCharacters(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join("");
}

/**
 * Get list of currencies
 * 
 * Retrieves a list of currency objects, optionally excluding NZD.
 * 
 * @param {boolean} shouldExcludeNZ - Whether to exclude NZD.
 * @returns {Array} - Array of currency objects.
 */
function getCurrencies(shouldExcludeNZ = false) {
  const currenciesWithoutNZ = [
    { value: "AUD", code: "AU", symbol: "AU$", taxLabel: "GST" },
    { value: "EUR", code: "EU", symbol: "€", taxLabel: "VAT" },
    { value: "GBP", code: "GB", symbol: "£", taxLabel: "VAT" },
    { value: "USD", code: "US", symbol: "$", taxLabel: "GST" },
    { value: "JPY", code: "JP", symbol: "¥", taxLabel: "JCT" },
    { value: "CNY", code: "CN", symbol: "¥", taxLabel: "VAT" },
  ];
  return shouldExcludeNZ ? currenciesWithoutNZ : [{ value: "NZD", code: "NZ", symbol: "$", taxLabel: "GST" }, ...currenciesWithoutNZ];
}

/**
 * Filter numbers only from input
 * 
 * Removes non-numeric characters from a string except for periods.
 * 
 * @param {string} inputString - The string to filter.
 * @returns {string} - The filtered string with numbers and periods only.
 */
function filterNumbersOnlyInput(inputString) {
  return inputString.replace(/[^0-9.]/g, "");
}

/**
 * Get user roles
 * 
 * Retrieves a list of user roles.
 * 
 * @returns {Array} - An array of user roles.
 */
function getUserRoles() {
  return ["Editor", "Admin", "Super User"];
}

/**
 * Get currency flag
 * 
 * Returns the emoji flag for a currency code.
 * 
 * @param {string} currency - The currency code.
 * @param {boolean} shouldExcludeNZ - Whether to exclude the NZ flag.
 * @returns {string} - The emoji flag or empty if excluded.
 */
function getCurrencyFlag(currency = "NZD", shouldExcludeNZ = false) {
  switch (currency) {
    case "AUD": return "🇦🇺";
    case "GBP": return "🇬🇧";
    case "JPY": return "🇯🇵";
    case "USD": return "🇺🇸";
    case "CNY": return "🇨🇳";
    case "EUR": return "🇪🇺";
    default: return shouldExcludeNZ ? "" : "🇳🇿";
  }
}

/**
 * Get list of countries
 * 
 * Retrieves a list of country objects with names and codes.
 * 
 * @returns {Array} - Array of country objects.
 */
function getCountries() {
  return [
    { name: "New Zealand", code: "NZ" },
    { name: "Australia", code: "AU" },
    { name: "United Kingdom", code: "GB" },
  ];
}


/**
 * Converts a UTC date string from MySQL to a formatted date and time string.
 * 
 * @param {string} mysqlDate - The MySQL date string in "YYYY-MM-DD HH:MM:SS" format.
 * @returns {string} - The formatted date and time string.
 */
function formatUTCDate(mysqlDate) {
  const dateObj = new Date(mysqlDate + "Z");

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isUS = timeZone.includes("America/");
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: timeZone
  };

  const dateFormatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = dateFormatter.format(dateObj);

  // The format returned by `Intl.DateTimeFormat` is generally "MM/dd/yyyy, HH:mm" format
  const [date, time] = formattedDate.split(', ');
  const [month, day, year] = date.split('/');

  if (isUS) {
    return `${month}/${day}/${year} ${time}`;
  } else {
    return `${day}/${month}/${year} ${time}`;
  }
}


export {
  isNumeric,
  validateEmail,
  validateStrongPassword,
  isEmpty,
  isAuth,
  capitalizeWord,
  hasElements,
  currencyFormat,
  generateUniqueHash,
  generateRandomCharacters,
  currencyNumberFormat,
  getCurrencies,
  filterNumbersOnlyInput,
  getUserRoles,
  getCurrencyFlag,
  getCountries,
  formatUTCDate
};
