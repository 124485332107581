import { Table, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { currencyFormat } from "../../../utils/GeneralUtils";

const BudgetTotalTable = ({ budget }) => {
  const getTotalQuotedAmount = (field) => {
    const totalsByCurrency = {};

    for (const item of filterBudget()) {
      const currency = item.currency || "NZD"; 
      if (!totalsByCurrency[currency]) {
        totalsByCurrency[currency] = 0;
      }
      if (item[field] && typeof item[field] === "number") {
        totalsByCurrency[currency] += item[field];
      } else {
        totalsByCurrency[currency] += Number(item[field]);
      }
    }
    return totalsByCurrency;
  };

  const filterBudget = (budgetByProject = null) => {
    return budgetByProject
      ? budgetByProject.filter((campaign) => {
          return campaign.isHidden !== 1;
        })
      : budget.filter((campaign) => {
          return campaign.isHidden !== 1;
        });
  };

  const renderTotals = (field) => {
    const totals = getTotalQuotedAmount(field);
    return Object.entries(totals).map(([currency, total]) => (
      <Typography variant="caption" key={currency}>
        {currencyFormat(total, currency)}
      </Typography>
    ));
  };

  return (
    <Table className="budget-accordion-footer-table">
      <TableRow>
        <TableCell className="column1 no-border-cell no-padding-cell no-background-cell">
          <strong>Total</strong>
        </TableCell>
        <TableCell className="column2 no-border-cell no-padding-cell no-background-cell">
          <div className="budget-accordion-header-total-column-container">
            <Typography variant="caption">Quoted</Typography>

            {renderTotals("quoted")}
          </div>
        </TableCell>
        <TableCell className="column3 no-border-cell no-padding-cell no-background-cell">
          <div className="budget-accordion-header-total-column-container">
            <Typography variant="caption">Allocated</Typography>

            {renderTotals("allocated")}
          </div>
        </TableCell>
        <TableCell className="column4 no-border-cell no-padding-cell no-background-cell">
          <div className="budget-accordion-header-total-column-container">
            <Typography variant="caption">Remaining</Typography>
            {renderTotals("remaining")}
          </div>
        </TableCell>
        <TableCell className="column5 no-border-cell no-padding-cell no-background-cell"></TableCell>
      </TableRow>
    </Table>
  );
};

export default BudgetTotalTable;
