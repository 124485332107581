import React, { Component, useEffect, useState } from "react";
//import Header from './Header';
import "../custom.scss";
import { Header, SidePanelMenu } from "@camistrynz/thomspsonspencer-ui";
import { useHistory } from "react-router-dom";
import { useTheme } from "../context/theme/ThemeContext";
import { useAuth } from "../context/auth/AuthContext";

const Layout = ({ children }) => {
  const history = useHistory();
  const { mode, toggleMode } = useTheme();
  const { isLoadingUser, user, hasAdminAccess, hasMediaAccess } = useAuth();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [adminSection, setAdminSection] = useState(null);

  useEffect(() => {
    document.documentElement.className =
      mode === "dark" ? "dark-mode" : "light-mode";
  }, [mode]);

  useEffect(() => {
    if (hasAdminAccess()) {
      setAdminSection({
        name: "Admin",
        handleOnClick: () => { },
        icon: null,
        innerItems: [
          {
            name: "General Settings",
            handleOnClick: () => {
              history.push("/admin/system");
              setShowSidePanel(false);
            },
            icon: null,
            innerItems: null,
          },
          {
            name: "Legal",
            handleOnClick: () => {
              history.push("/admin/legal");
              setShowSidePanel(false);
            },
            icon: null,
            innerItems: null,
          },
          {
            name: "Production",
            handleOnClick: () => { },
            icon: null,
            innerItems: [
              {
                name: "Catering",
                handleOnClick: () => {
                  history.push("/admin/catering");
                  setShowSidePanel(false);
                },
                icon: null,
                innerItems: null,
              },
              {
                name: "Contracts/Releases",
                handleOnClick: () => {
                  history.push("/admin/contracts-releases");
                  setShowSidePanel(false);
                },
                icon: null,
                innerItems: null,
              },
              {
                name: "Express Payment",
                handleOnClick: () => {
                  history.push("/admin/express-payment");
                  setShowSidePanel(false);
                },
                icon: null,
                innerItems: null,
              },
              {
                name: "Production Emails",
                handleOnClick: () => {
                  history.push("/admin/email");
                  setShowSidePanel(false);
                },
                icon: null,
                innerItems: null,
              },
              {
                name: "Health & Safety",
                handleOnClick: () => {
                  history.push("/admin/health-and-safety");
                  setShowSidePanel(false);
                },
                icon: null,
                innerItems: null,
              },
              {
                name: "Spam Bot",
                handleOnClick: () => {
                  history.push("/admin/spambot");
                  setShowSidePanel(false);
                },
                icon: null,
                innerItems: null,
              },
            ],
          },
          {
            name: "Media",
            handleOnClick: () => { },
            icon: null,
            innerItems: [
              {
                name: "Media Supplier Details",
                handleOnClick: () => {
                  history.push("/admin/media-suppliers");
                  setShowSidePanel(false);
                },
                icon: null,
                innerItems: null,
              },
            ],
          },
          {
            name: "Emails",
            handleOnClick: () => { },
            icon: null,
            innerItems: [
              {
                name: "Templates",
                handleOnClick: () => {
                  history.push("/admin/emails");
                  setShowSidePanel(false);
                },
                icon: null,
                innerItems: null,
              },
              ,
            ],
          },
        ],
      });
    } else if (hasMediaAccess()) {
      setAdminSection({
        name: "Admin",
        handleOnClick: () => { },
        icon: null,
        innerItems: [
          {
            name: "Media",
            handleOnClick: () => { },
            icon: null,
            innerItems: [
              {
                name: "Media Supplier Details",
                handleOnClick: () => {
                  history.push("/admin/media-suppliers");
                  setShowSidePanel(false);
                },
                icon: null,
                innerItems: null,
              },
            ],
          }
        ],
      });
    }
  }, [hasAdminAccess]);

  return (
    <div className="layout-background">
      <Header
        systemName={
          window.location?.pathname?.toLowerCase()?.includes("time-tracker")
            ? "TIME TRACKER"
            : window.location?.pathname
              ?.toLowerCase()
              ?.includes("/project/home")
              ? "PRODUCTION CORE"
              : "JOB MANAGER"
        }
        handleNavMenuClick={() =>
          !showSidePanel ? setShowSidePanel(true) : setShowSidePanel(false)
        }
        handleTitleClick={() => history.push("/")}
        handleToggleMode={toggleMode}
        mode={mode}
      />
      {showSidePanel && user && (
        <SidePanelMenu
          isOpen={showSidePanel}
          handleClose={() => setShowSidePanel(false)}
          firstName={user?.firstName}
          adminSection={adminSection}
        />
      )}
      {children}
    </div>
  );
};
export default Layout;
