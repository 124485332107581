import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  IconButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import axios from "axios";
import { useUserContext } from "../../../../../../context/user/UserContext";
import { useAuth } from "../../../../../../context/auth/AuthContext";
import ProcessingSpinnerDialog from "../../../../../Shared/Spinner/ProcessingSpinnerDialog";
import ProcessingSpinner from "../../../../../Shared/Spinner/ProcessingSpinner";
import { Alert } from "@material-ui/lab";
import PersonRatingsForm from "../../../../../Shared/Person/PersonRatingsForm";
import "./confirmInvoiceDialog.scss";
function ConfirmationRequest({ handleSendInvoice, closeDialog, scheduleItem, isNZD }) {
  
  return (
    <>
      <DialogTitle style={{ textAlign: "center" }}>
        {`Send ${isNZD() ? "Invoice" : "Purchase Order"}`}
      </DialogTitle>

      <React.Fragment>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            Are you sure you want to send {`${scheduleItem.providerName}'s`}{" "}
            {isNZD() ? "Invoice" : "Purchase Order"}?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              closeDialog();
            }}
            variant="contained"
            className="default-button-grey"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSendInvoice();
            }}
            variant="contained"
            className="default-button"
            size="small"
          >
            Send Now
          </Button>
        </DialogActions>
      </React.Fragment>
    </>
  );
}

function ProcessingInvoice({ scheduleItem, isNZD }) {
  return (
    <>
      <DialogTitle style={{ textAlign: "center" }}>
        {`Sending ${isNZD() ? "Invoice" : "Purchase Order"} Details to ${scheduleItem.providerName}`}
      </DialogTitle>

      <DialogContent>
        <ProcessingSpinner duration={60} unitTime="seconds" />
      </DialogContent>
    </>
  );
}

function InvoiceCreated({ scheduleItem, isNZD }) {
  return (
    <>
      <DialogTitle style={{ textAlign: "center" }}>
        {`${isNZD() ? "Invoice" : "Purchase Order"} created for ${scheduleItem.providerName}`}
      </DialogTitle>

      <DialogContent>
        <Alert severity="success">
          {`All done! The ${isNZD() ? "invoice" : "purchase order"} has been sent${isNZD() ? " for payment." : ""}.`}
        </Alert>
      </DialogContent>
    </>
  );
}

const ConfirmInvoiceDialog = ({
  scheduleItem,
  open,
  handleClose,
  handleUpdateScheduleItemContractStatus,
  setSuccessCreate,
  setFailedCreate,
}) => {
  const { user } = useAuth();
  const [displayReviewThankYouMessage, setDisplayReviewThankYouMessage] =
    useState(false);
  const [processingStatus, setProcessingStatus] = useState(
    "CONFIRMATION_REQUESTED"
  );

  const isNZD = () => {
    return scheduleItem?.personFkNavigation?.currency === "NZD"
  }

  const handleSendInvoice = () => {
    setProcessingStatus("PROCESSING_INVOICE");
    axios
      .post(
        `Influencer/PostInfluencerInvoice/${scheduleItem.personFk}/${scheduleItem.id}`,
        null,
        {
          params: {
            user: user?.email,
          },
        }
      )
      .then((e) => {
        //refresh data
        handleUpdateScheduleItemContractStatus(
          scheduleItem.id,
          "AwaitingPayment"
        );
        setProcessingStatus("INVOICE_CREATED");
        setSuccessCreate(true);
      })
      .catch((e) => {
        console.log(e);
        setFailedCreate(true);
        setProcessingStatus("CONFIRMATION_REQUESTED");
      })
  };

  const closeDialog = () => {
    if (processingStatus === "PROCESSING_INVOICE") {
      return;
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={() => closeDialog()} maxWidth="md" fullWidth>
      <div className="confirm-invoice-dialog-root">
        {processingStatus === "CONFIRMATION_REQUESTED" && (
          <ConfirmationRequest
            scheduleItem={scheduleItem}
            handleSendInvoice={handleSendInvoice}
            closeDialog={closeDialog}
            isNZD={isNZD}
          />
        )}
        {(processingStatus === "PROCESSING_INVOICE" ||
          processingStatus === "INVOICE_CREATED") && (
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            {processingStatus === "PROCESSING_INVOICE" && (
              <ProcessingInvoice scheduleItem={scheduleItem} isNZD={isNZD}/>
            )}
            {processingStatus === "INVOICE_CREATED" && (
              <InvoiceCreated scheduleItem={scheduleItem} isNZD={isNZD}/>
            )}
            <div className="confirm-invoice-dialog-review-heading">
              <Typography>{`Please take a moment to review your experience with ${scheduleItem.providerName}.`}</Typography>
              <Typography>{`Your feedback is confidential and will not be shared with ${scheduleItem.providerName}.`}</Typography>
            </div>
            <PersonRatingsForm
              handleSaveCallback={() => setDisplayReviewThankYouMessage(true)}
              personId={scheduleItem.personFk}
            />
            {displayReviewThankYouMessage && (
              <Alert severity="success">
                Fantastic! Thank you so much for your review.
              </Alert>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ConfirmInvoiceDialog;
